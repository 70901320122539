import React from 'react'
import { Formik } from 'formik';
import SaltedMd5 from 'salted-md5'

export class SubscribeButton extends React.Component {

    mode = 'live'
    payfast = {
        live: {
            merchant_id: '11612308',
            merchant_key: '1q8b0zoyh0b38'
        }, 
        demo: {
            merchant_id: '10004056',
            merchant_key: '2kiaths97drb2'
        }
    }

    state = {
        initialValues: {
            merchant_id: this.payfast[this.mode].merchant_id,
            merchant_key: this.payfast[this.mode].merchant_key,
            return_url: 'http://portal.waitr.co.za/dashboard/settings',
            cancel_url: 'https://portal.waitr.co.za/dashboard/settings',
            notify_url: 'https://us-central1-waitr-d321d.cloudfunctions.net/payment',
            name_first: '',
            name_last: '',
            email_address: '',
            amount: 100,
            item_name: 'Waitr Subscription',
            item_description: '',
            custom_str1: '',
            email_confirmation: 1,
            subscription_type: 1,
            recurring_amount: '',
            frequency: 3,
            cycles: 1,
            payment_method: 'sc'
        }
    } 
    
    componentDidMount() {
        const packageType = this.props.packageType
        const employee = this.props.employee
        const names = employee.name.split(' ')
        const packages = {
            free: {
                price: 0,
                name: 'Free'
            },
            standard: {
                price: 699,
                name: 'Standard'
            },
            advanced: {
                price: 899,
                name: 'Advanced'
            },
            full: {
                price: 899,
                name: 'Full'
            }
        }

        const initialValues = {
            merchant_id: this.payfast[this.mode].merchant_id,
            merchant_key: this.payfast[this.mode].merchant_key,
            return_url: 'https://portal.waitr.co.za/dashboard/settings',
            cancel_url: 'https://portal.waitr.co.za/dashboard/settings',
            notify_url: 'https://us-central1-waitr-d321d.cloudfunctions.net/payment',
            name_first: names[0],
            name_last: names[1] ? names[1] : '',
            email_address: employee.username,
            m_payment_id: employee.organization,
            amount: 100,
            item_name: 'WaitrSubscription',
            item_description: packages[packageType].name,
            custom_str1: employee.organization,
            
            email_confirmation: 1,
            // subscription_type: 1,
            // recurring_amount: packages[packageType].price,
            // frequency: 3,
            // cycles: 1,
            passphrase: 'blahblahblah',
            payment_method: 'eft'
        }
        console.log(initialValues)

        const keys = Object.keys(initialValues)
        let params = keys.map(key => `${key}=${encodeURIComponent(initialValues[key])}`).join('&')
        console.log(params)
        params += '&passphrase=blahblahblah'
        // keys.forEach(key => {
        //     initialValues[key] = encodeURI(initialValues[key])
        // })
        
        const hash = SaltedMd5(params)
        initialValues['security'] = hash
        console.log(initialValues['security'])
        this.setState({
            initialValues: initialValues
        })
    }

    render() {
        return (
            <Formik enableReinitialize={true} initialValues={this.state.initialValues}>
                {({
                    values
                }) => {
                    return (
                        <form ref={el => this.form = el} target="_blank" action={"https://" + (this.mode === 'demo' ? 'sandbox' : 'www') + ".payfast.co.za/eng/process"} method="POST">
                            {Object.keys(this.state.initialValues).map(key => {
                                return (
                                    <input type='hidden' name={key} value={this.state.initialValues[key]} />
                                )
                            })}
                        {/* <input type="hidden" name="merchant_id" value={values.merchant_id} />
                        <input type="hidden" name="merchant_key" value={values.merchant_key} />
                        <input type="hidden" name="return_url" value={values.return_url} />
                        <input type="hidden" name="cancel_url" value={values.cancel_url} />
                        <input type="hidden" name="notify_url" value={values.notify_url} />
                        <input type="hidden" name="name_first" value={values.name_first} />
                        <input type="hidden" name="name_last" value={values.name_last} />
                        <input type="hidden" name="email_address" value={values.email_address} />
                        <input type="hidden" name="m_payment_id" value={values.m_payment_id} />
                        <input type="hidden" name="amount" value={values.amount} />
                        <input type="hidden" name="item_name" value={values.item_name} />
                        <input type="hidden" name="item_description" value={values.item_description} />
                        <input type="hidden" name="custom_str1" value={values.custom_str1} />
                        <input type="hidden" name="email_confirmation" value={values.email_confirmation} />
                        <input type="hidden" name="subscription_type" value={values.subscription_type} />
                        <input type="hidden" name="recurring_amount" value={values.recurring_amount} />
                        <input type="hidden" name="frequency" value={values.frequency} />
                        <input type="hidden" name="cycles" value={values.cycles} />
                        <input type="hidden" name="signature" value={values.security} /> */}
    
                        <input className='btn btn-primary' type='submit' value='Subscribe Now' />
                    </form>
                    )
                }}
            </Formik>
        )
    }
}