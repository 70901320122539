import React from 'react'
import { Card, Form } from 'react-bootstrap';
import { RadarChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, Legend, ResponsiveContainer } from 'recharts';
import { Formik } from 'formik';
import moment from 'moment'
import { StatsService } from '../../services/stats.service';

function DrawChart(props) {
    const data = [
        {
          "name": "Small Option 1",
          "A": 120,
          "B": 110,
          "fullMark": 150
        },
        {
          "name": "Coke",
          "A": 98,
          "B": 130,
          "fullMark": 150
        },
        {
          "name": "Engine",
          "A": 86,
          "B": 130,
          "fullMark": 150
        },
        {
          "name": "Vacuum",
          "A": 99,
          "B": 100,
          "fullMark": 150
        },
        {
          "name": "Small Chassis	",
          "A": 85,
          "B": 90,
          "fullMark": 150
        },
        {
          "name": "Flatbed Option 1	",
          "A": 65,
          "B": 85,
          "fullMark": 150
        }
      ]
    return (
        <ResponsiveContainer width='99%' height={300} data={data}>
          <RadarChart outerRadius={130} width={730} height={250} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis angle={30} domain={[0, 150]} />
            <Radar dataKey="A" stroke="#c3c2ea" fill="#c3c2ea" fillOpacity={0.6} />
            {/* <Radar name="Lily" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} /> */}
            {/* <Legend /> */}
        </RadarChart>
        </ResponsiveContainer>
    )
}

export class PopularProducts extends React.Component {
    state = {
      data: [],
      durationType: 'week'
    }

    componentDidMount() {
      this.onSubmit(this.state.durationType)
    }

    onSubmit(value) {
      console.log(value)
      let startDate, endDate, promise

      endDate = moment(new Date())
      switch (value) {
        case 'week':
          startDate = moment(endDate).subtract(7, 'day').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD'))
          break;
        case 'month':
          startDate = moment(endDate).subtract(1, 'month').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD'))
          break
        case 'year':
          startDate = moment(endDate).subtract(1, 'year').format('YYYY-MM-DD')
          promise = StatsService.monthlyStats(startDate, endDate.format('YYYY-MM-DD'))
          break
        default:
          break;
      }
      
      
      promise.then(data => {
        data = data.map(item => {
          let name
          if (value === 'week') {
            name = item.day
          } else if (value === 'year') {
            name = item.month
          } else {
            name = item.day
          }
          return {
            name: name,
            orders: item.orders
          }
        })

        if (value === 'year') {
            data = data.map(item => {
              item.name = moment(new Date(item.name + '-01')).format('YYYY MMM')

              return item
            })
        }

        this.setState({
          data: data
        })
        console.log(data)
      })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <div style={{display: 'flex'}}>
                      <Card.Title style={{flex: 1}}>Popular Products</Card.Title>
                      <Formik
                        initialValues={{
                          durationType: this.state.durationType
                        }}
                        validateOnChange={(value) => {
                          console.log(value)
                        }}
                      >
                        {({
                          values,
                          handleChange
                        }) => {
                          return (
                            <Form>
                              <Form.Control value={values.durationType} id='durationType' as="select" onChange={(e) => {
                                handleChange(e)
                                this.onSubmit(e.target.value)
                              }}>
                                <option value='week'>Week</option>
                                <option value='month'>Month</option>
                                <option value='year'>Year</option>
                              </Form.Control>
                            </Form>
                          )
                        }}
                      </Formik>
                      
                    </div>
                    <DrawChart data={this.state.data} />
                </Card.Body>
            </Card>
        )
    }
}