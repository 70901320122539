import { AuthService } from "./auth.service";
import firebase from 'firebase'

export class DiscountService {
    static async discounts() {
        const employee = await AuthService.employee()
        const snapshots = await firebase.firestore().collection('discounts')
        .where('branch', '==', employee.branch)
        .where('active', '==', true)
        .orderBy('name').get()

        return snapshots.docs.map(doc => {
            return {
                ...doc.data(),
                id: doc.id
            }
        })
    }

    static async add(discount) {
        const employee = await AuthService.employee()

        discount = {
            ...discount,
            branch: employee.branch,
            organization: employee.organization,
            global: true,
            active: true
        }

        const ref = await firebase.firestore().collection('discounts').add(discount)

        return {
            ...discount,
            id: ref.id
        }
    }

    static async edit(id, discount) {
        await firebase.firestore().collection('discounts').doc(id).update(discount)
        
        return {
            ...discount,
            id: id
        }
    }

    static async remove(id) {
        return await firebase.firestore().collection('discounts').doc(id).delete()
    }
}