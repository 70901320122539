import React from 'react'
import RatingsIcon from '@material-ui/icons/Star'
import NoRatingsIcon from '@material-ui/icons/StarBorderOutlined'

export class StarRating extends React.Component {


    render() {
        const ratings = []
        if (this.props.rating != null) {
            for (let i = 0; i < 5; i++) {
                if (i < this.props.rating) {
                    ratings.push(true)
                } else {
                    ratings.push(false)
                }
            }
        }

        return (
            <div>
                {ratings.map((rating, index) => rating ? <RatingsIcon key={index} /> : <NoRatingsIcon key={index} />)}
            </div>
        )
    }
}