import React from 'react'
import { Modal, ListGroup } from 'react-bootstrap';
import { OrdersService } from '../../services/orders-service';
import moment from 'moment'

export class ViewOrderModal extends React.Component {
    state = {
        orders: []
    }

    constructor(props) {
        super(props)

        this.onHide = this.onHide.bind(this)
    }

    onHide() {
        if (this.props.onHide != null) {
            this.props.onHide()
        }
    }

    componentDidMount() {
        console.log(this.props.customer.orders)
        OrdersService.ordersByIds(this.props.customer.orders).then(orders => {
            this.setState({
                orders: orders
            })
            console.log(orders)
        })
    }

    render() {
        return (
            <Modal show={true} onHide={this.onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>View Orders</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {this.state.orders.reverse().filter(order => order != null).map((order, index) => {
                            return (
                                <ListGroup.Item key={index}>
                                    {moment(new Date(order.createdAt)).format('YYYY-MM-DD HH:mm:ss')}<br />
                                    {order.order}<br />
                                    {order.description}<br />
                                    R {(order.price / 100).toFixed(2)}
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal> 
        )
    }
}