import { AuthService } from "./auth.service";
import firebase from 'firebase'
import Axios from "axios";

export class OrdersService {
    static async recentOrders() {
        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        const snapshot = (await firebase.database().ref(`dashboard/orders/${employee.branch}`)
            .orderByChild('createdAt').limitToLast(5).once('value'))

        const orders = []
        snapshot.forEach(order => {
            orders.push({
                ...order.val(),
                id: order.key
            })
        })

        return orders.reverse()
    }

    static async cancelOrder(orderId) {
        // const user = AuthService.user()

        // return firebase.database().ref(`employees/${user.uid}`).once('value').then(snapshot => {
        //     const employee = snapshot.val()

        //     // return firebase.database().ref(`orders/${employee.branch}/`)

        //     return Promise.all(ids.map(id => {
        //         return firebase.database().ref(`dashboard/orders/${employee.branch}/${id}`).once('value').then(snapshot => {
        //             if (snapshot.val() == null) {
        //                 console.log('Non existant order: ' + id)
        //             }
        //             return snapshot.val()
        //         })
        //     }))
        // })
    }

    static async ordersByIds(ids) {
        const user = AuthService.user()

        return firebase.database().ref(`employees/${user.uid}`).once('value').then(snapshot => {
            const employee = snapshot.val()

            return Promise.all(ids.map(id => {
                return firebase.database().ref(`dashboard/orders/${employee.branch}/${id}`).once('value').then(snapshot => {
                    if (snapshot.val() == null) {
                        console.log('Non existant order: ' + id)
                    }
                    return snapshot.val()
                })
            }))
        })
    }

    static order(id) {
        const user = AuthService.user()

        return firebase.database().ref(`employees/${user.uid}`).once('value').then(snapshot => {
            const employee = snapshot.val()

            return firebase.database().ref(`dashboard/orders/${employee.branch}/${id}`).once('value').then(snapshot => {
                if (snapshot.val()) {
                    return {
                        ...snapshot.val(),
                        id: id
                    }
                }

                return null
            })
        })
    }

    static cancel(id, date) {
        const user = AuthService.user()

        return firebase.database().ref(`employees/${user.uid}`).once('value').then(snapshot => {
            const employee = snapshot.val()

            console.log(`orders/${employee.branch}/${date}/${id}`)
            return firebase.database().ref(`orders/${employee.branch}/${date}/${id}`).update({
                cancelled: true,
                ready: true,
                cancelledAt: firebase.database.ServerValue.TIMESTAMP,
                finishedAt: firebase.database.ServerValue.TIMESTAMP,
                collected: true,
                collectedAt: firebase.database.ServerValue.TIMESTAMP
            })
        })
    }

    static async payments(from, to) {
        console.log(from, to)
        const timeStart = new Date(from).getTime()
        const endDate = new Date(to)
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)
        const timeEnd = endDate.getTime()

        console.log(timeStart, timeEnd)

        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        const snapshot = (await firebase.database().ref(`dashboard/orders/${employee.branch}`)
            .orderByChild('paidAt').startAt(timeStart).endAt(timeEnd).once('value'))

        const orders = []
        snapshot.forEach(order => {
            orders.push({
                ...order.val(),
                id: order.key
            })
        })

        return orders
    }

    static async exportOrders(from, to, options) {
        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()

        window.location = `https://us-central1-waitr-d321d.cloudfunctions.net/exportOrders?branch=${employee.branch}&from=${from}&to=${to}
            ${options.notPaid ? '&notPaid='+options.notPaid : ''}
            ${options.paid ? '&paid='+options.paid : ''}
            ${options.collected ? '&collected='+options.collected : ''}
            ${options.notCollected ? '&notCollected='+options.notCollected : ''}`
    }

    static async sendInvoice(orderId, phone) {
        if (phone[0] == '0') {
            phone = '+27' + phone.substr(1, phone.length)
        }
        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        
        return await Axios.get(`https://us-central1-waitr-d321d.cloudfunctions.net/sendInvoice?branch=${employee.branch}&orderId=${orderId}&phone=${phone}`)
    }

    static async orders(from, to, options) {
        let {notPaid, notCollected, collected, paid, category} = options ? options : {}
        console.log(`Not paid: ${notPaid}`)
        console.log(`Not collected ${notCollected}`)
        console.log(from, to)
        const timeStart = new Date(from).getTime()
        const endDate = new Date(to)
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)
        endDate.setMilliseconds(999)
        const timeEnd = endDate.getTime()

        console.log(timeStart, timeEnd)

        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        const snapshot = (await firebase.database().ref(`dashboard/orders/${employee.branch}`)
            .orderByChild('createdAt').startAt(timeStart).endAt(timeEnd).once('value'))

        const orders = []
        snapshot.forEach(order => {
            orders.push({
                ...order.val(),
                id: order.key
            })
        })

        return orders.filter(order => {
            if (notCollected === true && order.collectedAt > 0) {
                return false
            }

            if (collected === true && order.collectedAt == -1) {
                return false
            }

            // if (notCollected === false && order.collectedAt == -1) {
            //     return false
            // }

            if (notPaid === true && !!order.paid == true) {
                return false
            }

            if (paid === true && !!order.paid == false) {
                return false
            }

            if (category && category != 'All') {
                let foundCategory = false
                for (let i = 0; i < order.products.length; i++) {
                    const product = order.products[i]

                    if (category == product.category) {
                        foundCategory = true
                        break
                    }
                }

                if (foundCategory == false) {
                    return false
                }
            }

            // if (notPaid === false && !!order.paid == false) {
            //     return false
            // }

            // if (notCollected === true) {
            //     return order.collectedAt === -1
            // } else if (notCollected === false) {
            //     return order.collectedAt > -1
            // }

            // if (notPaid == true) {
            //     console.log('Not paid working')
            //     return !!order.paid == false
            // } else if (notPaid == false) {
            //     return !!order.paid == true
            // }

            return true
        })
    }

    // static async orders(date) {
    //     const user = AuthService.user()
    //     const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        
    //     // const today = moment(new Date()).format('YYYY-MM-DD')

    //     console.log(`orders/${employee.branch}/${date}`)
    //     const orders = (await firebase.database().ref(`orders/${employee.branch}/${date}`).once('value')).val()

    //     if (orders) {
    //         const keys = Object.keys(orders)

    //         return keys.map(key => {
    //             return {
    //                 ...orders[key],
    //                 id: key,
    //                 price: orders[key].price ? orders[key].price : 0
    //             }
    //         }).sort((a, b) => {
    //             if (a.createdAt < b.cratedAt) {
    //                 return -1
    //             } else {
    //                 return 1
    //             }
    //         })
    //     }

    //     return []
    // }
}