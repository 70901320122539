import React from 'react'
import { Formik } from 'formik';
import { Form, Button, Modal } from 'react-bootstrap';
import { DiscountService } from '../../services/discount.service'
import { ClipLoader } from 'react-spinners'

export class AddDiscountModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            discount: this.props.discount ? {
                ...this.props.discount
            } : {
                name: '',
                type: '',
                value: 0
            },
            saving: false
        }
        console.log(this.props)

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
        }
    }

    componentDidUpdate() {
        
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        values['price'] = Math.round(values['price'] * 100)

        this.setState({
            saving: true
        })
        if (this.props.discount) {
            DiscountService.edit(this.props.discount.id, values).then(discount => {
                if (this.props.onDiscountEdited) {
                    this.props.onDiscountEdited(discount)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        } else {
            DiscountService.add(values).then(discount => {
                if (this.props.onDiscountAdded) {
                    this.props.onDiscountAdded(discount)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        }
        
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.discount ? {
                    ...this.props.discount
                } : {
                    name: '',
                    type: 'percentage',
                    value: 0
                }}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Discount</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='name'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={values.name} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter discount name' />
                                    </Form.Group>
                                    <Form.Group controlId='type'>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control value={values.type} onBlur={handleBlur} onChange={handleChange}as='select' >
                                            <option value='percentage'>Percentage (%)</option>
                                            <option value='amount'>Amount (R)</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='value'>
                                        <Form.Label>Value</Form.Label>
                                        <Form.Control value={values.value} onBlur={handleBlur} onChange={handleChange} type='number' min='0' step='0.01' placeholder='Enter discount amount' />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm} disabled={this.state.saving}>
                                        <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}