import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { AuthService } from '../services/auth.service'
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners'
import { Link } from 'react-router-dom';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class ForgotPasswordPage extends Component {
    state = {
        loading: false,
        error: null,
        success: null
    }

    reset(data, resetForm) {
        this.setState({
            loading: true,
            error: null
        })
        AuthService.sendResetPasswordEmail(data.email).then(data => {
            this.setState({
                loading: false,
                success: "You've got mail! Check you email for further instructions on resetting your password"
            })
            resetForm()
        }).catch(err => {
            console.log(err.message)
            this.setState({
                loading: false,
                error: err.message
            })
        }).finally(() => {
            // this.setState({
            //     loading: false
            // })
        })
    }

    renderForm() {
        const { classes } = this.props;
        return (
            <Formik initialValues={{
                email: '',
                password: ''
            }}
            onSubmit={(values) => this.reset(values)}>
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => {
                    return (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Email Address</InputLabel>
                                <Input value={values.email} onBlur={handleBlur} onChange={handleChange} id="email" name="email" autoComplete="email" autoFocus />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input value={values.password} onBlur={handleBlur} onChange={handleChange} name="password" type="password" id="password" autoComplete="current-password" />
                            </FormControl>
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
                            {this.state.error && <div style={{color: 'red', textAlign: 'center'}}>{this.state.error}</div>}
                            {this.state.success && <div style={{color: 'green', textAlign: 'center'}}>{this.state.success}</div>}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={this.state.loading}
                            >
                                <span style={{marginRight: '10px'}}>Login</span> {this.state.loading && <ClipLoader />}
                            </Button>
                        </form>
                    )
                }}
            </Formik>
        )
    }

    render() {
        const { classes } = this.props;

        return (
            <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: '#4b4453'}}>
            <div className="row justify-content-center">
            <div className="col-md-9 col-lg-12 col-xl-10">
                <div className="card shadow-lg o-hidden border-0 my-5">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-flex">
                                <div className="flex-grow-1 bg-login-image" style={{
                                    backgroundImage: `url('/assets/imgs/waitr-user.png')`
                                }}></div>
                            </div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <div className="text-center">
                                        <img src='/assets/imgs/waitr-logo.png' alt="Waitr Logo" width={200} style={{marginBottom: 25}} />
                                        <h4 className="text-dark mb-4">Forgot your Password?</h4>
                                        <p style={{textAlign: 'center'}}>No problem. Enter your email address below and we'll send you a password reset link</p>
                                    </div>
                                    <Formik  initialValues={{
                                        email: '',
                                        password: ''
                                    }} onSubmit={(values, {resetForm}) => {
                                        
                                        this.reset(values, resetForm)
                                    }}>
                                        {({
                                            values,
                                            handleBlur,
                                            handleChange,
                                            handleSubmit
                                        }) => {
                                            return (
                                                <form onSubmit={handleSubmit} className="user">
                                                    <div className="form-group"><input value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control form-control-user" name="email" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address" name="email" type='email' /></div>

                                                    {this.state.error && <div style={{color: 'red', textAlign: 'center'}}>{this.state.error}</div>}
                                                    {this.state.success && <div style={{color: 'green', textAlign: 'center'}}>{this.state.success}</div>}
                                                    <Button fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.submit} type="submit">Reset Password  {this.state.loading && <ClipLoader color='white' />}</Button>
                                                    {/* <hr /><a className="btn btn-primary btn-block text-white btn-google btn-user" role="button"><i className="fab fa-google"></i>&nbsp; Login with Google</a><a className="btn btn-primary btn-block text-white btn-facebook btn-user" role="button"><i className="fab fa-facebook-f"></i>&nbsp; Login with Facebook</a> */}
                                                    <hr />
                                                </form>
                                            )
                                        }}
                                        
                                    </Formik>
                                    <div className="text-center"><Link className="small" to="/">Back to Sign In</Link></div>
                                    <div className="text-center"><a className="small" target='_blank' href="https://www.waitr.co.za/signup">Create an Account!</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>


            // <main className={classes.main}>
            //     <CssBaseline />
            //     <Paper className={classes.paper}>
            //         <img src='/assets/imgs/waitr-logo.png' alt='Waitr Logo' style={{width: '150px', marginBottom: '10px'}} />
            //         <hr style={{width: '75px'}} />
            //         {/* <Avatar className={classes.avatar}>
            //         <LockOutlinedIcon />
            //         </Avatar> */}
            //         {/* <Typography component="h1" variant="h5">
            //             Portal
            //         </Typography> */}
            //         {this.renderForm()}
            //     </Paper>
            //     </main>
        )
    }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPasswordPage);