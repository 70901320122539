import axios from "axios";
import * as firebase from 'firebase'
import { AuthService } from "./auth.service";

export class SmsService {
    static ERRORS = {
        0: {
            definition: 'Deliveried',
            description: 'No Error',
            action: 'Nothing'
        },
        1: {
            definition: 'Enroute',
            description: 'SMS submitted to Operator',
            action: 'Nothing'
        },
        3: {
            definition: 'Expired',
            description: 'Mobile number off/unavailable and delivery retry expired on SMSC',
            action: 'Query the customer'
        },
        4: {
            definition: 'Deleted by SMSC',
            description: 'Delivery to mobile device not allowed',
            action: 'Subscriber to query Operator'
        },
        5: {
            definition: 'Cannot Deliver',
            description: 'Destination device not available on network. Ported/Cancelled SIM',
            action: 'Query the customer'
        },
        7: {
            definition: 'Cannot Deliver',
            description: 'Phone is off / GSM Network error / Phone out of range / SIM card full',
            action: 'Query the customer'
        },
        8: {
            definition: 'Delivery Failure',
            description: 'Destination SIM Full or SMS receiving turned off on phone',
            action: 'Query the customer'
        },
        11: {
            definition: 'Abandoned',
            description: 'Invalid Destination Address',
            action: 'Fix destination mobile number'
        },
        25: {
            definition: 'Abandoned',
            description: 'Invalid Destination Address',
            action: 'Fix destination mobile number'
        },
        69: {
            definition: 'Submit_sm failure',
            description: 'Maximum Delivery Number Exceeded',
            action: 'Reduce traffic to mobile number/Submit SMS later'
        },
        116: {
            definition: 'Unknown SCP Error',
            description: 'Suspended subscriber account',
            action: 'Query the customer'
        },
        120: {
            definition: 'VAS Unavailable to Called Party',
            description: 'Barred subscriber/Black listed',
            action: 'Query the customer'
        },
        123: {
            definition: 'Reserved Error',
            description: 'Unknown failure ',
            action: 'Contact Support Desk'
        },
        124: {
            definition: 'Submit_sm failure',
            description: 'Maximum Delivery Number Exceeded',
            action: 'Reduce traffic to mobile number/Submit SMS later'
        },
        134: {
            definition: 'Reserved Error',
            description: 'Unknown failure',
            action: 'Contact Support Desk'
        },
        195: {
            definition: 'Missing OPT Param',
            description: 'Missing Optional Parameter',
            action: 'Contact Support Desk'
        },
        196: {
            definition: 'Invalid OPT Param',
            description: 'Invalid Optional Parameter',
            action: 'Contact Support Desk'
        },
        254: {
            definition: 'Delivery Failure',
            description: 'Failure on subscriber end',
            action: 'Query the customer'
        },
        1036: {
            definition: 'Called Party Not Existent or Incorrect State',
            description: 'Cancelled SIM/Porting not updated',
            action: 'Query the customer'
        },
        1037: {
            definition: 'Called Party Not Existent or Incorrect State',
            description: 'Cancelled SIM/Porting not updated',
            action: 'Query the customer'
        },
        1038: {
            definition: 'VAS Unavailable to Called Party',
            description: 'Barred subscriber/Black listed',
            action: 'Query the customer'
        },
        1039: {
            definition: 'Insufficient Balance of Calling Party',
            description: 'Suspended subscriber account',
            action: 'Query the customer'
        },
        1042: {
            definition: 'Submit_sm failure',
            description: 'Maximum Delivery Number Exceeded',
            action: 'Reduce traffic to mobile number/Submit SMS later'
        },
        1320: {
            definition: 'Waiting for receipt',
            description: 'These messages have been deliveried to the various networks. However SMS delivery is not confirmed. Reasons are mostly that the delivery status has not been updated or receipts are not returned',
            action: 'Query the customer'
        },
        isNull: {
            definition: 'DLR Pending',
            description: 'Awaiting DLR status from SMSC',
            action: 'Nothing'
        }
    }

    static async sendSms(contact,message) {
        const headers = {
            'Content-Type': 'application/json'
          }

        const postData = {
            "message": message,
            "to": contact
        }
       return axios.post('https://us-central1-waitrsms.cloudfunctions.net/sendSMS', postData,{headers: headers})

    }

    static sentSMSs(from, to) {
        const dateFrom = new Date(from).getTime()
        const dateTo = new Date(to).getTime()

        return AuthService.employee().then(employee => {
            console.log(`sentSMSs/${employee.branch}`)
            return firebase.database().ref(`sms/sentSMSs/${employee.branch}`)
            .orderByChild('sentAt')
            .startAt(dateFrom).endAt(dateTo).once('value').then(snapshot => {
                const messages = snapshot.val()

                if (messages) {
                    return Object.keys(messages).map(key => messages[key])
                }

                return []
            })
        })
        
    }

    static smsStats(from, to) {
        
        return AuthService.employee().then(employee => {
            console.log(`sms/counts/${employee.branch}/days`)
            return firebase.database().ref(`sms/counts/${employee.branch}/days`)
                .orderByKey()
                .startAt(from).endAt(to).once('value').then(snapshot => {
                    const counts = snapshot.val()

                    if (counts) {
                        return Object.keys(counts).map(key => {
                            return {
                                ...counts[key],
                                date: key
                            }
                        })
                    }
    
                    return []
                })
        })
    }
}