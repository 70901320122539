import React from 'react'
import { Card, Modal, Button, Table, Form, Row, Col } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { Formik } from 'formik';
import moment from 'moment'
import { AddExpenseModal } from './add-expense-modal';
import { ExpensesService } from '../../services/expenses-service';
import { Link } from 'react-router-dom'

export class Expenses extends React.Component {
    state = {
        expenses: null,
        types: null,
        showAddExpense: false,
        loading: true,
        total: 0
    }

    constructor(props) {
        super(props)

        this.expenseAdded = this.expenseAdded.bind(this)
        this.expenseEdited = this.expenseEdited.bind(this)
        this.editExpense = this.editExpense.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
    }

    componentDidMount() {
        // const today = moment(new Date()).format('YYYY-MM-DD')
        const beginningMonth = moment().startOf('month').format('YYYY-MM-DD')
        const endMonth = moment().endOf('month').format('YYYY-MM-DD')

        console.log(beginningMonth, endMonth)

        ExpensesService.expenses(beginningMonth, endMonth).then(expenses => {
            ExpensesService.expenseTypes().then(types => {
                const keyTypes = {}
                types.forEach(type => {
                    keyTypes[type.id] = type
                })
                this.setState({
                    expenses: expenses,
                    types: keyTypes,
                    loading: false
                })
            })
        })
    }

    onHide() {
        this.setState({
            showAddExpense: false,
            selectedExpense: null
        })
    }

    handleClose() {
        this.setState({ showDelete: false, selectedExpense: null });
    }

    handleDelete(expense) {
        this.setState({ showDelete: true, selectedExpense: expense });
    }

    deleteExpense() {
        for (let i = 0; i < this.state.expenses.length; i++) {
            if (this.state.selectedExpense.id === this.state.expenses[i].id) {
                this.state.expenses.splice(i, 1)
                this.setState({
                    expenses: this.state.expenses
                })
                break
            }
        }

        ExpensesService.remove(this.state.selectedExpense.id)
        this.handleClose()
    }


    addExpense() {
        this.setState({
            showAddExpense: true,
            selectedExpense: null
        })
    }

    editExpense(expense) {
        this.setState({
            showAddExpense: true,
            selectedExpense: expense
        })
    }

    expenseAdded(expense) {
        this.state.expenses.push(expense)
        const expenses = this.state.expenses.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        })
        this.setState({
            expenses: expenses
        })
    }

    expenseEdited(expense) {
        for (let i = 0; i < this.state.expenses.length; i++) {
            if (expense.id === this.state.expenses[i].id) {
                const newExpenses = this.state.expenses
                newExpenses[i] = expense
                this.setState({
                    expense: newExpenses
                })
                break
            }
        }
    }

    filter(data) {
        this.setState({
            expenses: null,
            loading: true
        }, () => {
            ExpensesService.expenses(data.start, data.end).then(expenses => {
                this.setState({
                    expenses: expenses,
                    loading: false
                })
            })
        })
        
    }

    calcTotal() {
        var total = 0;

        this.state.expenses.forEach(expense => {
            total += expense.cost
        })

        return total
    }

    render() {
        return (
            <Card>
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><div style={{flex: 1}}><h3>Expenses</h3>
                <Link to='/dashboard/expenses/types'><Button size='sm' variant='outline-dark' style={{marginRight: 10}}>Types</Button></Link>
                <Button size='sm' variant='outline-dark' onClick={() => this.addExpense()}>Add Expense</Button></div>
                
                <Formik 
                            initialValues={{
                                start: moment().startOf('month').format('YYYY-MM-DD'),
                                end: moment().endOf('month').format('YYYY-MM-DD')
                            }}
                            onSubmit={(values) => this.filter(values)}
                            >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values
                            }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="start">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>From</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="end">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>To</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="2">
                                                <Button type='submit'>Filter</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                
                </div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Cost</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.expenses && this.state.expenses.map((expense, index) => {
                                return (
                                    <tr key={expense.id}>
                                        <td>{index + 1}</td>
                                        <td>{expense.name}</td>
                                        <td>{expense.description.length === 0 ? '-' : expense.description}</td>
                                        <td>{this.state.types[expense.type] ? this.state.types[expense.type].name : 'Generic'}</td>
                                        <td>{expense.date}</td>
                                        <td>R {(expense.cost / 100).toFixed(2)}</td>
                                        {/* <td><Link to={`/merchant/${expense.organization.key}/branch/${expense.branch.key}/edit`}>{expense.branch.name}</Link></td> */}
                                        <td>
                                            <Button onClick={() => this.editExpense(expense)} style={{marginRight: '5px'}} variant="outline-primary" size="sm">Edit</Button>
                                            {/* <Button style={{marginRight: '5px'}} variant="outline-secondary" size="sm">Reset Password</Button> */}
                                            <Button variant="outline-danger" size="sm" onClick={() => {this.handleDelete(expense)}}>Remove</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                        {this.state.expenses && <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>R {(this.calcTotal() / 100).toFixed(2)}</td>
                            <td style={{fontWeight: 'bold'}}>Total</td>
                        </tr>} 
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>
                {!this.state.loading && this.state.expenses && this.state.expenses.length === 0 && <div style={{textAlign: 'center'}}>There are no expenses on the selected dates</div>}

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the expense '{this.state.selectedExpense && this.state.selectedExpense.name}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteExpense()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddExpenseModal expense={this.state.selectedExpense} onExpenseEdited={this.expenseEdited} onExpenseAdded={this.expenseAdded} show={this.state.showAddExpense} onHide={this.onHide} />
                </Card.Body>
            </Card>
        )
    }
}