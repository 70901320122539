import moment from "moment"
import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import { Card } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { ClipLoader } from "react-spinners"
import { ProductsService } from "../../services/products.service"

export const StockMovement = () => {
    const [actions, setActions] = useState()
    const { id } = useParams()

    useEffect(() => {
        ProductsService.stockMovement(id).then(movement => {
            console.log(movement)
            setActions(movement)
        })
    }, [])

    return (
        <div>
            <Card>
                    <Card.Body>
                        <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Stock Movement</h3></div>
                        <hr />
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Action</th>
                                    <th>Date</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {actions && actions.map((action, index) => {
                                    return (
                                        <tr key={action.id}>
                                            <td>{index + 1}</td>
                                            <td>{action.action}</td>
                                            <td>{moment(action.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                                            
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {actions && actions.length === 0 && <div style={{textAlign: 'center'}}>There hasn't been any stock movement for this product</div>}
                        {actions == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                    </Card.Body>
                </Card>
        </div>
    )
}