import React, { Component } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom'
import Firebase from './components/Firebase';
import 'typeface-roboto';
import Login from './pages/login';
import Home from './pages/home';
import { AuthService } from './services/auth.service';
import { Button } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { CreateBranch } from './pages/onboarding/create-branch';
import ForgotPasswordPage from './pages/forgot-password';
import ResetPasswordPage from './pages/reset-password';


class App extends Component {
  centerStyle = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    fontSize: '2em',
    fontWeight: '100',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }

  constructor() {
    super()
    this.state = {
      items:[],
      loggedIn: false,
      loading: true
     };

     new Firebase()

     AuthService.loggedIn().subscribe(user => {
       this.setState({
         loggedIn: !!user,
         user: user,
         loading: false
       })
     })
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  logout() {
    AuthService.logout()
  }

  showLogin() {
    return (
      this.state.loading ? <div style={this.centerStyle}><ClipLoader /></div> : <Login />
    )
  }

  showDashboard() {
    return (
      this.state.loading ? <div style={this.centerStyle}><ClipLoader /></div> : <Home />
    )
  }

  checkIfHasBranch() {
    if (this.state.user.branch == null) {
      return <Redirect to='/create-branch' />
    }
  }

  checkIfAuthorization() {
    console.log(this.state.user)
    if (this.state.user.branch == null) {
      return <Redirect to='/create-branch' />
    } else
    if (this.state.user.role === 'admin') {
      return <Redirect to='/dashboard' />
    } else {
      console.log('Not authorized')
      return (
        <div style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          fontSize: '2em',
          fontWeight: '100',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          Not Authorized<br/><span style={{fontSize: '2.5em'}}>401</span>
          <Button variant='outline-dark' size='sm' onClick={this.logout}>Back to safety</Button>
        </div>
      )
    }
  }

  render() {
    return (
      <Router>
        <Route path='/' exact render={() => {
          return (
            this.state.loggedIn ? this.checkIfAuthorization() : this.showLogin()
          )
        }} />
        <Route path='/forgot-password' exact component={ForgotPasswordPage} />
        <Route path='/reset-password' exact component={ResetPasswordPage} />
        <Route path='/dashboard' render={() => {
          return (
            this.state.loggedIn || this.state.loading === true ?  this.showDashboard() : <Redirect to='/' />
          )
        }} />
        <Route path='/create-branch' render={() => {
          return (
            this.state.loggedIn || this.state.loading === true ?  <CreateBranch /> : <Redirect to='/' />
          )
        }} />
      </Router>
    );
  }
}

export default App;
