import React from 'react'
import { Table, Button, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import { AuthService } from '../../services/auth.service';
import { SubscribeButton } from '../../components/subscribe-button';

export class Subscription extends React.Component {
    state = {
        employee: null
    }

    componentDidMount() {
        AuthService.employee().then(employee => {
            console.log(employee)
            this.setState({
                employee: employee
            })
        })
    }

    render() {
        return (
            <div>
            {this.state.employee && <div style={{
                padding: 20,
                textAlign: 'center'
            }}>
                <h3 style={{marginBottom: 40}}>Let's get started</h3>
                <h5>Select your package:</h5>
                <p>Click on the Subcribe Now button next to the relevant package you want to sign up for, to activate your account. You can scroll down for a detailed description of each package.</p>

                <Table bordered bordered style={{textAlign: 'left'}}>
                    <thead>
                        <tr>
                            <th>Package</th>
                            <th>Price</th>
                            <th>Sign-up</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Free</td>
                            <td>R0.00p/m</td>
                            <td>Already Subscribed</td>
                        </tr>
                        <tr>
                            <td>Standard</td>
                            <td>R699.00p/m</td>
                            <td><SubscribeButton packageType='standard' employee={this.state.employee} /></td>
                        </tr>
                        <tr>
                            <td>Advanced</td>
                            <td>R899.00p/m</td>
                            <td><SubscribeButton packageType='advanced' employee={this.state.employee} /></td>
                        </tr>
                    </tbody>
                </Table>


                <h3 style={{marginTop: 40}}>Waitr Full Package</h3>
                <p>Save up to R6000 on equipment and setup when signing up for 24 month contract</p>
                <Table bordered style={{textAlign: 'left'}}>
                    <thead>
                        <tr>
                            <th>Package</th>
                            <th>Price</th>
                            <th>Sign-up</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li>Advanced Waitr Package</li>
                                    <li>10.1" Android Tablet</li>
                                    <li>LTE WiFi Modem</li>
                                    <li>1D Barcode Scanner</li>
                                    <li>80m Thermal Receipt Printer</li>
                                </ul>
                            </td>
                            <td>R899.00p/m for 24 months</td>
                            <td><SubscribeButton packageType='advanced' employee={this.state.employee} /></td>
                        </tr>
                    </tbody>
                </Table>

                <h3 style={{marginTop: 40}}>Package Descriptions</h3>
                <p>Detailed descriptions of the Waitr Packages</p>
                <Accordion style={{textAlign: 'left'}}>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Free
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul>
                                    <li>Notify your customers when their order is ready</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Standard
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <ul>
                                    <li>Notify your customers when their order is ready</li>
                                    <li>Integrated Point of Sale</li>
                                    <li>Useful Business Insights</li>
                                    <li>Inventory Management</li>
                                    <li>Loyalty Points &amp; Discounts</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                Advanced
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <ul>
                                    <li>Notify your customers when their order is ready</li>
                                    <li>Integrated Point of Sale</li>
                                    <li>Useful Business Insights</li>
                                    <li>Inventory Management</li>
                                    <li>Loyalty Points &amp; Discounts</li>
                                    <li>View Customer Ratings</li>
                                    <li>Send Campaigns to your Customers</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                Waitr Full Package
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <ul>
                                    <li>Notify your customers when their order is ready</li>
                                    <li>Integrated Point of Sale</li>
                                    <li>Useful Business Insights</li>
                                    <li>Inventory Management</li>
                                    <li>Loyalty Points &amp; Discounts</li>
                                    <li>View Customer Ratings</li>
                                    <li>Send Campaigns to your Customers</li>
                                </ul>
                                <h6>Receive the following hardware</h6>
                                <ul>
                                    <li>10.1" Android Tablet</li>
                                    <li>LTE WiFi Modem</li>
                                    <li>1D Barcode Scanner</li>
                                    <li>80m Thermal Receipt Printer</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>}
            </div>
        )
    }
}