import React from 'react'
import { Form, Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import {EmployeesService} from '../../services/employee-service';
import { ClipLoader } from 'react-spinners'

export class AddEmployeeModal extends React.Component {
    state = {
        saving: false
    }

    constructor(props) {
        super(props)

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
        }
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        this.setState({
            saving: true
        })

        if (this.props.employee) {
            EmployeesService.edit(this.props.employee.id, values).then(employee => {
                if (this.props.onEmployeeEdited) {
                    this.props.onEmployeeEdited(employee)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        } else {
            EmployeesService.add(values).then(employee => {
                if (this.props.onEmployeeAdded) {
                    this.props.onEmployeeAdded(employee)
                }
                this.onHide()
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        }
        
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.employee ? {
                    ...this.props.employee
                } : {
                    firstname: '',
                    lastname: '',
                    role: 'employee'
                }}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Employee</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='firstname'>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control value={values.firstname} onBlur={handleBlur} onChange={handleChange} type='text' />
                                    </Form.Group>
                                    <Form.Group controlId='lastname'>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control value={values.lastname} onBlur={handleBlur} onChange={handleChange} type='text' />
                                    </Form.Group>
                                    <Form.Group controlId='role'>
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control value={values.role} onBlur={handleBlur} onChange={handleChange} as='select' >
                                            <option value='employee'>Employee</option>
                                            <option value='supervisor'>Supervisor</option>
                                            <option value='manager'>Manager</option>
                                        </Form.Control>
                                    </Form.Group>
                                    {/* {!this.props.employee && <Form.Group controlId='password'>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control value={values.password} onBlur={handleBlur} onChange={handleChange} type='password' />
                                    </Form.Group>} */}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm}>
                                    <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}