import React, { Component } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import OrdersIcon from '@material-ui/icons/StoreMallDirectory'
import MoneyIcon from '@material-ui/icons/Money'
import AvgTimeIcon from '@material-ui/icons/Timelapse'
import TotalTimeIcon from '@material-ui/icons/Timer'
import {red, blue, green, orange} from '@material-ui/core/colors'
import { CompareOrders } from '../components/dashboard/compare-orders';
import { RecentOrders } from '../components/dashboard/recent-orders';
import { StatsService } from '../services/stats.service';
import { CompareRevenue } from '../components/dashboard/compare-revenue';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { ReturningCustomers } from '../components/dashboard/returning-customes';
import { PopularProducts } from '../components/dashboard/popular-products';
import { Formik } from 'formik';
import moment from 'moment'

class Dashboard extends Component {
    

    constructor(props) {
        super(props)

        this.state = {
            stats: {
                orders: null,
                revenue: null,
                averageTime: null,
                regulars: null
            },
            statsDif: null
        }
    }

    componentDidMount() {
        const beginningMonth = moment().startOf('month').format('YYYY-MM-DD')
        const today = moment(new Date()).format('YYYY-MM-DD')

        this.updateStates({
            start: beginningMonth,
            end: today
        })

        // StatsService.monthStats().then(stats => {
        //     console.log(stats)
            
            
        // })

        
    }

    renderCounter(title, value, icon, color, prevValue, percentage, positive) {
        const upArrow = prevValue.indexOf('-') === -1 ? true : false

        return (
            <Card>
                <Card.Body style={{display: 'flex'}}>
                    <div style={{background: color, 
                        borderRadius: '50%', 
                        width: '40px', 
                        height: '40px', 
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    
                        {icon}
                        {/* <OrdersIcon style={{color: '#ffffff'}} /> */}
                    </div>
                    <div style={{textAlign: 'right', flex: 1}}>
                        <Card.Subtitle>{title}</Card.Subtitle> 
                        <Card.Title style={{margin: 0}}>{value == null ? '-' : value}</Card.Title> 
                        {prevValue != '-' ? <div style={{
                            color: positive ? 'green' : 'red',
                            fontSize: '0.8em'
                        }}>{upArrow ? <ArrowUpward /> : <ArrowDownward />} <span>{prevValue} ({percentage})</span></div> : <div>-</div>}
                    </div>
                </Card.Body>
            </Card>
        )
    }

    customDateStats(start, end) {
        return StatsService.dailyStats(start, end).then(orders => {
            
            
            console.log(orders)

            return new Promise((resolve) => {
                const stats = {
                    orders: 0,
                    revenue: 0,
                    times: {
                        total: 0,
                        validTimes: 0
                    }
                }

                for (let order of orders) {
                    stats.orders += order.orders
                    stats.revenue += order.revenue
                    stats.times.total += order.times.total
                    stats.times.validTimes += order.times.validTimes
                }

                resolve(stats)
            })
        })
    }

    updateStates(values) {
        const startLastMonth = moment(values.start).subtract(1, 'month').format('YYYY-MM-DD')
        const endLastMonth = moment(values.end).subtract(1, 'month').format('YYYY-MM-DD')
        console.log(startLastMonth, endLastMonth)

        return this.customDateStats(values.start, values.end).then(stats => {
            return this.customDateStats(startLastMonth, endLastMonth).then(lastStats => {
                console.log(stats, lastStats)
                const statsDif = {
                    orders: stats.orders - lastStats.orders,
                    revenue: stats.revenue - lastStats.revenue,
                    totalTime: stats.times.total - lastStats.times.total,
                    averageTime: 0
                }

                if (stats.times.validTimes > 0 && lastStats.times.validTimes > 0) {
                    statsDif.averageTime = Math.round((stats.times.total / stats.times.validTimes) - (lastStats.times.total / lastStats.times.validTimes))
                }

                this.setState({
                    stats: stats,
                    statsDif: statsDif
                })

                console.log(statsDif)
            })
        })
    }

    averageTimePercentage() {
        return this.state.statsDif ? this.calcPercentage(Math.round(this.state.stats.times.total / this.state.stats.times.validTimes), this.state.statsDif.averageTime) : ''
    }

    totalTimePercentage() {
        return this.state.statsDif ? this.calcPercentage(this.state.stats.times.total, this.state.statsDif.totalTime) : ''
    }

    calcPercentage(old, dif) {
        let value = (dif / old * 100).toFixed(0) + '%'

        if (value == 'NaN%') {
            value = '0%'
        }
        
        return value
    }

    render() {
        const beginningMonth = moment().startOf('month').format('YYYY-MM-DD')
        const bottomMargin = {marginBottom: '20px'}
        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                        <h3 style={{flexGrow: 1}}>Dashboard</h3>
                        <Formik 
                            initialValues={{
                                start: beginningMonth,
                                end: moment(new Date()).format('YYYY-MM-DD')
                            }}
                            onSubmit={(values) => this.updateStates(values)}
                            >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values
                            }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="start">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>From</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="end">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>To</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="2">
                                                <Button type='submit'>Filter</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                        
                    </div>
                <Row>
                    <Col lg={4} md={4} sm={12} >
                        {this.renderCounter('Orders', this.state.stats.orders, <OrdersIcon style={{color: 'white'}} />, red['300'], this.state.statsDif ? ""+this.state.statsDif.orders : '-', this.state.statsDif ? this.calcPercentage(this.state.stats.orders, this.state.statsDif.orders) : '', this.state.statsDif ? this.state.statsDif.orders > 0 : true)}
                    </Col>
                    <Col lg={4} md={4} sm={12} >
                        {this.renderCounter('Revenue', this.state.stats.revenue == null ? null : 'R' + (this.state.stats.revenue / 100).toFixed(2), <MoneyIcon style={{color: 'white'}} />, blue['300'], this.state.statsDif ? "R"+ (this.state.statsDif.revenue / 100).toFixed(2) : '-', this.state.statsDif ? this.calcPercentage(this.state.stats.revenue, this.state.statsDif.revenue) : '', this.state.statsDif ? this.state.statsDif.revenue > 0 : true)}
                    </Col>
                    <Col lg={4} md={4} sm={12} >
                        {this.renderCounter('Avg Time', /*this.state.stats.times == null || this.state.stats.times.validTimes === 0 ? null : StatsService.timeFromSeconds(Math.round(this.state.stats.times.total / this.state.stats.times.validTimes))*/ '48:04', <AvgTimeIcon style={{color: 'white'}} />, orange['300'], /*this.state.statsDif ? StatsService.timeFromSeconds(this.state.statsDif.averageTime) : '-'*/ '02:01', /*this.averageTimePercentage()*/ '6%', /*this.state.statsDif ? this.state.statsDif.averageTime < 0 : true)*/ false)}
                    </Col>
                    {/* <Col lg={3} md={6} sm={12} >
                        {this.renderCounter('Total Time', this.state.stats.times == null ? null : StatsService.timeFromSeconds(this.state.stats.times.total), <TotalTimeIcon style={{color: 'white'}} />, green['300'], this.state.statsDif ? StatsService.timeFromSeconds(this.state.statsDif.totalTime) : '-', this.totalTimePercentage(), this.state.statsDif ? this.state.statsDif.totalTime > 0 : true)}
                    </Col> */}
                </Row>

                <hr />

                <Row style={bottomMargin}>
                    <Col md={6}>
                        <CompareOrders />
                    </Col>
                    <Col md={6}>
                        <CompareRevenue />
                    </Col>
                </Row>

                {/* <Row style={bottomMargin}>
                    <Col md={6}>
                        <ReturningCustomers />
                    </Col>
                    <Col md={6}>
                        <PopularProducts />
                    </Col>
                </Row> */}

                <Row style={bottomMargin}>
                    <Col>
                        <RecentOrders />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Dashboard;