import React from 'react'
import { Modal, ListGroup } from 'react-bootstrap';
import { OrdersService } from '../../services/orders-service';
import moment from 'moment'
import { EmployeesService } from '../../services/employee-service';

export class ViewEmployeesModal extends React.Component {
    state = {
        employees: []
    }

    constructor(props) {
        super(props)

        this.onHide = this.onHide.bind(this)
    }

    onHide() {
        if (this.props.onHide != null) {
            this.props.onHide()
        }
    }

    componentDidMount() {
        console.log(this.props.order.employees)
        EmployeesService.employeesByIds(this.props.order.employees).then(employees => {
            this.setState({
                employees: employees
            })
            console.log(employees)
        })
    }

    render() {
        return (
            <Modal show={true} onHide={this.onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>View Employees</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {this.state.employees.reverse().filter(employee => employee != null).map((employee, index) => {
                            return (
                                <ListGroup.Item key={index}>
                                    {employee.firstname} {employee.lastname}<br />
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Modal.Body>
            </Modal> 
        )
    }
}