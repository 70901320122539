import React from 'react'
import { Tabs, Tab, Badge } from 'react-bootstrap'
import { SentSMSs } from './sent'
import { SMSDashboard } from './dashboard'

export const Messages = () => {
    const tabStyle = {
        marginTop: 20
    }
    return (
        <div>
            <Tabs defaultActiveKey="dashboard" id="settings">
                <Tab eventKey="dashboard" title="Dashboard">
                    <div style={tabStyle}><SMSDashboard /></div>
                </Tab>
                <Tab eventKey="campaigns" title="Campaigns">
                    {/* <BranchList /> */}
                </Tab>
                <Tab eventKey="sent" title="Sent">
                    <div style={tabStyle}><SentSMSs /></div>
                </Tab>
                <Tab eventKey="inbox" title={<div>Inbox <Badge variant="danger">2</Badge></div>}>
                    {/* <BranchList /> */}
                </Tab>
                <Tab eventKey="settings" title="Settings">
                    {/* <BranchList /> */}
                </Tab>
            </Tabs>
        </div>
    )
}