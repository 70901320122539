import React from 'react'
import { Card, Form } from 'react-bootstrap';
import { AreaChart, linearGradient, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts';
import { Formik } from 'formik';
import moment from 'moment'
import { StatsService } from '../../services/stats.service';

function DrawChart(props) {
  const data = props.data
  const oldData = props.oldData
  console.log({
    data: data,
    oldData: oldData
  })

  for (let i = 0; i < data.length; i++) {
    if (oldData[i]) {
      data[i].oldOrders = oldData[i].orders
    } else {
      data[i].oldOrders = 0
    }
  }

    return (
        <ResponsiveContainer width='99%' height={300} data={data}>
          <AreaChart height={200} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0.5}/>
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.5}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area dot={{ stroke: '#82ca9d', strokeWidth: 2 }} type="linear" dataKey="oldOrders" stroke="#82ca9d" fillOpacity={0.6} fill="url(#colorPv)" />
            <Area dot={{ stroke: '#8884d8', strokeWidth: 2 }} type="linear" dataKey="orders" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            
            </AreaChart>
        </ResponsiveContainer>
    )
}

export class CompareOrders extends React.Component {
    state = {
      data: [],
      durationType: 'week'
    }

    componentDidMount() {
      this.onSubmit(this.state.durationType)
    }

    onSubmit(value) {
      console.log(value)
      let startDate, endDate, promise, prevStartDate, prevEndDate

      endDate = moment(new Date())
      switch (value) {
        case 'week':
          startDate = moment(endDate).subtract(6, 'day').format('YYYY-MM-DD')
          prevStartDate = moment(startDate).subtract(1, 'week').format('YYYY-MM-DD')
          prevEndDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
          console.log(prevStartDate, prevEndDate)
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD')).then(current => {
            return StatsService.dailyStats(prevStartDate, prevEndDate).then(previous => {
              console.log(previous)
              return {
                current: current,
                previous: previous
              }
            })
          })
          break;
        case 'month':
          startDate = moment(endDate).subtract(1, 'month').format('YYYY-MM-DD')
          prevStartDate = moment(startDate).subtract(1, 'month').format('YYYY-MM-DD')
          prevEndDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD')).then(current => {
            return StatsService.dailyStats(prevStartDate, prevEndDate).then(previous => {
              console.log(previous)
              return {
                current: current,
                previous: previous
              }
            })
          })
          break
        case 'year':
          startDate = moment(endDate).subtract(1, 'year').format('YYYY-MM-DD')
          prevStartDate = moment(startDate).subtract(1, 'year').format('YYYY-MM-DD')
          prevEndDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
          promise = StatsService.monthlyStats(startDate, endDate.format('YYYY-MM-DD')).then(current => {
            return StatsService.dailyStats(prevStartDate, prevEndDate).then(previous => {
              console.log(previous)
              return {
                current: current,
                previous: previous
              }
            })
          })
          break
        default:
          break;
      }
      
      
      promise.then(data => {
        let currentData = data.current.map(item => {
          let name
          if (value === 'week') {
            name = item.day
          } else if (value === 'year') {
            name = item.month
          } else {
            name = item.day
          }
          return {
            name: name,
            orders: item.orders
          }
        })

        let oldData = data.previous.map(item => {
          let name
          if (value === 'week') {
            name = item.day
          } else if (value === 'year') {
            name = item.month
          } else {
            name = item.day
          }
          return {
            name: name,
            orders: item.orders
          }
        })

        if (value === 'year') {
            currentData = currentData.map(item => {
              item.name = moment(new Date(item.name + '-01')).format('YYYY MMM')

              return item
            })
        }

        if (value === 'year') {
          oldData = oldData.map(item => {
            item.name = moment(new Date(item.name + '-01')).format('YYYY MMM')

            return item
          })
      }

        this.setState({
          data: currentData,
          oldData: oldData
        })
        // console.log(data)
      })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <div style={{display: 'flex'}}>
                      <Card.Title style={{flex: 1}}>Orders</Card.Title>
                      <Formik
                        initialValues={{
                          durationType: this.state.durationType
                        }}
                        validateOnChange={(value) => {
                          console.log(value)
                        }}
                      >
                        {({
                          values,
                          handleChange
                        }) => {
                          return (
                            <Form>
                              <Form.Control value={values.durationType} id='durationType' as="select" onChange={(e) => {
                                handleChange(e)
                                this.onSubmit(e.target.value)
                              }}>
                                <option value='week'>Week</option>
                                <option value='month'>Month</option>
                                <option value='year'>Year</option>
                              </Form.Control>
                            </Form>
                          )
                        }}
                      </Formik>
                      
                    </div>
                    <DrawChart oldData={this.state.oldData} data={this.state.data} />
                </Card.Body>
            </Card>
        )
    }
}