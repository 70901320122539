import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend, Cell } from "recharts"
import {blue, amber, orange, purple, brown, lightBlue, indigo, pink} from '@material-ui/core/colors'

export const DeliveryErrorsChart = ({data}) => {
    const COLORS = [
        blue['300'],
        amber['300'],
        orange['300'],
        purple['300'],
        brown['300'],
        lightBlue['300'],
        indigo['300'],
        pink['300']
    ]
    return (
        <ResponsiveContainer width='99%' height={300} data={data}>
          <PieChart width={200} height={200}>
            <Pie isAnimationActive={true} label={true} data={data} dataKey="value" nameKey="name" width={200} height={200} cx="50%" cy="50%" outerRadius={120} fill="#8884d8" >
            {
          	    data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
            }
            </Pie>
            <Tooltip />
            <Legend verticalAlign="top" height={36}/>
          </PieChart>
        </ResponsiveContainer>
    )
}