import React from 'react'
import { Table, Card } from 'react-bootstrap';
import { OrdersService } from '../../services/orders-service'
import moment from 'moment'

export class RecentOrders extends React.Component {
    state = {
        orders: []
    }
    componentDidMount() {
        OrdersService.recentOrders().then(orders => {
            this.setState({
                orders: orders
            })
        })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title>Recent Orders</Card.Title>
                    <hr />
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Order</th>
                                <th>Description</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Duration</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orders.map((order, index) => {
                                const startDate = moment(new Date(order.createdAt))
                                const endDate = moment(new Date(order.finishedAt))
                                const diff = endDate.diff(startDate)
                                const duration = order.duration === -1 ? '-' : moment.utc(diff).format('HH:mm:ss')

                                return (
                                    <tr key={order.id}>
                                        <td>{index + 1}</td>
                                        <td>{order.order}</td>
                                        <td>{order.description ? order.description : order.name}</td>
                                        <td>{moment(new Date(order.createdAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td>{order.finishedAt === 0 ? '-' : moment(new Date(order.finishedAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td>{duration}</td>
                                        <td>R{(order.price / 100).toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        )
    }
}