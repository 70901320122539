import firebase from 'firebase'
import config from '../../config';

class Firebase {
    constructor() {
        try {
            firebase.initializeApp(config.firebase)
        }
        catch (e) {

        }
    }

    merchants() {
        return firebase.database().ref(`organizations`).orderByChild('name').once('value').then(merchants => {
            if (merchants == null) {
                return []
            }

            return Object.keys(merchants).map(key => {
                return {
                    ...merchants[key],
                    key: key
                }
            })
        })
    }
}

export default Firebase