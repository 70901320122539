import React, { Component } from "react";
// import { Navbar } from "react-bootstrap";
// import Sidebar from "../components/Sidebar";
import Content from "../components/Content";
import { AppBar, Button, Toolbar, IconButton, Typography, Drawer, Divider, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import PrintIcon from '@material-ui/icons/Print';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined'
import RatingsIcon from '@material-ui/icons/Star'
import MessagingIcon from '@material-ui/icons/Message'
// import DiscountIcon from '@material-ui/icons/Cal'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import StockIcon from '@material-ui/icons/ShoppingBasket'
import CardIcon from '@material-ui/icons/CreditCard'
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory'
import PeopleIcon from '@material-ui/icons/People'
import PeopleOutline from '@material-ui/icons/PeopleOutline'
import ProductsIcon from '@material-ui/icons/ShoppingCart'
import CustomersIcons from '@material-ui/icons/Face'
import ExpensesIcon from '@material-ui/icons/Assignment';
// import MailIcon from '@material-ui/icons/Mail';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import { AuthService } from "../services/auth.service";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: '#2f2936'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});

const menuItems = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        icon: <BarChartOutlinedIcon />
    },
  //   {
  //     name: 'Bookings',
  //     link: '/dashboard/bookings',
  //     icon: <CalendarIcon />
  // },
    {
      name: 'Orders',
      link: '/dashboard/orders',
      icon: <AttachMoneyIcon />
    },
    {
      name: 'Stock',
      link: '/dashboard/stock',
      icon: <StockIcon />
    },
    {
      name: 'Payments',
      link: '/dashboard/payments',
      icon: <CardIcon />
    },
    // {
    //     name: 'Merchants',
    //     link: '/dashboard/merchants',
    //     icon: <StoreMallDirectoryIcon />
    // },
    {
      name: 'Products',
      link: '/dashboard/products',
      icon: <ProductsIcon />
    },
    {
      name: 'Expenses',
      link: '/dashboard/expenses',
      icon: <ExpensesIcon />
    },
    {
      name: 'Customers',
      link: '/dashboard/customers',
      icon: <CustomersIcons />
    },
    {
      name: 'Messages',
      link: '/dashboard/messages',
      icon: <MessagingIcon />
    },
    {
      name: 'Ratings & Reviews',
      link: '/dashboard/ratings',
      icon: <RatingsIcon />
    },
    {
        name: 'Discounts',
        link: '/dashboard/discounts',
        icon: <StoreMallDirectoryIcon />
    },
    {
      name: 'Employees',
      link: '/dashboard/employees',
      icon: <PeopleOutline />
  },
    {
        name: 'Users',
        link: '/dashboard/users',
        icon: <PeopleIcon />
    },
    {
      name: 'Settings',
      link: '/dashboard/settings',
      icon: <SettingsIcon />
  },
    // {
    //     name: 'Admin',
    //     link: '/dashboard/admin',
    //     icon: <PeopleOutline />
    // }
]

class Home extends Component {
    state = {
        open: true,
        showLogout: false
      };

    constructor(props) {
      super(props)

      this.handleClose = this.handleClose.bind(this)
    }

    logout() {
      this.setState({
        showLogout: true
      })
    }

    print() {
      window.print()
    }

    handleClose() {
      this.setState({
        showLogout: false
      })
    }

    confirmLogout() {
      AuthService.logout()
    }
    
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    
    render() {
        const { classes, theme } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })} >
                    <Toolbar disableGutters={!this.state.open} color='#2f2936' style={{background: '#2f2936'}}>
                        <IconButton  color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: this.state.open,
                            })}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" style={{flexGrow: 1}}>
                            Waitr Portal
                        </Typography>
                        <IconButton color="inherit" onClick={() => this.print()}>
                          <PrintIcon />
                        </IconButton>
                        <Button color="inherit" onClick={() => this.logout()}>Logout</Button>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent"
                    style={{backgroundColor: '#2F2936 !important'}}
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: classNames({
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                    open={this.state.open}>
                <div className={classes.toolbar} style={{background: '#2f2936'}}>
                    <IconButton onClick={this.handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon style={{color: 'white'}} /> : <ChevronLeftIcon style={{color: 'white'}} />}
                    </IconButton>
                </div>
                {/* <Divider /> */}
                <div style={{backgroundColor: '#2F2936', height: '100%'}}>
                  <List style={{backgroundColor: '#2F2936'}}>
                      {menuItems.map((item) => (
                      <Link key={item.link} to={item.link}>
                          <ListItem button>
                              <ListItemIcon style={{color: 'white'}}>{item.icon}</ListItemIcon>
                              <ListItemText disableTypography color='white' style={{color: 'white'}} primary={<Typography style={{color: 'white'}}>{item.name}</Typography>} />
                          </ListItem>
                      </Link>
                      ))}
                  </List>
                </div>
                
                {/* <Divider /> */}
                </Drawer>

                <main className={classes.content}>
                
          <div className={classes.toolbar} />
          <Content></Content>
        </main>
                
        <Modal show={this.state.showLogout} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Logout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to logout?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.confirmLogout()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  };

export default withStyles(styles, { withTheme: true })(Home);