import React from 'react'
import { SubscriptionService } from '../../services/subscription-service';
import ReactDOM from 'react-dom';
import { Formik } from 'formik';
import SaltedMd5 from 'salted-md5'
import { AuthService } from '../../services/auth.service';

export class Subscribe extends React.Component {
    form
    state = {
        initialValues: {
            merchant_id: '11612308',
                merchant_key: '1q8b0zoyh0b38',
                return_url: 'http://localhost:3000/dashboard/settings',
                cancel_url: 'https://portal.waitr.co.za/dashboard/settings',
                notify_url: 'https://us-central1-waitr-d321d.cloudfunctions.net/payment',
                name_first: '',
                name_last: '',
                email_address: '',
                amount: 0,
                item_name: 'Waitr Subscription',
                item_description: 'Advanced',
                email_confirmation: 1,
                // subscription_type: 1,
                // recurring_amount: 899,
                // frequency: 3,
                custom_str1: '',
                payment_method: 'sc'
        }
    }

    componentDidMount() {
        AuthService.employee().then(employee => {
            const names = employee.name.split(' ')
            const initialValues = {
                merchant_id: '11612308',
                merchant_key: '1q8b0zoyh0b38',
                return_url: 'http://portal.waitr.co.za/dashboard/settings',
                cancel_url: 'https://portal.waitr.co.za/dashboard/settings',
                notify_url: 'https://us-central1-waitr-d321d.cloudfunctions.net/payment',
                name_first: names[0],
                name_last: names[1] ? names[1] : '',
                email_address: employee.username,
                amount: 0,
                item_name: 'Waitr Subscription',
                item_description: 'Advanced',
                email_confirmation: 1,
                // subscription_type: 1,
                // recurring_amount: 899,
                // frequency: 3,
                custom_str1: employee.organization,
                payment_method: 'eft'
            }

            const keys = Object.keys(initialValues)
            const params = keys.map(key => `${key}=${initialValues[key]}`).join('&')
            const hash = SaltedMd5(params, 'blahblahblah')
            initialValues['security'] = hash

            this.setState({
                initialValues: initialValues
            }, () => {
                console.log(this.state.initialValues)
            })
        })
    }

    render() {
        return (
            <div>
                <Formik enableReinitialize={true} initialValues={this.state.initialValues ? this.state.initialValues : {

                }}>
                    {({
                        values
                    }) => {
                        return (
                            <form ref={el => this.form = el} target="_blank" action="https://www.payfast.co.za/eng/process" method="POST">
                            <input type="hidden" name="merchant_id" value={values.merchant_id} />
                            <input type="hidden" name="merchant_key" value={values.merchant_key} />
                            <input type="hidden" name="return_url" value={values.return_url} />
                            <input type="hidden" name="cancel_url" value={values.cancel_url} />
                            <input type="hidden" name="notify_url" value={values.notify_url} />
                            <input type="hidden" name="name_first" value={values.name_first} />
                            <input type="hidden" name="email_address" value={values.email_address} />
                            <input type="hidden" name="amount" value={values.amount} />
                            <input type="hidden" name="item_name" value={values.item_name} />
                            <input type="hidden" name="item_description" value={values.item_description} />
                            <input type="hidden" name="email_confirmation" value={values.email_confirmation} />
                            {/* <input type="hidden" name="subscription_type" value={values.subscription_type} /> */}
                            {/* <input type="hidden" name="recurring_amount" value={values.recurring_amount} /> */}
                            {/* <input type="hidden" name="frequency" value={values.frequency} /> */}
                            <input type="hidden" name="custom_str1" value={values.hash} />
                            <input type="hidden" name="payment_method" value={values.payment_method} />
                            <input type="hidden" name="signature" value={values.hash} />

                            <input type='submit' value='Submit' />
                        </form>
                        )
                    }}
                </Formik>
                
            </div>
        )
    }
}