import React from 'react'
import { Formik } from 'formik';
import { Form, Button, Modal } from 'react-bootstrap';
import { ProductsService } from '../../services/products.service'
import { CategoriesService } from '../../services/category-service'
import { ClipLoader } from 'react-spinners'

export class AddStockModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            product: this.props.product ? {
                ...this.props.product,
                price: this.props.product.price / 100
            } : null,
            saving: false
        }

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
            this.setState({
                product: null
            })
        }
    }

    componentDidUpdate() {
        
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        values = Object.assign({}, values)

        this.setState({
            saving: true
        })

        ProductsService.addStock(this.props.product, values.stock).then(() => {
            if (this.props.onStockAdded != null) {
                this.props.onStockAdded(this.props.product, values.stock)
                this.onHide()
            }
        }).finally(() => {
            this.setState({
                saving: false
            })
        })
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{stock: null}}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Stock</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='stock'>
                                        <Form.Label>Add Stock</Form.Label>
                                        <Form.Control value={values.stock} onBlur={handleBlur} onChange={handleChange} type='number' placeholder='Input number of stock to add' />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm} disabled={this.state.saving}>
                                        <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}