import React from 'react'
import { Card, Button, Row, Col, Form, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import moment from 'moment'
import { EmployeesService } from '../../services/employee-service';
import { CheckinService } from '../../services/checkin.services';
import Check from '@material-ui/icons/CheckCircle'
import Close from '@material-ui/icons/Close'

function Absent() {
    return <Close style={{color: 'red'}} />
}

function Present() {
    return <Check style={{color: 'green'}} />
}

export class Reports extends React.Component {
    subscription
    state = {
        employees: [],
        report: [],
        loading: true,
        showHours: false
    }

    componentWillUnmount() {
        this.subscription.unsubscribe()
    }

    componentDidMount() {
        const startOfWeek = moment().startOf('week').format('YYYY-MM-DD')
        const endOfWeek = moment().endOf('week').format('YYYY-MM-DD')

        this.subscription = EmployeesService.employees().subscribe(employees => {
            this.setState({
                employees: employees
            })
        })

        this.filter({
            start: startOfWeek,
            end: endOfWeek
        })
    }

    leadingZero(num) {
        if (num > 9) {
            return `${num}`
        } else {
            return `0${num}`
        }
    }

    showHours(employee) {
        if (!employee) {
            return {
                hours: '0',
                color: 'red'
            }
        } else if (!employee.checkout) {
            return {
                color: 'orange',
                hours: '-'
            }
        }

        var checkInTime = moment(new Date(employee.checkin))
        var checkOutTimeMoment = moment(new Date(employee.checkout))
        // checkOutTime = checkOutTimeMoment.format('HH:mm')

        // hours = checkOutTimeMoment.diff(checkInTime, 'hours')
        var min = checkOutTimeMoment.diff(checkInTime, 'minutes')
        var hours = Math.floor(min / 60)
        min = min - (hours * 60)

        return {
            hours: `${this.leadingZero(hours)}:${this.leadingZero(min)}`,
            color: 'green'
        }
    }

    totalAttendance(employee) {

    }

    totalHours(employee) {

    }

    filter(data) {
        CheckinService.report(data.start, data.end).then(report => {
            var currentDate = data.start

            while (currentDate <= data.end) {
                if (report && report[currentDate] == null) {
                    report[currentDate] = {}
                }
                currentDate = moment(currentDate).add(1, 'day').format('YYYY-MM-DD')
            }

            if (report) {
                this.setState({
                    loading: false,
                    report: Object.keys(report).map(key => {
                        return {
                            ...report[key],
                            id: key
                        }
                    }).sort((a, b) => {
                        if (a.id > b.id) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                })
            }
        })
    }

    render() {
        const startOfWeek = moment().startOf('week').format('YYYY-MM-DD')
        const endOfWeek = moment().endOf('week').format('YYYY-MM-DD')

        return (
            <Card>
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Reports</h3>
                <Formik 
                            initialValues={{
                                start: startOfWeek,
                                end: endOfWeek
                            }}
                            onSubmit={(values) => this.filter(values)}
                            >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values
                            }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="start">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>From</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="end">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>To</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="2">
                                                <Button type='submit'>Filter</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                </div>
                <Form.Check onChange={(data) => {
                    console.log(data.target.checked)
                    this.setState({
                        showHours: data.target.checked
                    })
                    
                }} type="checkbox" label="Show hours" />
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{
                                position: '-webkit-sticky',
                                top: 60
                            }}></th>
                            {this.state.employees.map(employee => {
                                return (
                                    <th key={employee.id}>{employee.firstname} {employee.lastname}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.report.map((day, index) => {
                                return (
                                    <tr key={day.id}>
                                        <td>{day.id}</td>
                                        {this.state.employees.map(employee => {
                                            const hours = this.showHours(day[employee.id]);
                                            var checkin = ''
                                            var checkout = ''
                                            if (day[employee.id]) {
                                                checkin = moment(new Date(day[employee.id].checkin)).format('HH:mm')
                                                checkout = day[employee.id].checkout ? moment(new Date(day[employee.id].checkout)).format('HH:mm') : null
                                            }
                                            return (
                                                <td key={employee.id}>
                                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                        {this.state.showHours ? <div style={{
                                                            color: hours.color,
                                                            fontWeight: 'bold'
                                                        }}><div style={{textAlign: 'center'}}>{hours.hours}</div><div style={{fontSize: '0.7em'}}>{checkin} - {checkout}</div></div> : day[employee.id] ? <Present /> : <Absent />}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                </Card.Body>
            </Card>
        )
    }
}