import { AuthService } from "./auth.service";
import firebase from 'firebase'

export class CategoriesService {
    static async categories() {
        const category = await AuthService.employee()
        const snapshots = await firebase.firestore().collection('categories')
        .where('branch', '==', category.branch)
        .where('active', '==', true)
        .orderBy('name').get()

        

        return snapshots.docs.map(doc => {
            let visibility
            const category = doc.data()

            if (category.showInstore && category.showOnline) {
                visibility = 'instore-online'
            } else if (category.showInstore) {
                visibility = 'instore'
            } else if (category.showOnline) {
                visibility = 'online'
            } else {
                visibility = 'hidden'
            }
            
            return {
                ...doc.data(),
                id: doc.id,
                visibility: visibility
            }
        })
    }

    static async add(category) {
        const user = await AuthService.employee()

        category = {
            ...category,
            branch: user.branch,
            organization: user.organization,
            global: true,
            active: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        }

        const ref = await firebase.firestore().collection('categories').add(category)

        return {
            ...category,
            id: ref.id
        }
    }

    static async edit(id, category) {
        await firebase.firestore().collection('categories').doc(id).update(category)
        
        return {
            ...category,
            id: id,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        }
    }

    static async remove(id) {
        return await firebase.firestore().collection('categories').doc(id).delete()
    }
}