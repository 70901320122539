import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { Employees } from './employees';
import { CheckIns } from './checkins';
import { CheckOuts } from './checkouts';
import { Reports } from './reports';

export class EmployeeManagement extends React.Component {
    render() {
        return (
            <Tabs defaultActiveKey="employees" id="employees">
                <Tab eventKey="employees" title="Employees">
                    <Employees />
                </Tab>
                <Tab eventKey="checkins" title="CheckIns">
                    <CheckIns />
                </Tab>
                <Tab eventKey="checkouts" title="CheckOuts">
                    <CheckOuts/>
                </Tab>
                <Tab eventKey="reports" title="Reports">
                    <Reports />
                </Tab>
            </Tabs>
        )
    }
}