import firebase from 'firebase'
import { AuthService } from './auth.service';
import axios from 'axios'
import config from '../config';

class UserService {
    static async registerUser(user) {    
        const employee = await AuthService.employee()
        return axios.post(config.apiUrl + 'registerUser', {
            ...user,
            branch: employee.branch,
            organization: employee.organization
        }).then(response => {
            return {
                ...response.data,
                key: response.data.id
            }
        })
    }

    static updateUser(key, user) {
        return axios.post(config.apiUrl + 'updateUser?uid=' + key, {
            name: user.name,
            role: user.role
        }).then(response => {
            return {
                ...response.data,
                key: response.data.id
            }
        })
    }

    static async remove(key) {
        return await axios.get(config.apiUrl + 'removeUser?uid=' + key)
    }

    getBranches() {

    }

    users() {
        const branches = {}
        const organizations = {}
        return AuthService.employee().then(employee => {

        
        return firebase.database().ref(`employees`).orderByChild('branch').equalTo(employee.branch).once('value').then(snapshot => {
            const users = snapshot.val()

            if (users) {
                return Object.keys(users).map(key => {
                    const user = users[key]
                    branches[user.branch] = true
                    organizations[user.organization] = true
                    return {
                        ...user,
                        key: key
                    }
                })
            }

            return {}
        }).then(users => {
            const promises = Object.keys(branches).map(branchKey => {
                return firebase.database().ref(`branches/${branchKey}`).once('value').then(snapshot => {
                    return {
                        ...snapshot.val(),
                        key: branchKey
                    }
                })
            })

            return Promise.all(promises).then(branches => {
                const toReturn = {}
                branches.forEach(branch => {
                    toReturn[branch.key] = branch
                })

                return toReturn
            }).then(branches => {
                return users.map(user => {
                    user['branch'] = branches[user.branch]

                    return user
                })
            })
        }).then(users => {
            const promises = Object.keys(organizations).map(organizationKey => {
                return firebase.database().ref(`organizations/${organizationKey}`).once('value').then(snapshot => {
                    return {
                        ...snapshot.val(),
                        key: organizationKey
                    }
                })
            })

            return Promise.all(promises).then(organizations => {
                const toReturn = {}
                organizations.forEach(organization => {
                    toReturn[organization.key] = organization
                })

                return toReturn
            }).then(organizations => {
                return users.map(user => {
                    user['organization'] = organizations[user.organization]

                    return user
                })
            })
        })
        }).then(users => {
            return users.sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                } else {
                    return -1
                }
            })
        })
    }
}

export default UserService