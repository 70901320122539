import React from 'react'
import { Card, Form } from 'react-bootstrap';
import { AreaChart, linearGradient, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts';
import { Formik } from 'formik';
import moment from 'moment'
import { StatsService } from '../../services/stats.service';

function getPreviousMonthDay(date) {
  const dateArray = date.split('-')

  let month = parseInt(dateArray[1])
  let year = parseInt(dateArray[0])
  if (month == 1) {
    month = 12
    year -= 1
  } else {
    month -= 1
  }

  return `${year}-${month > 9 ? month : '0'+month}-${dateArray[2]}`
}

function DrawChart(props) {
  let data = props.data
  const oldData = props.oldData
  const types = {
    week: 'Week',
    month: 'Month',
    year: 'Year'
  }

  console.log(props.durationType)

  if (props.durationType === 'week' || props.durationType === 'year') {
    for (let i = 0; i < data.length; i++) {
      if (oldData[i]) {
        data[i][`Previous ${types[props.durationType]}`] = oldData[i].Revenue
      } else {
        data[i][`Previous ${types[props.durationType]}`] = 0
      }
    }
  } else if (props.durationType === 'month') {
    const days = {}

    oldData.forEach(item => {
      days[item.name] = item
    })

    data = data.map(item => {
      const date = getPreviousMonthDay(item.name)
      console.log(date)

      if (days[date]) {
        item[`Previous ${types[props.durationType]}`] = days[date].Revenue
      } else {
        item[`Previous ${types[props.durationType]}`] = 0
      }

      return item
    })
  }
  console.log(data)

    return (
        <ResponsiveContainer width='99%' height={300} data={data}>
          <AreaChart height={300} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }} >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.5}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0.5}/>
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.5}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area unit=' ZAR' dot={{ stroke: '#82ca9d', strokeWidth: 2 }} type="linear" dataKey={`Previous ${types[props.durationType]}`} stroke="#82ca9d" fillOpacity={0.6} fill="url(#colorPv)" />
            <Area unit=' ZAR' dot={{ stroke: '#8884d8', strokeWidth: 2 }} type="linear" dataKey="Revenue" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
            </AreaChart>
        </ResponsiveContainer>
    )
}

export class CompareRevenue extends React.Component {
    state = {
      data: [],
      durationType: 'week'
    }

    componentDidMount() {
      this.onSubmit(this.state.durationType)
    }

    onSubmit(value) {
      console.log(value)
      let startDate, endDate, promise, prevStartDate, prevEndDate

      endDate = moment(new Date())
      switch (value) {
        case 'week':
          startDate = moment(endDate).subtract(6, 'day').format('YYYY-MM-DD')
          prevStartDate = moment(startDate).subtract(1, 'week').format('YYYY-MM-DD')
          prevEndDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD')).then(current => {
            return StatsService.dailyStats(prevStartDate, prevEndDate).then(previous => {
              console.log(previous)
              return {
                current: current,
                previous: previous
              }
            })
          })
          break;
        case 'month':
          startDate = moment(endDate).subtract(1, 'month').format('YYYY-MM-DD')
          prevStartDate = moment(startDate).subtract(1, 'month').format('YYYY-MM-DD')
          prevEndDate = moment(startDate).add(4, 'days').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD')).then(current => {
            return StatsService.dailyStats(prevStartDate, prevEndDate).then(previous => {
              console.log(previous)
              return {
                current: current,
                previous: previous
              }
            })
          })
          break
        case 'year':
          startDate = moment(endDate).subtract(1, 'year').format('YYYY-MM-DD')
          prevStartDate = moment(startDate).subtract(1, 'year').format('YYYY-MM-DD')
          prevEndDate = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD')
          promise = StatsService.monthlyStats(startDate, endDate.format('YYYY-MM-DD')).then(current => {
            return StatsService.dailyStats(prevStartDate, prevEndDate).then(previous => {
              console.log(previous)
              return {
                current: current,
                previous: previous
              }
            })
          })
          break
        default:
          break;
      }
      
      
      promise.then(data => {
        let currentData = data.current.map(item => {
          let name
          if (value === 'week') {
            name = item.day
          } else if (value === 'year') {
            name = item.month
          } else {
            name = item.day
          }
          return {
            name: name,
            Revenue: (item.revenue / 100)
          }
        })

        let oldData = data.previous.map(item => {
          let name
          if (value === 'week') {
            name = item.day
          } else if (value === 'year') {
            name = item.month
          } else {
            name = item.day
          }
          return {
            name: name,
            Revenue: (item.revenue / 100)
          }
        })

        if (value === 'year') {
          currentData = currentData.map(item => {
              item.name = moment(new Date(item.name + '-01')).format('YYYY MMM')

              return item
            })
        }

        if (value === 'year') {
          oldData = oldData.map(item => {
            item.name = moment(new Date(item.name + '-01')).format('YYYY MMM')

            return item
          })
      }

        this.setState({
          data: currentData,
          oldData: oldData,
          durationType: value
        })
        console.log(data)
      })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <div style={{display: 'flex'}}>
                      <Card.Title style={{flex: 1}}>Revenue (R)</Card.Title>
                      <Formik
                        initialValues={{
                          durationType: this.state.durationType
                        }}
                        validateOnChange={(value) => {
                          console.log(value)
                        }}
                      >
                        {({
                          values,
                          handleChange
                        }) => {
                          return (
                            <Form>
                              <Form.Control value={values.durationType} id='durationType' as="select" onChange={(e) => {
                                handleChange(e)
                                this.onSubmit(e.target.value)
                              }}>
                                <option value='week'>Week</option>
                                <option value='month'>Month</option>
                                <option value='year'>Year</option>
                              </Form.Control>
                            </Form>
                          )
                        }}
                      </Formik>
                      
                    </div>
                    <DrawChart durationType={this.state.durationType} oldData={this.state.oldData} data={this.state.data} />
                </Card.Body>
            </Card>
        )
    }
}