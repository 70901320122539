import React from 'react'
import { Formik } from 'formik';
import { Form, Button, Modal } from 'react-bootstrap';
import { ExpensesService } from '../../services/expenses-service'
import { ClipLoader } from 'react-spinners'

export class AddExpenseModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            expense: this.props.expense ? {
                ...this.props.expense,
                cost: this.props.expense.cost / 100
            } : {
                name: '',
                description: '',
                cost: 0,
                category: 'generic'
            },
            saving: false,
            types: []
        }
        console.log(this.props)

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        ExpensesService.expenseTypes().then(types => {
            this.setState({
                types: types
            })
        })
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
        }
    }

    componentDidUpdate() {
        
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        values['cost'] = Math.round(values['cost'] * 100)

        this.setState({
            saving: true
        })
        if (this.props.expense) {
            ExpensesService.edit(this.props.expense.id, values).then(expense => {
                if (this.props.onExpenseEdited) {
                    this.props.onExpenseEdited(expense)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        } else {
            ExpensesService.add(values).then(expense => {
                if (this.props.onExpenseAdded) {
                    this.props.onExpenseAdded(expense)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        }
        
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.expense ? {
                    ...this.props.expense,
                    cost: this.props.expense.cost / 100
                } : {
                    name: '',
                    description: '',
                    cost: 0,
                    date: ''
                }}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.props.expense ? 'Edit' : 'Add'} Expense</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='name'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={values.name} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter expense name' />
                                    </Form.Group>
                                    <Form.Group controlId='description'>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control value={values.description} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter expense description (optional)' />
                                    </Form.Group>
                                    <Form.Group controlId='type'>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control value={values.type} onBlur={handleBlur} onChange={handleChange} as='select'>
                                            <option value='generic'>Generic</option>
                                            {this.state.types.map(type => {
                                                return (
                                                    <option key={type.id} value={type.id}>{type.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='date'>
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control onBlur={handleBlur} value={values.date} onChange={handleChange} type="date" />
                                    </Form.Group>
                                    <Form.Group controlId='cost'>
                                        <Form.Label>Cost</Form.Label>
                                        <Form.Control value={values.cost} onBlur={handleBlur} onChange={handleChange} type='number' min='0' step='0.01' placeholder='Enter expense cost' />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm} disabled={this.state.saving}>
                                        <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}