import React, { Component } from 'react'
import MerchantService from '../services/merchant-service';
import { Form, Row, Col, ProgressBar, Image, Button, Alert } from 'react-bootstrap';
import { Formik } from 'formik';

class EditMerchant extends Component {
    merchantService = new MerchantService()
    fileRef = React.createRef()
    
    constructor() {
        super()
        
        this.state = {
            merchant: null,
            saving: false,
            isNewMerchant: true,
            image: '/assets/imgs/Logo.jpg',
            saved: false,
            savingError: false,
            merchantId: null
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({
                merchantId: this.props.match.params.id,
                isNewMerchant: false
            })
            console.log('Getting merchant')
            this.merchantService.merchant(this.props.match.params.id).then(merchant => {
                console.log(merchant)
                this.setState({
                    merchant: merchant
                })
            }).catch(err => {
                console.log(err)
            })
        } else {
            console.log(':/')
        }
    }

    submit(merchant) {
        this.setState({
            saving: true,
            saved: false,
            savingError: false
        })
        var promise
        if (this.state.merchantId) {
            promise = this.merchantService.edit(this.state.merchantId, merchant)
        } else {
            console.log(merchant)
            promise = this.merchantService.add(merchant).then(data => {
                this.setState({
                    merchantId: data.key,
                    isNewMerchant: false,
                    merchant: data
                })

                return data
            })
        }

        promise.then(() => {
            this.setState({
                saving: false,
                saved: true
            })
        }).catch(() => {
            this.setState({
                saving: false,
                savingError: true
            })
        })

        
    }

    renderForm() {
        const merchant = this.state.merchant
        return (
            <Formik
                onSubmit={(values) => {
                    this.submit(values)
                }}
                initialValues={this.state.isNewMerchant ? {
                    name: '',
                    entity: 'order',
                    input: 'Order Number',
                    inputInstruction: 'Enter order number below',
                    inputType: 'number',
                    type: 'Restaurant',
                    phoneNumberInstruction: "Got somewhere to go? Enter your phone number below and we'll let you know when your order is ready"
                } : merchant}>{({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit
                }) => (
                    <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="name">
                                <Form.Label>Merchant Name</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.name} type="text" placeholder="Merchant Name" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="entity">
                                <Form.Label>Entity</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.entity} type="text" placeholder="Entity" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="input">
                                <Form.Label>Input Text</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.input} type="text" placeholder="Input Text" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="inputInstruction">
                                <Form.Label>Input Instructions</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.inputInstruction} type="text" placeholder="Input Instructions" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="inputType">
                                <Form.Label>Input Type</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.inputType} as="select">
                                    <option disabled value=''>Select Input Type</option>
                                    <option value="text">Text</option>
                                    <option value="number">Number</option>
                                    <option value="tell">Phone Number</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="type">
                                <Form.Label>Merchant Type</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.type} as="select">
                                    <option disabled value=''>Select Merchant Type</option>
                                    <option value="Restaurant">Restaurant</option>
                                    <option value="Car Wash">Car Wash</option>
                                    <option value="Laundromat">Laundromat</option>
                                    <option value="Service">Car Service</option>
                                    <option value="Custom">Custom</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="phoneNumberInstruction">
                                <Form.Label>Enter Phone Number Message</Form.Label>
                                <Form.Control onBlur={handleBlur} onChange={handleChange} value={values.phoneNumberInstruction} type="text" placeholder="Enter Phone Number Message" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group style={{display: 'none'}} controlId="logo">
                                <Form.Label>Logo</Form.Label>
                                <Form.Control ref={this.fileRef} onBlur={handleBlur} onChange={(event) => {
                                    
                                    var myReader = new FileReader();
                                    myReader.onloadend = (e) => {
                                        this.setState({
                                            image: e.target['result']
                                        })
                                    }
                                
                                    myReader.readAsDataURL(this.fileRef.current.files[0]);
                                    
                                    return handleChange
                                }} type="file" placeholder="Logo" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Image onClick={() => { console.log(this.fileRef.current.click()) }} width="250px" src={values.logo ? values.logo : this.state.image} thumbnail />
                    {this.state.saving === true ? <ProgressBar animated now={100}></ProgressBar> : null}
                    <Row>
                        <Button style={{margin: '10px'}} type="submit">Save Merchant</Button>
                    </Row>
                </Form>
                )}
                </Formik>
        )
    }

    previewFile(files) {
        if (files.length > 0)
        {
        //   var file = files 
          var myReader = new FileReader();
    
          myReader.onloadend = (e) => {
            // this.image = e.target['result']
          }
    
          myReader.readAsDataURL(files[0]);
        }
      }
    

    render() {
        return (
            <div>
                <h3>{this.state.isNewMerchant ? 'Add' : 'Edit'} Merchant</h3>
                {this.state.saved ? <Alert variant="success" dismissible>Merchant successfully saved</Alert> : null}
                {this.state.savingError ? <Alert variant="danger" dismissible>An error has occurred while saving merchant</Alert> : null}
                {/* {this.renderForm()} */}
                {this.state.merchant || this.state.isNewMerchant ? this.renderForm() : <ProgressBar animated now={100}></ProgressBar>}
                
            </div>
        )
    }
}

export default EditMerchant;