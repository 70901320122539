import React from 'react'
import { Card, Table, Button, Pagination, Form, Modal } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { CustomersService } from '../../services/customers-service';
import moment from 'moment';
import { ViewOrderModal } from './view-order';
import { BranchService } from '../../services/branch-service'
import { AuthService } from '../../services/auth.service';

export class Customers extends React.Component {
    PER_PAGE = 200
    lastVisible
    firstVisible
    state = {
        customers: [],
        points: {},
        orderBy: 'name',
        page: 1,
        pages: 1,
        loading: true,
        phoneOnly: false,
        direction: 'asc',
        startAfter: null,
        showOrders: false,
        currentCustomer: null,
        branch: null,
        showDelete: false
    }

    constructor(props) {
        super(props)

        this.next = this.next.bind(this)
        this.prev = this.prev.bind(this)
        this.onHide = this.onHide.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        this.setState({
            loading: true,
            customers: [],
            points: {}
        })
        console.log(this.state.phoneOnly)
        BranchService.businessInfo().then(business => {
            return CustomersService.customers({
                phoneOnly: this.state.phoneOnly === false ? null : true,
                orderBy: this.state.orderBy,
                direction: this.state.direction,
                startAfter: this.state.startAfter,
                endBefore: this.state.endBefore,
                limit: this.PER_PAGE
            }).then(data => {
                console.log(data)
                this.lastVisible = data.lastVisible
                return this.setState({
                    customers: data.customers,
                    points: data.points,
                    pages: data.customers.length / this.PER_PAGE,
                    businessType: business.businessType
                })
            }).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })
        
    }

    orderBy(type) {
        var direction = 'asc'
        console.log(this.state.orderBy, type, this.state.direction)
        if (type === this.state.orderBy) {
            if (this.state.direction === 'asc') {
                direction = 'desc'
            } else {
                direction = 'asc'
            }
        }

        this.setState({
            orderBy: type,
            direction: direction,
            startAfter: null
        }, () => {
            this.refresh()
        })
    }

    handleClose() {
        this.setState({
            showDelete: false,
            selectedCustomer: null
        })
    }

    onHide() {
        this.setState({
            showOrders: false
        })
    }

    viewOrders(customer) {
        this.setState({
            showOrders: true,
            currentCustomer: customer
        })
    }

    next() {
        this.setState({
            startAfter: this.lastVisible,
            endBefore: null,
            page: this.state.page + 1
        }, () => {
            this.refresh()
        })
    }

    prev() {
        if (this.state.page > 1) {
            this.setState({
                endBefore: this.firstVisible,
                startAfter: null,
                page: this.state.page - 1
            }, () => {
                this.refresh()
            })
        }
    }

    deleteCustomer(customer) {
        this.setState({
            selectedCustomer: customer,
            showDelete: true
        })
    }

    fixCustomer(customer, index) {
        // console.log(CustomersService.fixCustomer(customer))
        this.state.customers[index] = CustomersService.fixCustomer(customer)
        console.log(this.state.customers[index])
        this.setState({
            customers: this.state.customers
        })
    }

    confirmDelete() {
        CustomersService.deleteCustomer(this.state.selectedCustomer)
    }

    render() {
        const items = []
        for (var i = 0; i < this.state.pages; i++) {
            items.push(i + 1)
        }

        return (
            <Card>
                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete the customer '{this.state.selectedCustomer && this.state.selectedCustomer.name}'?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Nevermind
                            </Button>
                            <Button variant="primary" onClick={() => this.confirmDelete()}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Customers</h3></div>
                <Form.Check onChange={(data) => {
                    console.log(data.target.checked)
                    this.setState({
                        phoneOnly: data.target.checked,
                        startAfter: null
                    }, () => {
                        this.refresh()
                    })
                    
                }} type="checkbox" label="Display only with contact numbers" />
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th><Button variant="light">#</Button></th>
                            <th><Button variant="light">Name</Button></th>
                            <th><Button variant="light">Cell Phone</Button></th>
                            <th><Button variant="light" onClick={() => this.orderBy('ordersCount')}>Orders {/*this.state.orderBy === 'orders' && '*'*/}</Button></th>
                            {this.state.businessType == 'Car Wash' && <th><Button variant="light">Vehicles</Button></th>}
                            <th><Button variant="light">Points</Button></th>
                            <th><Button variant="light" onClick={() => this.orderBy('lastOrder')}>Last Order {this.state.orderBy === 'last_order' && '*'}</Button></th>
                            {/* <th><Button variant="light">Actions</Button></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.customers.map((customer, index) => {
                                return (
                                    <tr key={customer.id}>
                                        <td>{(index + 1) + ((this.state.page - 1) * this.PER_PAGE)}</td>
                                        <td>{customer.name ? customer.name : '-'}</td>
                                        <td>{customer.hasPhone ? customer.phone : '-'}</td>
                                        <td><Button onClick={() => this.viewOrders(customer)} variant="link">Orders{/*customer.ordersCount} order{customer.ordersCount > 1 ? 's' : ''*/}</Button></td>
                                        {this.state.businessType == 'Car Wash' && <td>{customer.vehicles.join(', ')}</td>}
                                        <td>{this.state.points[customer.id] ? this.state.points[customer.id].points : 'Not Set'}</td>
                                        <td>{customer.lastOrder ? moment(new Date(customer.lastOrder)).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                                        {/* <td><Link to={`/merchant/${employee.organization.key}/branch/${employee.branch.key}/edit`}>{employee.branch.name}</Link></td> */}
                                        {/* <td>
                                        <Button onClick={() => {
                                                this.fixCustomer(customer, index)
                                            }} style={{marginRight: '5px'}} variant="outline-primary" size="sm">Fix</Button>
                                            <Button onClick={() => {
                                                this.deleteCustomer(customer)
                                            }} style={{marginRight: '5px'}} variant="outline-danger" size="sm">Delete</Button>
                                        </td> */}
                                    </tr>
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>
                {!this.state.loading && this.state.customers.length === 0 && <div style={{textAlign: 'center'}}>You don't have any customers yet</div>}

                <Button onClick={this.prev} style={{marginRight: 10}}>Prev</Button>
                <Button onClick={this.next}>Next</Button>
                {/* <Pagination size='sm'>{items.map(item => {
                    return (<Pagination.Item active={this.state.page === item} onClick={() => {
                        this.setState({
                            page: item
                        })
                    }} key={item}>{item}</Pagination.Item>)
                })}</Pagination> */}
                </Card.Body>

                {this.state.showOrders && <ViewOrderModal customer={this.state.currentCustomer} onHide={this.onHide} />}
            </Card>
        )
    }
}