import React, { Component } from 'react'
import { Table, Form, Card, Row, Col, Button, Modal, Alert } from 'react-bootstrap';
import moment from 'moment';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { RatingsService } from '../../services/rating-service';
import { StarRating } from '../../components/star-rating';
import { ReplyModal } from './reply-modal';
import { OrdersService } from '../../services/orders-service';

export class Ratings extends Component {
    state = {
        selectedOrder: null,
        showViewOrder: false,
        loadingOrder: true,
        avarageRating: 0,
        commonRatings: 0,
        categories: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        }
    }

    total = 0
    selectedValue;
    selectedRating;

    constructor(props) {
        super(props)

        this.state = {
            ratings: null,
            showOrders: false,
            rating: null
        }

        this.selectedValue = "All"
        this.selectOnChange = this.selectOnChange.bind(this)
        this.onHide = this.onHide.bind(this)

        const today = moment(new Date()).format('YYYY-MM-DD')
        const startOfMonth = moment(new Date()).startOf('month').format('YYYY-MM-DD')
        RatingsService.ratings(startOfMonth, today).then(data => {
            this.formatRatings(data)
            this.setState({
                ratings: data.length == 0 ? [] : data.reverse()
            })
        })

        this.handleClose = this.handleClose.bind(this)
    }

    viewOrder(rating) {
        this.setState({
            showViewOrder: true,
            loadingOrder: true
        }, () => {
            OrdersService.order(rating.orderDate + '-' + rating.orderId).then(order => {
                console.log(order)
                this.setState({
                    loadingOrder: false,
                    selectedOrder: order
                })
            })
        })
        
    }

    handleClose() {
        this.setState({
            showViewOrder: false,
            selectedOrder: null
        })
    }

    formatRatings(ratings) {
        const categories = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        }
        console.log(ratings)
        let ratingsCount = 0
        let ratingsTotal = 0
        let commonRatings = 0
        ratings.forEach(rating => {
            categories[rating.rating] += 1
            ratingsCount += 1
            ratingsTotal += rating.rating
        })

        Object.keys(categories).forEach(category => {
            if (categories[category] > commonRatings) {
                commonRatings = categories[category]
            }
        })

        this.setState({
            avarageRating: ratingsCount > 0 ? ratingsTotal / ratingsCount : 0,
            categories: categories,
            commonRatings: commonRatings
        })
        
    }

    filter(data) {
        // console.log(date.target.value)
        this.setState({
            ratings: null
        }, () => {
            RatingsService.ratings(data.start, data.end).then(data => {
                let filteredData = []
                if (this.selectedValue != "All") {
                    data.forEach(rating => {
                        if (rating.rating == this.selectedValue) {
                            filteredData.push(rating)
                        }
                    })
                    data = filteredData
                }
                this.setState({
                    ratings: data.reverse()
                })
            })
        })
    }

    reply(rating) {

        this.selectedRating = rating;
        console.log(rating.contact)
        if (rating.contact !== "" && rating.contact) {
            this.setState({
                showOrders: true,
                customer: rating
            })
        } else {
            console.log("customer has no number phones ")
            alert("customer has no contact numbers");
        }

    }

    selectOnChange(event) {
        this.selectedValue = event.target.value;
    }

    onHide() {
        this.setState({
            showOrders: false
        })
    }

    render() {
        this.total = 0;
        return (
            <div>
                <Modal style={{zIndex: 100000}} show={this.state.showViewOrder} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Order {this.state.selectedOrder && this.state.selectedOrder.id.split('-')[3] }</Modal.Title>
                        </Modal.Header>
                        {this.state.loadingOrder && <Modal.Body><div style={{alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex'}}><ClipLoader /></div></Modal.Body>}
                        {this.state.selectedOrder && <Modal.Body>
                            <h5>Customer</h5>
                            <p>Name: {this.state.selectedOrder.name}<br />
                            Phone: {this.state.selectedOrder.phone}
                            </p>

                            <h5>Order Info</h5>
                            <p>
                                Order Number: {this.state.selectedOrder.id.split('-')[3]}<br />
                                Date: {moment(this.state.selectedOrder.createdAt).format('YYYY-MM-DD HH:mm:ss')}<br />
                                Time Ended: {this.state.selectedOrder.finishedAt && moment(this.state.selectedOrder.finishedAt).format('YYYY-MM-DD HH:mm:ss')}<br />
                                Special Instructions: {this.state.selectedOrder.instructions}<br />
                                Description: {this.state.selectedOrder.description}<br />
                                Completed: {this.state.selectedOrder.completed ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}<br />
                                Paid: {this.state.selectedOrder.paid ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}<br />
                                Payment Method: {this.state.selectedOrder.paymentType}<br />
                            </p>

                            <h5>Products</h5>
                            <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Discount</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.selectedOrder.products && this.state.selectedOrder.products.map((product, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td style={{maxWidth: 200}}>{product.name + ' ' + product.customDescription}</td>
                                    <td>{product.qty}</td>
                                    <td>R{product.discount.toFixed(2)}</td>
                                    <td>R{(((product.price * product.qty) / 100) - product.discount).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>R{(this.state.selectedOrder.price / 100).toFixed(2)}</td>
                        </tr>
                    </tbody>
                                
                            </Table>
                        </Modal.Body>}
                    </Modal>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h3 style={{ flexGrow: 1 }}>Ratings &amp; Reviews</h3>
                    <Formik
                        initialValues={{
                            start: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
                            end: moment(new Date()).format('YYYY-MM-DD')
                        }}
                        onSubmit={(values) => this.filter(values)}
                    >
                        {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            values
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="start">
                                                <Form.Label column sm="3" style={{ textAlign: 'right' }}>From</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="end">
                                                <Form.Label column sm="3" style={{ textAlign: 'right' }}>To</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="rating">
                                                <Form.Label column sm="3" style={{ textAlign: 'right' }}>Ratings</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control as="select" onChange={this.selectOnChange} value={this.state.selectedValue} >
                                                        <option>All</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="2">
                                            <Button type='submit'>Filter</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>

                {this.state.ratings && <Row style={{marginBottom: 10}}>
                <Col xs={12} lg={3} md={3}>
                    <Card>
                        <Card.Body style={{display: 'flex'}}>
                            {this.state.categories && <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}><span style={{fontSize: '1em', marginRight: 5, marginBottom: 5}}>AVG</span><span style={{fontSize: '2.5em'}}>{this.state.avarageRating ? this.state.avarageRating.toFixed(1) : '-'}/5.0</span></div>
                                {Object.keys(this.state.categories).reverse().map((category, index) => {
                                    return <div key={'category-' + index} style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
                                        <div style={{minWidth: 50, fontSize: '0.8em', color: this.state.categories[category] == 0 ? '#dadbdd' : '#4d77e5'}}>{category} Star{category == 1 ? '' : 's'}</div>
                                        <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                            <div style={{background: '#efeff0', height: 10, borderRadius: 10, marginLeft: 10, width: '100%'}}>
                                                <div style={{background: '#feb24b', borderRadius: 10, width: `${this.state.categories[category] / this.state.commonRatings * 100}%`, height: '100%'}}></div>
                                            </div>
                                            <span style={{fontSize: '0.6em', textAlign: 'right', marginLeft: 5, minWidth: 20, color: this.state.categories[category] == 0 ? '#dadbdd' : '#4d77e5'}}>({this.state.categories[category]})</span>
                                        </div>
                                        </div>
                                })}
                            </div>}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>}

                <Card>
                    <Card.Body>
                        <hr />
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Order</th>
                                    <th>Rating</th>
                                    <th>Comment</th>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ratings && this.state.ratings.map((rating, index) => {
                                    const date = moment(new Date(rating.createdAt)).format('YYYY-MM-DD HH:mm:ss')
                                    return (
                                        <tr key={rating.id}>
                                            <td>{index + 1}</td>
                                            <td><Button variant='link' onClick={() => this.viewOrder(rating)}>{rating.orderId}</Button></td>
                                            <td style={{minWidth: 150}}><StarRating rating={rating.rating} /></td>
                                            <td style={{maxWidth: 250}}>{rating.comment ? rating.comment : rating.comments}</td>
                                            <td>{rating.name}</td>
                                            <td>{rating.contact}</td>
                                            <td>{moment(new Date(rating.createdAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td><Button onClick={() => this.reply(rating)}>Reply</Button></td>
                                        </tr>
                                    )
                                })}

                                {this.state.showOrders && <ReplyModal rating={this.selectedRating} onHide={this.onHide} />}
                            </tbody>
                        </Table>
                        {this.state.ratings && this.state.ratings.length === 0 && <div style={{ textAlign: 'center' }}>No ratings to display</div>}
                        {this.state.ratings == null && <div style={{ justifyContent: 'center', display: 'flex' }}><ClipLoader /></div>}
                    </Card.Body>
                </Card>
            </div>
        )
    }
}