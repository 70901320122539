import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import Dropzone from 'react-dropzone'

export class BranchPhoto extends React.Component {
    state = {
        logo: null,
        validLogo: true
    }

    componentDidMount() {
        console.log(this.props.values)
        if (this.props.values.photoFile) {
            console.log(this.props.values)
            this.setState({
                logo: this.props.values.photoFile,
                validLogo: true
            })
            var reader  = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({
                    logoSrc: reader.result
                })
            }, false);
            reader.readAsDataURL(this.props.values.photoFile);
        }
    }

    render() {
        return (
            <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <p style={{textAlign: 'center'}}>Help your customers identify your brand by adding your company's logo</p>
                {!this.props.fileUploaded && <p style={{color: 'red', fontSize: '0.8em'}}>Please upload your company logo</p>}
                <Dropzone onDrop={acceptedFiles => {
                    console.log(acceptedFiles)
                    this.setState({
                        logo: acceptedFiles,
                        validLogo: true
                    })
                    if (this.props.photoSelected) {
                        this.props.photoSelected(acceptedFiles[0])
                    }

                    var reader  = new FileReader();
                    reader.addEventListener("load", () => {
                        this.setState({
                            logoSrc: reader.result
                        })
                      }, false);
                    reader.readAsDataURL(acceptedFiles[0]);
                }}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                        <div style={{
                            padding: 20,
                            borderColor: 'grey',
                            borderWidth: 2,
                            borderRadius: 10,
                            borderStyle: 'solid',
                            height: 150,
                            width: 150,
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: this.state.logoSrc == null ? null : `url('${this.state.logoSrc}')`,
                        }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.state.logo == null && <p style={{textAlign: 'center'}}>Drag 'n' drop your logo here, or click to select files</p>}
                        </div>
                        </section>
                    )}
                </Dropzone>
            </div>
        )
    }
}