import React, { Component } from 'react'
import { Button, Table, Modal, Card } from 'react-bootstrap';
import UserService from '../services/user-service';
import { AddUserModal } from './users/add-user-modal'
import { ClipLoader } from 'react-spinners';

export class MerchantUsers extends Component {
    usersService = new UserService()

    constructor() {
        super()
        this.state = {
            users: [],
            loading: true,
            showDelete: false,
            showAddUser: false,
            selectedUser: null
        }

        this.addUser = this.addUser.bind(this)
        this.onHide = this.onHide.bind(this)
        this.userAdded = this.userAdded.bind(this)
        this.userEdited = this.userEdited.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        this.usersService.users().then(users => {
            this.setState({
                users: users,
                loading: false
            })
        })
    }

    addUser() {
        this.setState({
            showAddUser: true
        })
    }

    onHide() {
        this.setState({
            showAddUser: false,
            selectedUser: null
        })
    }

    userAdded(user) {
        this.state.users.push(user)
        const users = this.state.users.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        })
        this.setState({
            users: users
        })
    }

    editUser(user) {
        this.setState({
            showAddUser: true,
            selectedUser: user
        })
    }

    deleteUser() {
        for (let i = 0; i < this.state.users.length; i++) {
            if (this.state.selectedUser.key === this.state.users[i].key) {
                this.state.users.splice(i, 1)
                this.setState({
                    users: this.state.users
                })
                break
            }
        }

        UserService.remove(this.state.selectedUser.key)
        this.handleClose()
    }

    userEdited(user) {
        for (let i = 0; i < this.state.users.length; i++) {
            if (user.key === this.state.users[i].key) {
                const newUsers = this.state.users
                newUsers[i] = user
                this.setState({
                    users: newUsers
                })
                break
            }
        }
    }

    handleClose() {
        this.setState({ showDelete: false, selectedUser: null });
    }

    handleDelete(user) {
        this.setState({ showDelete: true, selectedUser: user });
    }

    resetPassword(user) {

    }

    render() {
        return (
            <Card>
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Users</h3><Button variant="outline-dark" size="sm" style={{marginLeft: '10px'}} onClick={this.addUser}>Add User</Button></div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Username / Email</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.users.map((user, index) => {
                                return (
                                    <tr key={user.key}>
                                        <td>{index + 1}</td>
                                        <td>{user.name}</td>
                                        <td>{user.username}</td>
                                        <td style={{textTransform: 'capitalize'}}>{user.role}</td>
                                        {/* <td><Link to={`/merchant/${user.organization.key}/branch/${user.branch.key}/edit`}>{user.branch.name}</Link></td> */}
                                        <td>
                                            <Button onClick={() => this.editUser(user)} style={{marginRight: '5px'}} variant="outline-primary" size="sm">Edit</Button>
                                            <Button style={{marginRight: '5px'}} variant="outline-secondary" size="sm" onClick={() => {
                                                this.resetPassword()
                                            }}>Reset Password</Button>
                                            <Button variant="outline-danger" size="sm" onClick={() => {this.handleDelete(user)}}>Remove</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the user '{this.state.selectedUser && this.state.selectedUser.name}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteUser()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddUserModal user={this.state.selectedUser} onUserEdited={this.userEdited} onUserAdded={this.userAdded} show={this.state.showAddUser} onHide={this.onHide} />
                </Card.Body>
            </Card>
        )
    }
}
