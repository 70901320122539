import firebase from 'firebase'
import { AuthService } from './auth.service';
import moment from 'moment'

export class StatsService {
    static async monthStats() {
        const user = AuthService.user()
        console.log(user.uid)
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        const month = moment(new Date()).format('YYYY-MM')

        console.log(employee)
        console.log(month)
        const stats = (await firebase.database().ref(`dashboard/counts/${employee.branch}/months/${month}`).once('value')).val()

        if (stats == null) {
            return []
        }

        return stats
    }

    static async dailyStats(start, end) {
        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        
        const stats = (await firebase.database().ref(`dashboard/counts/${employee.branch}/days`).orderByKey().startAt(start).endAt(end).once('value')).val()
        if (stats) {
            let startingDate = moment(new Date(start))
            let currentDate = start
            do {
                if (stats[currentDate] == null) {
                    stats[currentDate] = {
                        day: currentDate,
                        orders: 0, revenue: 0,
                        times: {
                            total: 0,
                            validTimes: 0
                        }
                    }
                }

                startingDate = startingDate.add('day', 1)
                currentDate = startingDate.format('YYYY-MM-DD')
            } while (currentDate !== end && currentDate < end)
        }

        return stats ? Object.keys(stats).map(key => stats[key]).sort((a, b) => {
            if (a.day < b.day) {
                return -1
            } else {
                return 1
            }
        }) : []
    }

    static async monthlyStats(start, end) {
        let count = 0
        start = moment(new Date(start)).format('YYYY-MM-DD')
        end = moment(new Date(end)).format('YYYY-MM')
        const user = AuthService.user()
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()
        
        const stats = (await firebase.database().ref(`dashboard/counts/${employee.branch}/months`).orderByKey().startAt(start).endAt(end).once('value')).val()

        if (stats) {
            let startingDate = moment(new Date(start))
            let currentDate = moment(new Date(start)).format('YYYY-MM')
            do {
                if (stats[currentDate] == null) {
                    stats[currentDate] = {
                        month: currentDate,
                        orders: 0, revenue: 0,
                        times: {
                            total: 0,
                            validTimes: 0
                        }
                    }
                }

                startingDate = startingDate.add('month', 1)
                currentDate = startingDate.format('YYYY-MM')
                console.log(currentDate)
                if (++count > 500) {
                    break
                }
            } while (currentDate !== end)
        }

        return stats ? Object.keys(stats).map(key => stats[key]).sort((a, b) => {
            if (a.month < b.month) {
                return -1
            } else {
                return 1
            }
        }) : []
    }

    static timeFromSeconds(seconds) {
        let isNegative = false
        if (seconds < 0) {
            isNegative = true
            seconds *= -1
        }

        seconds = Math.floor(seconds / 1000)
        const hours = Math.floor(seconds / (60 * 60))
        seconds = seconds - (hours * (60 * 60))
        const min = Math.floor(seconds / 60)
        seconds = seconds - (min * 60)
        const sec = seconds

        return (isNegative ? '-' : '') + `${StatsService.leadingZero(hours)}:${StatsService.leadingZero(min)}:${StatsService.leadingZero(sec)}`
    }

    static leadingZero(number) {
        if (number > 9) {
            return `${number}`
        } else {
            return `0${number}`
        }
    }
}