import { AuthService } from "./auth.service";
import firebase from 'firebase'

export class ProductsService {
    static async products() {
        const employee = await AuthService.employee()
        const snapshots = await firebase.firestore().collection('products')
        .where('branch', '==', employee.branch)
        .where('active', '==', true)
        .orderBy('name').get()

        return snapshots.docs.map(doc => {
            let visibility
            const product = doc.data()

            if (product.showInstore && product.showOnline) {
                visibility = 'instore-online'
            } else if (product.showInstore) {
                visibility = 'instore'
            } else if (product.showOnline) {
                visibility = 'online'
            } else {
                visibility = 'hidden'
            }

            return {
                ...doc.data(),
                id: doc.id,
                visibility: visibility
            }
        })
    }

    static async add(product) {
        const employee = await AuthService.employee()

        product = {
            ...product,
            branch: employee.branch,
            organization: employee.organization,
            global: true,
            active: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        }

        const ref = await firebase.firestore().collection('products').add(product)

        return {
            ...product,
            id: ref.id
        }
    }

    static async edit(id, product) {
        await firebase.firestore().collection('products').doc(id).update(product)
        
        return {
            ...product,
            id: id,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        }
    }

    static async addStock(product, count) {
        const employee = await AuthService.employee()

        const currentStock = (await firebase.database().ref(`stock/${employee.branch}/products/${product.id}`).once('value')).val()
        await firebase.database().ref(`stock/${employee.branch}/updates`).push({
            employee: employee.id,
            type: 'add',
            value: count,
            action: `${employee.name} added ${count} stock to ${product.name}`,
            product: product.id,
            createdAt: firebase.database.ServerValue.TIMESTAMP
        })
        await firebase.database().ref(`stock/${employee.branch}/products/${product.id}`).transaction(stock => {
            if (stock == null && currentStock != null) {
                return null
            }

            if (currentStock == null) {
                return parseInt(count)
            }

            return parseInt(stock) + parseInt(count)
        })
    }

    static async stock() {
        const employee = await AuthService.employee()

        const snapshot = await firebase.database().ref(`stock/${employee.branch}/products`).once('value')

        return snapshot.val() ? snapshot.val() : {}
    }

    static async remove(id) {
        return await firebase.firestore().collection('products').doc(id).delete()
    }

    static allStockMovement = (from, to) => {
        return AuthService.employee().then(employee => {
            return firebase.database().ref(`stock/${employee.branch}/updates`).once('value').then(snapshot => {
                const data = snapshot.val()
                const keys = Object.keys(data ? data : {})
                
                return keys.map(key => {
                    return {
                        ...data[key],
                        id: key
                    }
                }).sort((a, b) => {
                    if (a.createdAt > b.createdAt) {
                        return -1
                    } else {
                        return 1
                    }
                })
            })
        })
    }

    static stockMovement = (id) => {
        return AuthService.employee().then(employee => {
            return firebase.database().ref(`stock/${employee.branch}/updates`).orderByChild('product').equalTo(id).once('value').then(snapshot => {
                const data = snapshot.val()
                const keys = Object.keys(data ? data : {})
                
                return keys.map(key => {
                    return {
                        ...data[key],
                        id: key
                    }
                })
            })
        })
        
    }
}