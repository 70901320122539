import React, { Component } from 'react'
import { Button, Table, Modal, Card } from 'react-bootstrap';
import {EmployeesService} from '../../services/employee-service';
import { AddEmployeeModal } from './add-employee-modal'
import { ClipLoader } from 'react-spinners';

export class Employees extends Component {
    subscription

    constructor() {
        super()
        this.state = {
            employees: [],
            loading: true,
            showDelete: false,
            showAddEmployee: false,
            selectedEmployee: null
        }

        this.addEmployee = this.addEmployee.bind(this)
        this.onHide = this.onHide.bind(this)
        this.employeeAdded = this.employeeAdded.bind(this)
        this.employeeEdited = this.employeeEdited.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentWillUnmount() {
        this.subscription.unsubscribe()
    }

    componentDidMount() {
        this.subscription = EmployeesService.employees().subscribe(employees => {
            this.setState({
                employees: employees,
                loading: false
            })
        })
    }

    addEmployee() {
        this.setState({
            showAddEmployee: true
        })
    }

    onHide() {
        this.setState({
            showAddEmployee: false,
            selectedEmployee: null
        })
    }

    employeeAdded(employee) {
        this.state.employees.push(employee)
        const employees = this.state.employees.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        })
        this.setState({
            employees: employees
        })
    }

    editEmployee(employee) {
        this.setState({
            showAddEmployee: true,
            selectedEmployee: employee
        })
    }

    deleteEmployee() {
        for (let i = 0; i < this.state.employees.length; i++) {
            if (this.state.selectedEmployee.id === this.state.employees[i].id) {
                this.state.employees.splice(i, 1)
                this.setState({
                    employees: this.state.employees
                })
                break
            }
        }

        EmployeesService.remove(this.state.selectedEmployee.id)
        this.handleClose()
    }

    employeeEdited(employee) {
        for (let i = 0; i < this.state.employees.length; i++) {
            if (employee.id === this.state.employees[i].id) {
                const newEmployees = this.state.employees
                newEmployees[i] = employee
                this.setState({
                    employees: newEmployees
                })
                break
            }
        }
    }

    handleClose() {
        this.setState({ showDelete: false, selectedEmployee: null });
    }

    handleDelete(employee) {
        this.setState({ showDelete: true, selectedEmployee: employee });
    }

    render() {
        return (
            <Card>
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Employees</h3><Button variant="outline-dark" size="sm" style={{marginLeft: '10px'}} onClick={this.addEmployee}>Add Employee</Button></div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.employees.map((employee, index) => {
                                return (
                                    <tr key={employee.id}>
                                        <td>{index + 1}</td>
                                        <td>{employee.firstname}</td>
                                        <td>{employee.lastname}</td>
                                        <td style={{textTransform: 'capitalize'}}>{employee.role}</td>
                                        {/* <td><Link to={`/merchant/${employee.organization.key}/branch/${employee.branch.key}/edit`}>{employee.branch.name}</Link></td> */}
                                        <td>
                                            <Button onClick={() => this.editEmployee(employee)} style={{marginRight: '5px'}} variant="outline-primary" size="sm">Edit</Button>
                                            {/* <Button style={{marginRight: '5px'}} variant="outline-secondary" size="sm">Reset Password</Button> */}
                                            <Button variant="outline-danger" size="sm" onClick={() => {this.handleDelete(employee)}}>Remove</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>
                {!this.state.loading && this.state.employees.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any employees yet</div>}

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the employee '{this.state.selectedEmployee && this.state.selectedEmployee.firstname}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteEmployee()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddEmployeeModal employee={this.state.selectedEmployee} onEmployeeEdited={this.employeeEdited} onEmployeeAdded={this.employeeAdded} show={this.state.showAddEmployee} onHide={this.onHide} />
                </Card.Body>
            </Card>
        )
    }
}
