import config from '../config'

class GeocodingService {
    geocode(address) {
        return fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' +
        address.split(' ').join('+') + '&key=' + config.apiKey).then(response => response.json())
        .then(response => {
            if (response.results.length === 0) {
                return [null]
            }

          const result = response.results[0]
          console.log(result)

          if (result == null) {
              return null
          } else {
              console.log(result['geometry']['location'])
              return result['geometry']['location']
          }
        })


        // https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyBdrVocl3NFl2pPuRgaMvSWOqBPa7N_aeQ
    }
}

export default GeocodingService