import React from 'react'
import { Formik } from 'formik';
import { Badge, ProgressBar, Form, Row, Col, Button, Alert, InputGroup, FormControl } from 'react-bootstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import * as config from '../../config'
import GeocodingService from '../../services/geocoding-service';

const AnyReactComponent = ({ text }) => <div style={{
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: 'red',
    borderWidth: '2px',
    borderColor: 'white',
    borderStyle: 'solid'
}}></div>;

export class BranchAddress extends React.Component {
    geocodingService = new GeocodingService()
    state = {
        validAddress: true
    }

    constructor(props) {
        super(props)

        this.submit = this.submit.bind(this)
    }

    submit() {
        console.log(this.props)

        if (this.state.address) {
            if (this.props.submitted) {
                this.props.submitted(this.state)
            }
        } else {
            this.setState({
                validAddress: false
            })
        }
    }

    render() {
        if (this.props.bindForm) {
            this.props.bindForm(this.submit)
        }

        return (
            <Formik
                initialValues={this.props.values ? this.props.values : {
                    address: ''
                }}
                onSubmit={(values) => {
                    this.submit(values)
                }}
                >
                {({
                    values,
                    // handleBlur,
                    // handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    console.log(values)
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                            
                            <div style={{width: '100%', margin: '20px', marginTop: '50px'}}>
                                <p style={{textAlign: 'center'}}>Where is the branch situated? This will make it easier for your customers to find you</p>
                                {!this.state.validAddress && <div style={{color: 'red', fontSize: '0.8em'}}>Please enter a valid address before moving on</div>}
                            <GooglePlacesAutocomplete
                                    initialValue={values.address}
                                    onSelect={(data) => {
                                        this.geocodingService.geocode(data.description).then(coords => {
                                            if (coords) {
                                                values.location = coords
                                                this.setState({
                                                    coords: coords,
                                                    address: data.description,
                                                    validAddress: true
                                                })
                                            } else {
                                                this.setState({
                                                    coords: null,
                                                    address: null,
                                                    validAddress: false
                                                })
                                            }
                                        })
                                        values.address = data.description
                                        
                                    }}
                                />
                            </div>
                            <div style={{marginBottom: 20}}></div>
                            {/* <div style={{width: '100%', height: '250px'}}> */}
                            {/* <GoogleMapReact
                                bootstrapURLKeys={{ key: config.default.apiKey }}
                                defaultCenter={values.location ? values.location : {lat: 59.95,
                                    lng: 30.33}}
                                defaultZoom={18}
                                center={values.location ? values.location : null}
                                >
                                {values.location &&
                                    <AnyReactComponent
                                    {...values.location }
                                />}
                                </GoogleMapReact> */}
                            {/* </div> */}
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}