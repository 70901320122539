import React, { Component } from 'react'
import { Table, Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MerchantService from '../services/merchant-service';

class Merchants extends Component {
    constructor() {
        super()
        this.state = {
            merchants: [],
            loading: true
        }

        new MerchantService().merchants().then(merchants => {
            this.setState({
                merchants: merchants,
                loading: false
            })
        })
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <h3>Merchants <Link to='/merchant/new'><Button variant="outline-primary" size="sm" style={{marginLeft: '10px'}}>Add Merchant</Button></Link></h3>
                
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Merchant</th>
                            <th>Branches</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.merchants.map((merchant, index) => {
                                console.log(merchant)
                                return (
                                    <tr key={merchant.key}>
                                    <td>{index + 1}</td>
                                    <td>{merchant.name}</td>
                                    <td><Link to={`/dashboard/merchant/${merchant.key}/branches`}>{merchant.branches} branch{merchant.branches === 1 ? '' : 'es'}</Link></td>
                                    <th>{merchant.type}</th>
                                    <td>
                                        <Link to={`/dashboard/merchant/${merchant.key}/edit`}><Button style={{marginRight: '5px'}} variant="outline-primary" size="sm">Edit</Button></Link>
                                        <Button style={{marginRight: '5px'}} variant="outline-secondary" size="sm">View on Map</Button>
                                        <Button variant="outline-danger" size="sm">Deactivate</Button>
                                    </td>
                                </tr>
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                {this.state.loading === true ? <ProgressBar animated now={100}></ProgressBar> : null}
                        </div>
        )
    }
}

export default Merchants;