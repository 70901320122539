import React from 'react'
import { Card, Form } from 'react-bootstrap';
import { Pie, PieChart, Legend, Cell, AreaChart, linearGradient, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer } from 'recharts';
import { Formik } from 'formik';
import moment from 'moment'
import { StatsService } from '../../services/stats.service';

var COLORS = [
    '#8884d8',
    '#c3c2ea'
]

function DrawChart(props) {
//   const data = props.data
  const data = [
    {
      "name": "Returning (35%)",
      "value": 389
    },
    {
      "name": "New (65%)",
      "value": 603
    }
  ];
  console.log('draw')
  
    return (
        <ResponsiveContainer width='99%' height={300} data={data}>
          <PieChart width={200} height={200}>
            <Pie isAnimationActive={true} label={true} data={data} dataKey="value" nameKey="name" width={200} height={200} cx="50%" cy="50%" outerRadius={120} fill="#8884d8" >
            {
          	    data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
            }
            </Pie>
            <Tooltip />
            <Legend verticalAlign="top" height={36}/>
          </PieChart>
        </ResponsiveContainer>
    )
}

export class ReturningCustomers extends React.Component {
    state = {
      data: [],
      durationType: 'week'
    }

    componentDidMount() {
      this.onSubmit(this.state.durationType)
    }

    onSubmit(value) {
      console.log(value)
      let startDate, endDate, promise

      endDate = moment(new Date())
      switch (value) {
        case 'week':
          startDate = moment(endDate).subtract(7, 'day').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD'))
          break;
        case 'month':
          startDate = moment(endDate).subtract(1, 'month').format('YYYY-MM-DD')
          promise = StatsService.dailyStats(startDate, endDate.format('YYYY-MM-DD'))
          break
        case 'year':
          startDate = moment(endDate).subtract(1, 'year').format('YYYY-MM-DD')
          promise = StatsService.monthlyStats(startDate, endDate.format('YYYY-MM-DD'))
          break
        default:
          break;
      }
      
      
      promise.then(data => {
        data = data.map(item => {
          let name
          if (value === 'week') {
            name = item.day
          } else if (value === 'year') {
            name = item.month
          } else {
            name = item.day
          }
          return {
            name: name,
            orders: item.orders
          }
        })

        if (value === 'year') {
            data = data.map(item => {
              item.name = moment(new Date(item.name + '-01')).format('YYYY MMM')

              return item
            })
        }

        this.setState({
          data: data
        })
        console.log(data)
      })
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <div style={{display: 'flex'}}>
                      <Card.Title style={{flex: 1}}>New vs Returning Customers</Card.Title>
                      <Formik
                        initialValues={{
                          durationType: this.state.durationType
                        }}
                        validateOnChange={(value) => {
                          console.log(value)
                        }}
                      >
                        {({
                          values,
                          handleChange
                        }) => {
                          return (
                            <Form>
                              <Form.Control value={values.durationType} id='durationType' as="select" onChange={(e) => {
                                handleChange(e)
                                this.onSubmit(e.target.value)
                              }}>
                                <option value='week'>Week</option>
                                <option value='month'>Month</option>
                                <option value='year'>Year</option>
                              </Form.Control>
                            </Form>
                          )
                        }}
                      </Formik>
                      
                    </div>
                    <DrawChart data={this.state.data} />
                </Card.Body>
            </Card>
        )
    }
}