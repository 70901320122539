import React from 'react'
import { Card, Table, Button, Modal } from 'react-bootstrap';
import { ProductsService } from '../services/products.service';
import { ClipLoader } from 'react-spinners';
import { AddProductModal } from './products/add-product-modal';
import { Link } from 'react-router-dom'
import { CategoriesService } from '../services/category-service';
import { AddStockModal } from './products/add-stock-modal';

export class Products extends React.Component {
    state = {
        products: null,
        showDelete: false,
        showAddProduct: false,
        selectedProduct: null,
        showStock: false,
        stock: null
    }

    constructor(props) {
        super(props)

        // this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.productAdded = this.productAdded.bind(this)
        this.productEdited = this.productEdited.bind(this)
        this.stockAdded = this.stockAdded.bind(this)
    }

    componentDidMount() {
        ProductsService.products().then(products => {
            ProductsService.stock().then(stock => {
                CategoriesService.categories().then(categories => {
                    const keyCategories = {}
                    categories.forEach(category => {
                        keyCategories[category.id] = category
                    })
                    this.setState({
                        products: products,
                        categories: keyCategories,
                        stock: stock
                    })
                })
            })
        })
    }

    handleClose() {
        this.setState({ showDelete: false, selectedProduct: null });
    }

    handleShow(product) {
        this.setState({ showDelete: true, selectedProduct: product });
    }

    handleShowStock(product) {
        this.setState({ showStock: true, selectedProduct: product });
    }

    deleteProduct() {
        for (let i = 0; i < this.state.products.length; i++) {
            if (this.state.selectedProduct.id === this.state.products[i].id) {
                this.state.products.splice(i, 1)
                this.setState({
                    products: this.state.products
                })
                break
            }
        }

        ProductsService.remove(this.state.selectedProduct.id)
        this.handleClose()
    }

    addProduct() {
        this.setState({
            showAddProduct: true
        })
    }

    productAdded(product) {
        this.state.products.push(product)
        const products = this.state.products.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        })
        this.setState({
            products: products,
            selectedProduct: null
        })
    }

    stockAdded(product, count) {
        const newStock = Object.assign({}, this.state.stock)
        newStock[product.id] = newStock[product.id] == null ? count : newStock[product.id] + parseInt(count)

        this.setState({
            stock: newStock
        })
    }

    productEdited(product) {
        for (let i = 0; i < this.state.products.length; i++) {
            if (product.id === this.state.products[i].id) {
                const newProducts = this.state.products
                newProducts[i] = product
                this.setState({
                    products: newProducts,
                    selectedProduct: null
                })
                break
            }
        }
    }

    editProduct(product) {
        this.setState({
            showAddProduct: true,
            selectedProduct: product
        })
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Products</h3>
                        <Link to='/dashboard/products/categories'><Button size='sm' variant='outline-dark' onClick={this.showCategories} style={{marginRight: 10}}>Categories</Button></Link>
                        <Button size='sm' variant='outline-dark' onClick={() => this.addProduct()}>Add Product</Button></div>
                        <hr />
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Category</th>
                                    <th>Price</th>
                                    <th>Cost Price</th>
                                    <th>Stock</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.products && this.state.products.map((product, index) => {
                                    return (
                                        <tr key={product.id}>
                                            <td>{index + 1}</td>
                                            <td>{product.name}</td>
                                            <td>{product.description}</td>
                                            <td>{this.state.categories[product.category] ? this.state.categories[product.category].name : 'Generic'}</td>
                                            <td>R {(product.price / 100).toFixed(2)}</td>
                                            <td>{product.cost ? 'R' + (product.cost / 100).toFixed(2) : '-'}</td>
                                            <td>{this.state.stock[product.id] ? this.state.stock[product.id] : '-'}</td>
                                            <th>
                                                <Button onClick={() => this.editProduct(product)} variant='outline-primary' size='sm' style={{marginRight: '5px'}}>Edit</Button>
                                                <Button variant='outline-info' size='sm' onClick={() => {this.handleShowStock(product)}} style={{marginRight: '5px'}}>Add Stock</Button>
                                                <Button variant='outline-danger' size='sm' onClick={() => {this.handleShow(product)}}>Delete</Button>
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {this.state.products && this.state.products.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any products yet</div>}
                        {this.state.products == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                    </Card.Body>
                </Card>

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the product '{this.state.selectedProduct && this.state.selectedProduct.name} - {this.state.selectedProduct && this.state.selectedProduct.description}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteProduct()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddProductModal product={this.state.selectedProduct} onProductEdited={this.productEdited} onProductAdded={this.productAdded} show={this.state.showAddProduct} onHide={() => {
                    this.setState({
                        showAddProduct: false,
                        selectedProduct: null
                    })
                }} />

                <AddStockModal product={this.state.selectedProduct} onStockAdded={this.stockAdded} show={this.state.showStock} onHide={() => {
                    this.setState({
                        showStock: false,
                        selectedProduct: null
                    })
                }} />
            </div>
        )
    }
}