import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap';
import { SmsService } from '../../services/sms-service';

export class ReplyModal extends React.Component {

    rating;
    messageRef;

    constructor(props) {
        super(props)
        this.rating = this.props.rating
        this.onHide = this.onHide.bind(this)
    }

    onHide() {
        if (this.props.onHide != null) {
            this.props.onHide()
        }
    }

    handleChange = e => {
        this.messageRef = e.target.value
    };

    componentDidMount() {
        this.textInput = React.createRef();
    }
    reply() {
        const contact = this.rating.contact
 
        SmsService.sendSms(contact, this.messageRef)      
        .then(function (response) {
            alert("message sent")
          
        })
        .catch(function (error) {
            alert("message sending failed!")
         
        });
    }
    cancel(){
      
        this.onHide();
    }
    finish(){
        this.onHide();
    }
    render() {

        return (
            <Modal show={true} onHide={this.onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reply to   {this.rating.name}  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Recepient</Form.Label>
                            <Form.Control type="text" defaultValue={this.rating.contact} disabled />
                            <Form.Text className="text-muted">
                                You are sending a text message to {this.rating.name}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Write Message</Form.Label>
                            <Form.Control as="textarea" rows="3" onChange={this.handleChange} />
                        </Form.Group>
                        <Button style={{ margin: 10 }} variant="primary" onClick={() => this.reply()}>
                            Send
                        </Button>
                        <Button style={{ margin: 10 }} variant="primary" onClick={() => this.cancel()} >
                            Cancel
                        </Button>
                        <Button  style={{margin: 10}} variant="primary" onClick={() => this.finish()} >
                            Finish
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }
}