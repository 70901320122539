import React from 'react'
import { ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip, linearGradient, Legend } from 'recharts'
import {red, green} from '@material-ui/core/colors'

export const DeliveryLineChart = ({data}) => {
    return (
        <ResponsiveContainer width='99%' height={300} data={data}>
          <AreaChart height={200} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={green['300']} stopOpacity={0.5}/>
                <stop offset="95%" stopColor={green['300']} stopOpacity={0.5}/>
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={red['300']} stopOpacity={0.5}/>
                <stop offset="95%" stopColor={red['300']} stopOpacity={0.5}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area dot={{ stroke: green['300'], strokeWidth: 2 }} type="linear" dataKey="Delivered" stroke={green['300']} fillOpacity={1} fill="url(#colorUv)" />
            <Area dot={{ stroke: red['300'], strokeWidth: 2 }} type="linear" dataKey="Not Delivered" stroke={red['300']} fillOpacity={0.6} fill="url(#colorPv)" />
            
            <Legend verticalAlign="top" height={36}/>
            </AreaChart>
        </ResponsiveContainer>
    )
}