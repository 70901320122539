import firebase from 'firebase'

class MerchantService {
    add(merchant, file) {
        const data = {
            ...merchant,
            branches: 0
        }
        return firebase.database().ref(`organizations`).push(data).then((ref) => {
            return {
                ...data,
                key: ref.key
            }
        })
    }

    edit(id, merchant, file) {
        return firebase.database().ref(`organizations/${id}`).update(merchant)
    }

    merchant(id) {
        console.log(`organizations/${id}`)
        return firebase.database().ref(`organizations/${id}`).once('value').then(snapshot => {
            return snapshot.val()
        })
    }

    branch(id) {
        return firebase.database().ref(`branches/${id}`).once('value').then(snapshot => {
            return snapshot.val()
        })
    }

    branches(merchantId) {
        return firebase.database().ref(`branches`).orderByChild('organization').equalTo(merchantId).once('value').then(snapshot => {
            const branches = snapshot.val()
            if (branches == null) {
                return []
            }

            return Object.keys(branches).map(key => {
                return {
                    ...branches[key],
                    key: key
                }
            }).sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                } else {
                    return -1
                }
            })
        })
    }

    merchants() {
        return firebase.database().ref(`organizations`).orderByChild('name').once('value').then(snapshot => {
            const merchants = snapshot.val()
            if (merchants == null) {
                return []
            }

            return Object.keys(merchants).map(key => {
                return {
                    ...merchants[key],
                    key: key
                }
            }).sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                } else {
                    return -1
                }
            })
        })
    }
}

export default MerchantService