import { AuthService } from "./auth.service";
import firebase from 'firebase'

export class RatingsService {
    static async ratings(startDate, endDate) {
        // console.log(startDate, endDate)
        const dateFrom = new Date(startDate).getTime()
        const dateTo = new Date(endDate+'T23:59:59').getTime()
        const employee = await AuthService.employee()

        // console.log(dateTo)

        const snapshot = await firebase.database().ref(`ratings/${employee.branch}`)
            .orderByChild('createdAt')
            .startAt(dateFrom).endAt(dateTo)
            .once('value')

        const data = snapshot.val()

        if (data) {
            return Object.keys(data).map(key => {
                return {
                    ...data[key],
                    id: key
                }
            })
        } else {
            return []
        }
    }
}