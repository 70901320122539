import { keyframes } from '@emotion/core';
import React, { useEffect, useState } from 'react'
import { Card, Table, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners';
import { CategoriesService } from '../../services/category-service';
import { ProductsService } from '../../services/products.service';

export const Stock = () => {
    const [products, setProducts] = useState(null)
    const [stock, setStock] = useState(null)
    const [stockTotal, setStockTotal] = useState(0)
    const [stockTotalValue, setStockTotalValue] = useState(0)
    const [stockCostValue, setStockCostValue] = useState(0)

    useEffect(() => {
        ProductsService.products().then(products => {
            ProductsService.stock().then(stock => {
                CategoriesService.categories().then(categories => {
                    const keyCategories = {}
                    categories.forEach(category => {
                        keyCategories[category.id] = category
                    })
                    setStock(stock)
                    setProducts(products)
                    
                    const keys = Object.keys(stock)
                    let total = 0
                    let totalValue = 0
                    let costValue = 0
                    keys.map(key => {
                        total += stock[key]
                    })

                    products.forEach(product => {
                        if (stock[product.id]) {
                            totalValue += (stock[product.id] * product.price) / 100
                        }

                        if (stock[product.id] && product.cost) {
                            costValue += (stock[product.id] * product.cost / 100)
                        }
                    })

                    setStockTotal(total)
                    setStockTotalValue(totalValue)
                    setStockCostValue(costValue)
                })
            })
        })
    }, [])
    
    return (
        <div>
                <Card>
                    <Card.Body>
                        <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Stock</h3><Link to='/dashboard/stock-movement'><Button size='sm' variant='outline-dark'>View Stock Movement</Button></Link></div>
                        <hr />
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    {/* <th>Category</th> */}
                                    
                                    <th>Stock</th>
                                    <th>Stock Value</th>
                                    <th>Stock Cost</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products && products.map((product, index) => {
                                    return (
                                        <tr key={product.id}>
                                            <td>{index + 1}</td>
                                            <td>{product.name}</td>
                                            <td>{product.description}</td>
                                            {/* <td>{this.state.categories[product.category] ? this.state.categories[product.category].name : 'Generic'}</td> */}
                                            
                                            <td>{stock[product.id] ? stock[product.id] : '-'}</td>
                                            <td>{stock[product.id] ? 'R ' + ((product.price / 100 * stock[product.id]).toFixed(2)) : '-'}</td>
                                            <td>{stock[product.id] && product.cost ? 'R ' + ((product.cost / 100 * stock[product.id]).toFixed(2)) : '-'}</td>
                                            <td><Link to={`/dashboard/stock/${product.id}`}><Button size='sm' variant='outline-primary'>View</Button></Link></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <th>{stockTotal}</th>
                                    <th>R {stockTotalValue.toFixed(2)}</th>
                                    <th>R {stockCostValue.toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </Table>
                        {products && products.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any products yet</div>}
                        {products == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                    </Card.Body>
                </Card>
            </div>
    )
}