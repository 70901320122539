import React, { Component } from 'react'
import { Badge, ProgressBar, Form, Row, Col, Button, Alert, InputGroup, FormControl } from 'react-bootstrap';
import MerchantService from '../services/merchant-service';
import { Formik } from 'formik';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
// import Map from '../components/Map';
import GoogleMapReact from 'google-map-react';
import * as config from '../config'
import GeocodingService from '../services/geocoding-service';
import BranchService from '../services/branch-service';

const AnyReactComponent = ({ text }) => <div style={{
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: 'red',
    borderWidth: '2px',
    borderColor: 'white',
    borderStyle: 'solid'
}}></div>;

class SaveBranch extends Component {
    merchantService = new MerchantService()
    branchService = new BranchService()
    geocodingService = new GeocodingService()

    constructor() {
        super()
        this.state = {
            merchantId: null,
            branchId: null,
            merchant: null,
            saved: false,
            savingError: false
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({
            merchantId: this.props.match.params.merchant,
            branchId: this.props.match.params.branch
        })

        this.merchantService.merchant(this.props.match.params.merchant).then(merchant => {
            return this.merchantService.branch(this.props.match.params.branch).then(branch => {
                console.log(merchant, branch)
                this.setState({
                    merchant: merchant,
                    branch: branch
                })
            })
        })
    }

    render() {
        return (
            <div>
                <h3>{this.state.branchId ? 'Edit' : 'Add'} Branch {this.state.merchant ? <Badge variant="secondary">{this.state.merchant.name}</Badge>: null}</h3>
                {this.state.saved ? <Alert variant="success" dismissible>Branch successfully saved</Alert> : null}
                {this.state.savingError ? <Alert variant="danger" dismissible>An error has occurred while saving branch</Alert> : null}
                {this.state.merchant || (this.state.branch && this.state.branchId) ? this.renderForm() : <ProgressBar animated now={100}></ProgressBar>}
            </div>
        )
    }

    submit(values) {
        var promise
        const key = values.key
        delete values.key
        values['organization'] = this.state.merchantId
        if (this.state.branch) {
            promise = this.branchService.update(key, values)
        } else {
            promise = this.branchService.add(key, values)
        }

        promise.then(() => {
            this.setState({
                saving: false,
                saved: true
            })
        }).catch(() => {
            this.setState({
                saving: false,
                savingError: true
            })
        })
    }

    renderForm() {
        console.log(this.state.branch)
        console.log(this.state)
        const initialValues = {
            ...this.state.branch,
            key: this.state.branchId
        }
        console.log(initialValues)
        return (
            <Formik
                initialValues={this.state.branch ? initialValues : {
                    name: '',
                    address: ''
                }}
                onSubmit={(values) => {
                    this.submit(values)
                }}
                >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit
                }) => {
                    console.log(values)
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                <Form.Group controlId="key">
                                    <Form.Label>Branch Link</Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                        placeholder="Branch Link"
                                        aria-describedby="basic-addon2"
                                        value={values.key} onBlur={handleBlur} onChange={handleChange}
                                        />
                                        <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2">.waitr.co.za</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                </Form.Group>
                                
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Branch Name</Form.Label>
                                        <Form.Control placeholder="Branch Name" value={values.name} onBlur={handleBlur} onChange={handleChange} type="text"></Form.Control>
                                    </Form.Group>
                                    <input style={{display: 'none'}} value={values.address} onBlur={handleBlur} onChange={handleChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <GooglePlacesAutocomplete
                                    initialValue={values.address}
                                    onSelect={(data) => {
                                        this.geocodingService.geocode(data.description).then(coords => {
                                            if (coords) {
                                                values.location = coords
                                                values.location = coords
                                                this.setState(this.state)
                                            }
                                        })
                                        values.address = data.description

                                    }}
                                />
                                </Col>
                            </Row>
                            <Row>
                            <div style={{width: '100%', height: '350px', margin: '20px'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: config.default.apiKey }}
                                defaultCenter={values.location ? values.location : {lat: 59.95,
                                    lng: 30.33}}
                                defaultZoom={18}
                                center={values.location ? values.location : null}
                                >
                                {values.location &&
                                    <AnyReactComponent
                                    {...values.location }
                                />}
                                </GoogleMapReact>
                            </div>
                            </Row>
                            <Row>
                                <Button style={{margin: '10px'}} type="submit">Save Branch</Button>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default SaveBranch;