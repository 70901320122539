import React, { Component, useState } from 'react'
import { OrdersService } from '../services/orders-service';
import { Table, Form, Card, Row, Col, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { ViewEmployeesModal } from './orders/view-employees';
import { HardwareService } from '../services/hardware-service';
import { ProductsService } from '../services/products.service';
import { CategoriesService } from '../services/category-service';
import { ListGroup } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { SalesCount } from '../components/charts/sales-count';
import { SmsService } from '../services/sms-service';
import { OverlayTrigger } from 'react-bootstrap';
import { Popover } from 'react-bootstrap';

const MessageStatusDetail = ({order}) => {
    const [sending, setSending] = useState(false)

    const sendSMS = () => {
        SmsService.sendSms(order.phone ? order.phone : order.cellphone, order.smsMessage)
    }

    // const [show, setShow] = useState(false)
    const status = SmsService.ERRORS[order.smsStatus ? parseInt(order.smsStatus) : 'isNull']
    const popover = (
        <Popover id={`popover-positioned-${order.smsId}`}>
            <Popover.Title>SMS Status</Popover.Title>
            
            <Popover.Content>
                <div style={{minWidth: 200}}>
                    <h6>Definition</h6>
                    <p>{status.definition}</p>
                    <hr/>

                    <h6>Description</h6>
                    <p>{status.description}</p>
                    <hr/>

                    <h6>What to do?</h6>
                    <p>{status.action}</p>

                    {status.action != 'Nothing' && (
                        <Button>Send SMS Again</Button>
                    )}
                </div>
            </Popover.Content>
        </Popover>
    )
    

    return popover
}

export class Orders extends Component {
    total = 0
    handleSubmit
    handleSendInvoiceSubmit
    isExport = false

    

    constructor(props) {
        super(props)

        this.state = {
            orders: null,
            showDelete: false,
            selectedOrder: null,
            isExport: false,
            search: '',
            categories: [],
            total: 0,
            totalCount: 0
        }

        const today = moment(new Date()).format('YYYY-MM-DD')
        OrdersService.orders(today, today).then(data => {
            CategoriesService.categories().then(categories => {
                const categoryValues = this.calculateCategories(categories, data)
                
                categories.map(category => {
                    category.salesCount = categoryValues[category.id].salesCount
                    category.salesAmount = categoryValues[category.id].salesAmount
                })

                console.log(categories)

                this.setState({
                    orders: data,
                    categories: categories
                })
            })
            
        })

        this.onHide = this.onHide.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.export = this.export.bind(this)
        this.handleCloseInvoice = this.handleCloseInvoice.bind(this)
    }

    calculateCategories(categories, orders) {
        const categoryValues = {}
        let total = 0
        let totalCount = 0

        categories.forEach(category => {
            categoryValues[category.id] = {
                salesCount: 0,
                salesAmount: 0
            }
        })

        orders.forEach(order => {
            if (!order.cancelled && order.products != null) {
                order.products.forEach(product => {
                    if (categoryValues[product.category]) {
                        categoryValues[product.category].salesCount += product.qty
                        categoryValues[product.category].salesAmount += (product.qty * product.price)
    
                        if (product.discount > 0) {
                            console.log(product)
                        }
                        
                        totalCount += product.qty
                    }
                })
                total += order.price
            }
        })

        this.setState({
            totalCount: totalCount,
            total: total
        })

        return categoryValues
    }

    handleClose() {
        this.setState({
            showDelete: false,
            showViewOrder: false,
            selectedOrder: null
        })
    }

    handleCloseInvoice() {
        this.setState({
            showSendInvoice: false,
            selectedOrder: null
        })
    }

    viewSendInvoice(order) {
        this.setState({
            showSendInvoice: true,
            selectedOrder: order
        })
    }

    viewEmployees(order) {
        this.setState({
            showEmployees: true,
            currentOrder: order
        })
    }

    onHide() {
        this.setState({
            showEmployees: false
        })
    }

    export() {
        this.setState({
            isExport: true
        }, () => {
            this.handleSubmit()
        })
    }

    filter(data, options) {
        console.log(options)

        if (this.state.isExport) {
            OrdersService.exportOrders(data.start, data.end, options).finally(() => {
                this.setState({
                    isExport: false
                })
            })
        } else {
            this.setState({
                orders: null
            }, () => {
                OrdersService.orders(data.start, data.end, options).then(data => {
                    console.log(data)
                    const categoryValues = this.calculateCategories(this.state.categories, data)
                    const categories = [...this.state.categories]

                    categories.map(category => {
                        category.salesCount = categoryValues[category.id].salesCount
                        category.salesAmount = categoryValues[category.id].salesAmount
                    })

                    this.setState({
                        orders: data,
                        categories: categories
                    })
                })
            })
        }
        
        
    }

    cancelOrder(order) {
        this.setState({
            selectedOrder: order,
            showDelete: true
        })
    }

    viewOrder(order) {
        this.setState({
            selectedOrder: order,
            showViewOrder: true
        })
    }

    paymentType(order) {
        if (order.paid) {
            return order.paymentType && order.paymentType.trim().length > 0 ? order.paymentType : 'Paid'
        } else if (order.cancelled) {
            return 'Cancelled'
        } else {
            return 'Not Paid'
        }
    }

    print() {
        
        HardwareService.ping().then(success => {
            if (success) {
                HardwareService.print(this.state.selectedOrder, false)
                this.setState({
                    showDelete: false,
                    showViewOrder: false,
                    selectedOrder: null
                })
                
            } else {
                alert("Printer not found. Please make sure the Waitr Controller is running on your computer")
            }
        }).catch(() => {
            alert("Printer not found. Please make sure the Waitr Controller is running on your computer")
        })
        
    }

    confirmCancel() {
        const date = moment(this.state.selectedOrder.createdAt).format('YYYY-MM-DD')
        const id = this.state.selectedOrder.id.split('-')[3]
        OrdersService.cancel(id, date).then(() => {
            this.state.selectedOrder.cancelled = true
            this.setState({
                selectedOrder: null,
                showDelete: false
            })
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        this.total = 0;
        this.cancelled = 0;
        
        return (
            <Card>
                
                <Card.Body>
                <Modal style={{zIndex: 100000}} show={this.state.showSendInvoice} onHide={this.handleCloseInvoice} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Order {this.state.selectedOrder && this.state.selectedOrder.id.split('-')[3] }</Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>
                            Send the invoice to
                            <Formik onSubmit={values => {
                                console.log('Submit clicked')
                                const phone = values.phone

                                this.setState({
                                    saving: true
                                })
                                OrdersService.sendInvoice(this.state.selectedOrder.id, phone).then(() => {
                                    this.setState({
                                        saving: false
                                    })
                                    this.handleCloseInvoice()
                                }).catch(() => {
                                    this.setState({
                                        saving: false
                                    })
                                })
                                
                            }} enableReinitialize initialValues={{phone: this.state.selectedOrder ? this.state.selectedOrder.phone : ''}}>
                                {({
                                    values,
                                    handleSubmit,
                                    handleBlur,
                                    handleChange
                                }) => {
                                    this.handleSendInvoiceSubmit = handleSubmit
                                    return (
                                        <Form.Group controlId='value'>
                                            <Form.Label>Phone Number</Form.Label>
                                            <Form.Control value={values.phone} onBlur={handleBlur('phone')} onChange={handleChange('phone')} type='text' placeholder="Input the customer's phone number" />
                                        </Form.Group>
                                    )
                                }}
                            </Formik>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseInvoice} disabled={this.state.saving}>
                                Cancel
                            </Button>
                            <Button variant="primary" type='submit' onClick={() => this.handleSendInvoiceSubmit()} disabled={this.state.saving}>
                                <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Send</span>{this.state.saving && <ClipLoader size={20} />}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal style={{zIndex: 100000}} show={this.state.showViewOrder} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Order {this.state.selectedOrder && this.state.selectedOrder.id.split('-')[3] }</Modal.Title>
                        </Modal.Header>
                        {this.state.selectedOrder && <Modal.Body>
                            <h5>Customer</h5>
                            <p>Name: {this.state.selectedOrder.name}<br />
                            Phone: {this.state.selectedOrder.phone}
                            </p>

                            <h5>Order Info</h5>
                            <p>
                                Order Number: {this.state.selectedOrder.id.split('-')[3]}<br />
                                Date: {moment(this.state.selectedOrder.createdAt).format('YYYY-MM-DD HH:mm:ss')}<br />
                                Description: {this.state.selectedOrder.description}<br />
                                Special Instructions: {this.state.selectedOrder.instructions}<br />
                                Completed: {this.state.selectedOrder.completed ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}<br />
                                Collected: {this.state.selectedOrder.collectedAt > 0 ? moment(this.state.selectedOrder.collectedAt).format('YYYY-MM-DD HH:mm:ss') : 'Not collected yet'}<br />
                                Paid: {this.state.selectedOrder.paid ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}<br />
                                Payment Method: {this.state.selectedOrder.paymentType}<br />
                            </p>

                            <h5>Products</h5>
                            <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Discount</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.selectedOrder.products && this.state.selectedOrder.products.map((product, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td style={{maxWidth: 200}}>{product.name + (product.customDescription ? ' ' + product.customDescription : '')}</td>
                                    <td>{product.qty}</td>
                                    <td>R{product.discount.toFixed(2)}</td>
                                    <td>R{(((product.price * product.qty) / 100) - product.discount).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>R{(this.state.selectedOrder.price / 100).toFixed(2)}</td>
                        </tr>
                    </tbody>
                                
                            </Table>
                        </Modal.Body>}
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => this.print()}>
                                Print
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to cancel the order '{this.state.selectedOrder && this.state.selectedOrder.order}'?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Nevermind
                            </Button>
                            <Button variant="primary" onClick={() => this.confirmCancel()}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <h3 style={{flexGrow: 1}}>Orders <Button size='sm' variant='outline-dark' onClick={this.export} disabled={this.state.isExport}>Export</Button></h3>
                        <Formik 
                            initialValues={{
                                start: moment(new Date()).format('YYYY-MM-DD'),
                                end: moment(new Date()).format('YYYY-MM-DD'),
                                notPaid: null,
                                notCollected: null,
                                collected: null,
                                paid: null,
                                category: null
                            }}
                            onSubmit={(values) => this.filter(values, {
                                notPaid: values.notPaid, 
                                notCollected: values.notCollected,
                                paid: values.paid,
                                collected: values.collected,
                                category: values.category
                            })}
                            >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values
                            }) => {
                                this.handleSubmit = handleSubmit
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="start">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>From</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="end">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>To</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="2">
                                                <Button type='submit'>Filter</Button>
                                            </Col>
                                            {/* <Col sm="2">
                                                
                                            </Col> */}
                                        </Row>
                                        <Row>
                                        <Col sm="8">
                                                <Form.Group as={Row} controlId="category">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>Category</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.category} onChange={handleChange} as='select'>
                                                            <option value={null}>All</option>
                                                            {this.state.categories.map((category, index) => {
                                                                return (
                                                                    <option value={category.id} key={`category-${index}`}>{category.name}</option>
                                                                )
                                                            })}
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group as={Row} >
                                                    <Col sm="3">
                                                        <Form.Check id="notCollected" onBlur={handleBlur} value={values.notCollected} onChange={handleChange} type="checkbox" label="Not Collected" />
                                                    </Col>
                                                    <Col sm="3">
                                                        <Form.Check id="collected" onBlur={handleBlur} value={values.collected} onChange={handleChange} type="checkbox" label="Collected" />
                                                    </Col>
                                                    <Col sm="3">
                                                        <Form.Check id="notPaid" onBlur={handleBlur} value={values.notPaid} onChange={handleChange} type="checkbox" label="Not Paid" />
                                                    </Col>
                                                    <Col sm="3">
                                                        <Form.Check id="paid" onBlur={handleBlur} value={values.paid} onChange={handleChange} type="checkbox" label="Paid" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            {/* <Col> */}
                                            {/* <Form.Group as={Row} controlId="notPaid">
                                                <Col sm="4">
                                                    <Form.Check onBlur={handleBlur} value={values.notPaid} onChange={handleChange} type="checkbox" label="Not Paid" />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="paid">
                                                <Col sm="4">
                                                    <Form.Check onBlur={handleBlur} value={values.notPaid} onChange={handleChange} type="checkbox" label="Paid" />
                                                </Col>
                                            </Form.Group> */}
                                            {/* </Col> */}
                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                        
                    </div>

                    <hr />

                    <div style={{marginBottom: 15}}>
                            <Form.Control onChange={(event) => {
                                this.setState({
                                    search: event.target.value.toLowerCase()
                                })
                                // console.log(event.target.value)
                            }} placeholder='Search' />
                        </div>

                    {this.state.categories && this.state.categories.length > 0 && 
                        (
                            <Row>
                                <Col sm="6">
                                    <ListGroup style={{marginBottom: 20}}>
                                        <ListGroup.Item variant="dark"><b>Sales Count ({this.state.totalCount ? this.state.totalCount.toFixed(0) : 0})</b></ListGroup.Item>
                                        {this.state.categories.map(category => {
                                            return (
                                                <ListGroup.Item><div style={{display: 'flex'}}><div style={{flex: 1}}>{category.name}</div><div><Badge variant="secondary">{category.salesCount.toFixed(0)}</Badge> {this.state.totalCount > 0 ? '(' + (category.salesCount / this.state.totalCount * 100).toFixed(1) + '%)' : ''}</div></div></ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Col>
                                <Col sm="6">
                                    <ListGroup style={{marginBottom: 20}}>
                                        <ListGroup.Item variant="dark"><b>Sale Amounts (R{(this.state.total / 100).toFixed(2)})</b></ListGroup.Item>
                                        {this.state.categories.map(category => {
                                            return (
                                                <ListGroup.Item><div style={{display: 'flex'}}><div style={{flex: 1}}>{category.name}</div><div><Badge variant="secondary">R{(category.salesAmount / 100).toFixed(2)}</Badge> {this.state.total > 0 ? '(' + (category.salesAmount / this.state.total * 100).toFixed(1) + '%)' : ''}</div></div></ListGroup.Item>
                                            )
                                        })}
                                    </ListGroup>
                                </Col>

                                {/* <Col sm="6">
                                    <Card>
                                        <SalesCount />
                                    </Card>
                                </Col> */}
                            </Row>
                        )
                    }
                    

                    <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Order</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Transaction</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Duration</th>
                            <th>Amount</th>
                            <th>Employees</th>
                            <th>SMS Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orders && this.state.orders.filter(order => {
                            const text = [
                                order.order,
                                order.description,
                                order.instructions
                            ].join(' ')
                            if (text.toLowerCase().indexOf(this.state.search) == -1) {
                                return false
                            }

                            return true
                        }).map((order, index) => {
                            this.total += order.price;
                            if (order.cancelled) {
                                this.cancelled += order.price
                            }
                            order.price = order.price ? order.price : 0
                            const startDate = moment(new Date(order.createdAt))
                            const endDate = moment(new Date(order.finishedAt))
                            const diff = endDate.diff(startDate)
                            const duration = order.duration === -1 ? '-' : moment.utc(diff).format('HH:mm:ss')

                            
                            return (
                                <tr key={order.id}>
                                    <td>{index + 1}</td>
                                    <td>{order.order}</td>
                                    <td style={{maxWidth: 150}}>{order.description ? order.description : order.name}</td>
                                    <td>{order.phone ? order.phone : order.cellphone}</td>
                                    <td style={{
                                        color: !!order.cancelled == true || order.paid == false ? 'red' : (order.paymentType === 'card' ? 'blue' : 'green'),
                                        textTransform: 'capitalize'
                                    }}>{!!order.cancelled == true || order.paid == false ? this.paymentType(order) : this.paymentType(order)}</td>
                                    <td>{moment(new Date(order.createdAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>{order.finishedAt === 0 ? '-' : moment(new Date(order.finishedAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>{duration}</td>
                                    <td>R{(order.price / 100).toFixed(2)}</td>
                                    <td><Button onClick={() => this.viewEmployees(order)} variant="link">{order.employees ? order.employees.length : '0'} employee{order.employees && order.employees.length == 1 ? '' : 's'}</Button></td>
                                    <td>
                                    {order.smsId ? <OverlayTrigger trigger='click' placement='right' overlay={<div style={{width: 100, height: 30}}><MessageStatusDetail key={order.smsId} order={order} /></div>}><Button variant={order.smsStatus == 0 ? 'outline-success' : 'outline-danger'} size='sm'>{SmsService.ERRORS[order.smsStatus ? parseInt(order.smsStatus) : 'isNull'] ? SmsService.ERRORS[order.smsStatus ? parseInt(order.smsStatus) : 'isNull'].definition : 'Unknown'}</Button></OverlayTrigger> : '-'}
                                    </td>
                                    <td>
                                        <Button style={{marginRight: 5, marginBottom: 5}} onClick={() => this.viewOrder(order)} variant='outline-primary' size='sm'>View</Button>
                                        <Button style={{marginRight: 5, marginBottom: 5}} onClick={() => this.viewSendInvoice(order)} variant='outline-info' size='sm'>Send Invoice</Button>
                                        <Button onClick={() => this.cancelOrder(order)} variant='outline-danger' size='sm'>Cancel</Button>
                                    </td>
                                </tr>
                            )
                        })}
                        <tr style={{borderStyle: 'none', background: 'transparent'}}>
                            <td style={{borderStyle: 'none', background: 'transparent'}}></td>
                        </tr>
                        {this.state.orders && this.cancelled > 0 && this.state.orders.length > 0 && <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>Subtotal</td>
                            <td style={{fontWeight: 'bold'}}>R{(this.total / 100).toFixed(2)}</td>
                            <td></td>
                            <td></td>
                        </tr>}
                        {this.state.orders && this.cancelled > 0 && this.state.orders.length > 0 && <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>Cancelled</td>
                            <td style={{fontWeight: 'bold'}}>- R{(this.cancelled / 100).toFixed(2)}</td>
                            <td></td>
                            <td></td>
                        </tr>}
                        {this.state.orders && this.state.orders.length > 0 && <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>Total</td>
                            <td style={{fontWeight: 'bold'}}>R{((this.total - this.cancelled) / 100).toFixed(2)}</td>
                            <td></td>
                            <td></td>
                        </tr>}
                    </tbody>
                </Table>
                {this.state.orders && this.state.orders.length === 0 && <div style={{textAlign: 'center'}}>No orders to display</div>}
                {this.state.orders == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                {this.state.showEmployees && <ViewEmployeesModal order={this.state.currentOrder} onHide={this.onHide} />}
                </Card.Body>
                
                
            </Card>
        )
    }
}