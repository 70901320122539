import React from 'react'
import { Form, Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import {ExpensesService} from '../../../services/expenses-service';
import { ClipLoader } from 'react-spinners'

export class AddTypeModal extends React.Component {
    state = {
        saving: false,
        selectedColor: null
    }

    constructor(props) {
        super(props)

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
        }
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        this.setState({
            saving: true
        })

        if (this.props.type) {
            ExpensesService.editType(this.props.type.id, values).then(type => {
                if (this.props.onTypeEdited) {
                    this.props.onTypeEdited(type)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        } else {
            ExpensesService.addType(values).then(type => {
                if (this.props.onTypeAdded) {
                    this.props.onTypeAdded(type)
                }
                this.onHide()
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        }
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.type ? {
                    name: this.props.type.name,
                    createdAt: this.props.type.createdAt,
                    id: this.props.type.id
                } : {
                    name: ''
                }}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Type</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='name'>
                                        <Form.Label>Type Name</Form.Label>
                                        <Form.Control value={values.name} onBlur={handleBlur} onChange={handleChange} type='text' />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm}>
                                    <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}