import firebase from 'firebase'
import { AuthService } from './auth.service';

export class BranchService {
    registerBranch(branchId, branch) {
        return firebase.database().ref(`branches/${branchId}`).set(branch).then(ref => {
            const uid = AuthService.user().uid

            if (uid) {
                return firebase.database().ref(`employees/${uid}`).update({
                    branch: branchId
                })
            } else {
                return
            }
        })
    }

    add(branchId, branch) {
        return firebase.database().ref(`branches/${branchId}`).set(branch).then((ref) => {
            return {
                branch
            }
        })
    }

    update(branchId, branch) {
        return firebase.database().ref(`branches/${branchId}`).update(branch).then((ref) => {
            return branch
        })
    }

    static branchExists(branchId) {
        return firebase.database().ref(`branches/${branchId}`).once('value').then(snapshot => {
            return !!snapshot.val()
        })
    }

    static setupFirstBranch(branchId, branch, logoFile) {
        const uid = AuthService.user().uid

        return firebase.database().ref(`branches/${branchId}`).set(branch).then(() => {
            return firebase.storage().ref(`logos/${branch.organization}`).put(logoFile).then(snapshot => {
                return snapshot.ref.getDownloadURL().then(url => {
                    return firebase.database().ref(`organizations/${branch.organization}/logo`).set(url).then(() => {
                        return firebase.database().ref(`employees/${uid}/branch`).set(branchId)
                    })
                })
            })
        })
    }

    static branch() {
        return AuthService.employee().then(employee => {
            return firebase.database().ref(`branches/${employee.branch}`).once('value').then(snapshot => {
                return snapshot.val()
            })
        })
    }

    static updateBranch(branchValues) {
        return AuthService.employee().then(employee => {
            return firebase.database().ref(`branches/${employee.branch}`).update(branchValues)
        })
    }

    static businessInfo() {
        return AuthService.employee().then(employee => {
            return firebase.database().ref(`organizations/${employee.organization}`).once('value').then(snapshot => {
                const orgInfo = snapshot.val()
                return {
                    currentBranch: employee.branch,
                    organization: employee.organization,
                    businessType: orgInfo.type,
                    orgInfo: orgInfo
                }
            })
        })
    }
}

export default BranchService