import React, { Component } from 'react'
import { Route } from "react-router-dom";
import Dashboard from '../pages/dashboard';
import Merchants from '../pages/merchants';
import Admin from '../pages/admin';
import SaveMerchant from '../pages/save-merchant';
import Branches from '../pages/branches';
import SaveBranch from '../pages/save-branch';
// import Users from '../pages/users';
import { Orders } from '../pages/orders';
// import { Container } from 'react-bootstrap';
import { Products } from '../pages/products';
import { MerchantUsers } from '../pages/merchant-users';
import { Discounts } from '../pages/discounts';
// import { Employees } from '../pages/employees/employees';
import { Categories } from '../pages/categories/categories';
import { Customers } from '../pages/customers/customers';
import { Expenses } from '../pages/expenses/expenses';
import { Types } from '../pages/expenses/types/types';
import { EmployeeManagement } from '../pages/employees/employee-management';
import {CreateBranch} from '../pages/onboarding/create-branch';
import { Ratings } from '../pages/ratings/ratings';
import { Settings } from '../pages/settings/settings';
import { Payments } from '../pages/payments/payments';
import { Bookings } from '../pages/bookings/bookings';
import { Messages } from '../pages/messages/messages';
import { Stock } from '../pages/stock/stock'
import { StockMovement } from '../pages/stock/movement';
import { AllStockMovement } from '../pages/stock/all-movement';

class Content extends Component {
    render() {
        return (
            <div>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/create" component={CreateBranch} />
                <Route path="/dashboard/merchants" component={Merchants} />
                <Route path="/dashboard/admin/" component={Admin} />
                <Route path="/dashboard/users/" component={MerchantUsers} />
                <Route exact path="/dashboard/products/" component={Products} />
                <Route path="/dashboard/products/categories" component={Categories} />
                <Route path="/dashboard/merchant/:id/edit" component={SaveMerchant} />
                <Route path="/dashboard/merchant/new" component={SaveMerchant} />
                <Route path="/dashboard/merchant/:id/branches" component={Branches} />
                <Route path="/dashboard/merchant/:merchant/branch/new" component={SaveBranch} />
                <Route path="/dashboard/merchant/:merchant/branch/:branch/edit" component={SaveBranch} />
                <Route path="/dashboard/orders" component={Orders} />
                <Route path="/dashboard/payments" component={Payments} />
                <Route path="/dashboard/discounts" component={Discounts} />
                <Route path="/dashboard/employees" component={EmployeeManagement} />
                <Route path="/dashboard/customers" component={Customers} />
                <Route exact path="/dashboard/expenses" component={Expenses} />
                <Route path="/dashboard/expenses/types" component={Types} />
                <Route path="/dashboard/ratings" component={Ratings} />
                <Route path="/dashboard/settings" component={Settings} />
                <Route path="/dashboard/bookings" component={Bookings} />
                <Route path='/dashboard/messages' component={Messages} />
                <Route exact path='/dashboard/stock' component={Stock} />
                <Route exact path='/dashboard/stock-movement' component={AllStockMovement} />
                <Route path='/dashboard/stock/:id' component={StockMovement} />
            </div>
        )
    }
}

export default Content;