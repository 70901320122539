import React from 'react'
import { Formik } from 'formik';
import { Form, Button, Modal } from 'react-bootstrap';
import { ProductsService } from '../../services/products.service'
import { CategoriesService } from '../../services/category-service'
import { ClipLoader } from 'react-spinners'

export class AddProductModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            product: this.props.product ? {
                ...this.props.product,
                price: this.props.product.price / 100
            } : {
                name: '',
                description: '',
                price: 0,
                category: 'generic'
            },
            saving: false,
            categories: []
        }
        console.log(this.props)

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        CategoriesService.categories().then(categories => {
            this.setState({
                categories: categories
            })
        })
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
            this.setState({
                product: null
            })
        }
    }

    componentDidUpdate() {
        
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        values = Object.assign({}, values)
        values['price'] = Math.round(values['price'] * 100)
        values['cost'] = Math.round(values['cost'] * 100)
        console.log(values)

        switch (values['visibility']) {
            case 'instore':
                values['showOnline'] = false
                values['showInstore'] = true
                break
            case 'online':
                values['showOnline'] = true
                values['showInstore'] = false
                break
            case 'instore-online':
                values['showOnline'] = true
                values['showInstore'] = true
                break
            case 'hidden':
                values['showOnline'] = false
                values['showInstore'] = false
                break
            default:
                values['showOnline'] = false
                values['showInstore'] = true
        }

        this.setState({
            saving: true
        })
        if (this.props.product) {
            ProductsService.edit(this.props.product.id, values).then(product => {
                if (this.props.onProductEdited) {
                    this.props.onProductEdited(product)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        } else {
            ProductsService.add(values).then(product => {
                if (this.props.onProductAdded) {
                    this.props.onProductAdded(product)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        }
        
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.product ? {
                    ...this.props.product,
                    price: this.props.product.price / 100,
                    cost: this.props.product.cost / 100
                } : {
                    name: '',
                    description: '',
                    price: 0
                }}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>{this.props.product ? 'Edit' : 'Add'} Product</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='name'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control value={values.name} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter product name' />
                                    </Form.Group>
                                    <Form.Group controlId='description'>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control value={values.description} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter product description' />
                                    </Form.Group>
                                    <Form.Group controlId='category'>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Control value={values.category} onBlur={handleBlur} onChange={handleChange} as='select'>
                                            <option value='generic'>Generic</option>
                                            {this.state.categories.map(category => {
                                                return (
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='price'>
                                        <Form.Label>Selling Price</Form.Label>
                                        <Form.Control value={values.price} onBlur={handleBlur} onChange={handleChange} type='number' min='0' step='0.01' placeholder='Enter product selling price' />
                                    </Form.Group>
                                    <Form.Group controlId='cost'>
                                        <Form.Label>Cost Price</Form.Label>
                                        <Form.Control value={values.cost} onBlur={handleBlur} onChange={handleChange} type='number' min='0' step='0.01' placeholder='Enter product cost price' />
                                    </Form.Group>
                                    <Form.Group controlId="visibility">
                                        <Form.Label>Product Visibility</Form.Label>
                                        <Form.Control value={values.visibility} onBlur={handleBlur} onChange={handleChange} as="select">
                                            <option value='instore'>In Store Only</option>
                                            <option value='online'>Online Only</option>
                                            <option value='instore-online'>Instore &amp; Online</option>
                                            <option value='hidden'>Hidden</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='orderReadySms'>
                                        <Form.Check
                                            type='checkbox'
                                            checked={values.orderReadySms}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Send SMS to customer when order is ready'
                                        />
                                    </Form.Group>
                                    <Form.Group controlId='stockNotify'>
                                        <Form.Check
                                            type='checkbox'
                                            checked={values.stockNotify}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label='Notify me when running out of stock'
                                        />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm} disabled={this.state.saving}>
                                        <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}