import React from 'react'
import { Card, Table, Button, Modal } from 'react-bootstrap';
import { DiscountService } from '../services/discount.service';
import { ClipLoader } from 'react-spinners';
import { AddDiscountModal } from './discounts/add-discount-modal';

export class Discounts extends React.Component {
    state = {
        discounts: null,
        showDelete: false,
        showAddDiscount: false,
        selectedDiscount: null
    }

    constructor(props) {
        super(props)

        // this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.discountAdded = this.discountAdded.bind(this)
        this.discountEdited = this.discountEdited.bind(this)
    }

    componentDidMount() {
        DiscountService.discounts().then(discounts => {
            this.setState({
                discounts: discounts
            })
        })
    }

    handleClose() {
        this.setState({ showDelete: false, selectedDiscount: null });
    }

    handleShow(discount) {
        this.setState({ showDelete: true, selectedDiscount: discount });
    }

    deleteDiscount() {
        for (let i = 0; i < this.state.discounts.length; i++) {
            if (this.state.selectedDiscount.id === this.state.discounts[i].id) {
                this.state.discounts.splice(i, 1)
                this.setState({
                    discounts: this.state.discounts
                })
                break
            }
        }

        DiscountService.remove(this.state.selectedDiscount.id)
        this.handleClose()
    }

    addDiscount() {
        this.setState({
            showAddDiscount: true
        })
    }

    discountAdded(discount) {
        this.state.discounts.push(discount)
        const discounts = this.state.discounts.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        }) 
        this.setState({
            discounts: discounts
        })
    }

    discountEdited(discount) {
        for (let i = 0; i < this.state.discounts.length; i++) {
            if (discount.id === this.state.discounts[i].id) {
                const newDiscounts = this.state.discounts
                newDiscounts[i] = discount
                this.setState({
                    discounts: newDiscounts
                })
                break
            }
        }
    }

    editDiscount(discount) {
        this.setState({
            showAddDiscount: true,
            selectedDiscount: discount
        })
    }

    render() {
        return (
            <div>
                <Card>
                    <Card.Body>
                        <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Discounts</h3><Button size='sm' variant='outline-dark' onClick={() => this.addDiscount()}>Add Discount</Button></div>
                        <hr />
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.discounts && this.state.discounts.map((discount, index) => {
                                    return (
                                        <tr key={discount.id}>
                                            <td>{index + 1}</td>
                                            <td>{discount.name}</td>
                                            <td style={{textTransform: 'capitalize'}}>{discount.type}</td>
                                            <td>{discount.type === 'amount' && 'R'} {discount.value}{discount.type === 'percentage' && '%'}</td>
                                            <th>
                                                <Button onClick={() => this.editDiscount(discount)} variant='outline-primary' size='sm' style={{marginRight: '5px'}}>Edit</Button>
                                                <Button variant='outline-danger' size='sm' onClick={() => {this.handleShow(discount)}}>Delete</Button>
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {this.state.discounts && this.state.discounts.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any discounts yet</div>}
                        {this.state.discounts == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                    </Card.Body>
                </Card>

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the discount '{this.state.selectedDiscount && this.state.selectedDiscount.name} - {this.state.selectedDiscount && this.state.selectedDiscount.description}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteDiscount()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddDiscountModal discount={this.state.selectedDiscount} onDiscountEdited={this.discountEdited} onDiscountAdded={this.discountAdded} show={this.state.showAddDiscount} onHide={() => {
                    this.setState({
                        showAddDiscount: false,
                        selectedDiscount: null
                    })
                }} />
            </div>
        )
    }
}