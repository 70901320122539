import React from 'react'
import IntroIcon from '@material-ui/icons/LocalPostOffice'
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory'
import ProductsIcon from '@material-ui/icons/ShoppingCart'
import PaymentIcon from '@material-ui/icons/Payment'
import LocationIcon from '@material-ui/icons/Place'
import PictureIcon from '@material-ui/icons/InsertPhoto'
import { BranchInfo } from './branch-info';
import { BranchAddress } from './branch-address';
import { BranchPhoto } from './branch-photo';
import { AuthService } from '../../services/auth.service';
import BranchService from '../../services/branch-service';
import { ClipLoader } from 'react-spinners';
import {Redirect} from 'react-router-dom'
// import '../../css/create-branch.css'

function Intro() {
    return (
        <div>
            <p style={{marginTop: 40, textAlign: 'center'}}>Welcome! You are a few steps from creating a <span style={{fontWeight: 'bold'}}>relationship</span> with your customers while collecting valuable <span style={{fontWeight: 'bold'}}>business insights</span> to help you make decisions to improve your business.</p>
            <p style={{textAlign: 'center'}}>All you need to do now is setup your first branch.</p>
        </div>
    )
}

function Line(props) {
    const focused = props.focused

    return <div style={{flex: 1, height: focused ? 3 : 1, background: 'black'}}></div>
}

function StepIcon(props) {
    const {icon, focused} = props

    return (
        <div style={{
            width: '60px',
            height: '60px',
            borderRadius: '40px',
            borderColor: 'black',
            background: focused ? 'black' : 'white',
            borderWidth: '2px',
            borderStyle: 'solid',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {icon}
        </div>
    )
}

export class CreateBranch extends React.Component {
    state = {
        position: 0,
        merchant: null,
        validFile: true,
        formValues: {
            name: '',
            key: ''
        },
        loading: true,
        addedBranch: false
    }
    submitInfoForm
    submitAddressForm

    constructor(props) {
        super(props)

        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
        this.bindInfoForm = this.bindInfoForm.bind(this)
        this.bindAddressForm = this.bindAddressForm.bind(this)
        this.submitted = this.submitted.bind(this)
        this.createBranch = this.createBranch.bind(this)
        this.photoSelected = this.photoSelected.bind(this)
    }

    createBranch() {
        if (this.state.formValues.photoFile) {
            const values = this.state.formValues
            const logo = values.photoFile
            const branchId = values.key
            delete values.key
            delete values.photoFile

            values['organization'] = this.state.merchant

            this.setState({
                validFile: true,
                loading: true
            })

            BranchService.setupFirstBranch(branchId, values, logo).then(() => {
                console.log('Branch created')
                this.setState({
                    addedBranch: true
                })
            }).catch(err => {
                console.log(err)
            })
            console.log(this.state)
        } else {
            this.setState({
                validFile: false
            })
        }        
    }

    componentDidMount() {
        const subscription = AuthService.loggedIn().subscribe(user => {
            AuthService.employee(user.uid).then(employee => {
                console.log(employee)
                this.setState({
                    merchant: employee.organization,
                    branch: employee.branch,
                    loading: false
                })
            })
            subscription.unsubscribe()
        })
    }

    previous() {
        if (this.state.position > 0) {
            this.setState({
                position: this.state.position - 1
            })
        }
    }

    next() {
        switch (this.state.position) {
            case 0:
                break
            case 1:
                this.submitInfoForm()
                return
            case 2:
                this.submitAddressForm()
                return
        }

        if (this.state.position < 3) {
            this.setState({
                position: this.state.position + 1
            })
        }
    }

    render() {
        if (this.state.addedBranch) {
            return <Redirect push to="/dashboard" />
        }

        if (this.state.branch) {
            return <Redirect push to="/dashboard" />
        }

        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: 0, right: 0,
            top: 0, bottom: 0, background: '#f9f6f1'}}>
                {/* <div className='row'> */}
                    <div className='col-sm-12 col-md-10 col-lg-8' style={{position: 'relative'}}>
                        <div className='row'>
                            <div className='col-sm-12 col-md-5' style={{height: 500, backgroundImage: 'url(/assets/imgs/cashier.jpg)', backgroundPosition: 'center center', backgroundSize: 'cover'}}></div>
                            <div className='col-sm-12 col-md-7' style={{
                                height: 500, 
                                // background: 'blue',
                                borderColor: '#dfdfdf',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                padding: 40,
                                display: 'flex',
                                alignItems: 'stretch',
                                flexDirection: 'column',
                                background: 'white',
                                paddingBottom: 0
                            }}>

                                <h3 style={{textAlign: 'center', marginBottom: '20px'}}>Setup Branch</h3>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <StepIcon focused={this.state.position >= 0} icon={<IntroIcon style={{color: this.state.position >= 0 ? 'white' : 'black'}} />} />
                                        <Line focused={this.state.position > 0} />
                                        <StepIcon focused={this.state.position >= 1} icon={<StoreMallDirectoryIcon style={{color: this.state.position >= 1 ? 'white' : 'black'}} />} />
                                        <Line focused={this.state.position > 1} />
                                        <StepIcon focused={this.state.position >= 2} icon={<LocationIcon style={{color: this.state.position >= 2 ? 'white' : 'black'}} />} />
                                        <Line focused={this.state.position > 2} />
                                        <StepIcon focused={this.state.position >= 3} icon={<PictureIcon style={{color: this.state.position >= 3 ? 'white' : 'black'}} />} />
                                    </div>
                                </div>

                                <div style={{
                                    overflowY: 'scroll',
                                    marginBottom: 20
                                }}>
                                    {this.state.position === 0 && <Intro />}
                                    {this.state.position === 1 && <BranchInfo values={this.state.formValues} bindForm={this.bindInfoForm} submitted={this.submitted} merchant={this.state.merchant} />}
                                    {this.state.position === 2 && <BranchAddress values={this.state.formValues} bindForm={this.bindAddressForm} submitted={this.submitted} merchant={this.state.merchant} />}
                                    {this.state.position === 3 && <BranchPhoto fileUploaded={this.state.validFile} values={this.state.formValues} photoSelected={this.photoSelected} merchant={this.state.merchant} />}
                                </div>
                                
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    paddingBottom: '20px',
                                    height: '60px'
                                }}>
                                    {this.state.position > 0 && <button onClick={this.previous} className='btn btn-outline-dark' style={{width: 100, borderRadius: 0, position: 'absolute', top: 0, left: 0}}>Previous</button>}
                                    {this.state.position < 3 && <button onClick={this.next} className='btn btn-outline-dark' style={{width: 100, borderRadius: 0, position: 'absolute', top: 0, right: 0}}>Next</button>}
                                    {this.state.position > 2 && <button onClick={this.createBranch} className='btn btn-outline-dark' style={{width: 130, borderRadius: 0, position: 'absolute', top: 0, right: 0}}>Create Branch</button>}
                                </div>
                                
                            </div>
                        </div>
                        {this.state.loading && <div style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            top: 0,
                            right: 0,
                            background: 'rgba(0,0,0,0.7)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <ClipLoader color='white' />
                            <div style={{
                                color: 'white',
                                fontSize: '1.2em',
                                textAlign: 'center',
                                marginTop: 20
                            }}>Please wait</div>
                        </div>}
                    </div>
                {/* </div> */}
            </div>
        )
    }

    submitted(values) {
        const formValues = Object.assign(this.state.formValues, values)
        this.setState({
            position: this.state.position + 1,
            formValues: formValues
        }, () => {
            console.log(this.state.formValues)
        })
    }

    photoSelected(photoFile) {
        this.setState({
            formValues: {
                ...this.state.formValues,
                photoFile: photoFile
            }
        })
    }

    bindInfoForm(submitForm) {
        this.submitInfoForm = submitForm
    }    

    bindAddressForm(submitForm) {
        this.submitAddressForm = submitForm
    }   
}