import React, { useState, useEffect } from 'react'
import { Card, Table, Button, Popover, OverlayTrigger, Form, Row, Col } from 'react-bootstrap'
import { ClipLoader } from 'react-spinners'
import { SmsService } from '../../services/sms-service'
import moment from 'moment'
import Overlay from 'react-bootstrap/Overlay'
import { Formik } from 'formik'

const MessageStatusDetail = ({sms}) => {
    // const [show, setShow] = useState(false)
    const status = SmsService.ERRORS[sms.status ? parseInt(sms.status) : 'isNull']
    const popover = (
        <Popover id={`popover-positioned-${sms.key}`}>
            <Popover.Title>SMS Status</Popover.Title>
            
            <Popover.Content>
                <div style={{minWidth: 200}}>
                    <h6>Definition</h6>
                    <p>{status.definition}</p>
                    <hr/>

                    <h6>Description</h6>
                    <p>{status.description}</p>
                    <hr/>

                    <h6>What to do?</h6>
                    <p>{status.action}</p>
                </div>
            </Popover.Content>
        </Popover>
    )
    

    return popover
}



export const SentSMSs = () => {
    const [sentSMSs, setSentSMSs] = useState(null)

    // const status = SmsService.ERRORS[parseInt(sms.status)]
    

    useEffect(() => {
        const todayString = moment(Date.now()).format('YYYY-MM-DD')
        SmsService.sentSMSs(`${todayString} 00:00:00`, `${todayString} 23:59:59`).then(messages => {
            setSentSMSs(messages)
        })
    }, [])

    const filterSMSs = (from, to) => {
        setSentSMSs(null)
        SmsService.sentSMSs(from, to).then(messages => {
            setSentSMSs(messages)
        })
    }

    return (
        <div>
            <Card>
                    <Card.Body>
                        <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Sent SMSs</h3><Button size='sm' variant='outline-dark'>Start Campaign</Button></div>
                        <hr />

                        <Formik
                            initialValues={{
                                start: moment(new Date()).format('YYYY-MM-DD'),
                                end: moment(new Date()).format('YYYY-MM-DD')
                            }}
                            onSubmit={(values) => filterSMSs(values.start, values.end)}
                        >
                        {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            values
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="start">
                                                <Form.Label column sm="3" style={{ textAlign: 'right' }}>From</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="end">
                                                <Form.Label column sm="3" style={{ textAlign: 'right' }}>To</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="2">
                                            <Form.Group as={Row} controlId="filter">
                                                <Button type='submit'>Filter</Button>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                        </Formik>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Message ID</th>
                                    <th>Order</th>
                                    <th>Cellphone</th>
                                    <th>Status</th>
                                    <th>Date Sent</th>
                                    <th>Last Update</th>
                                    {/* <th></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {sentSMSs && sentSMSs.map((sms, index) => {
                                    return (
                                        <tr key={sms.key}>
                                            <td>{index + 1}</td>
                                            <td>{sms.key}</td>
                                            <td><Button variant='link'>{sms.orderId}</Button></td>
                                            <td>+{sms.number}</td>
                                            <td><OverlayTrigger trigger='click' placement='right' overlay={<div style={{width: 100, height: 30}}><MessageStatusDetail key={sms.key} sms={sms} /></div>}><Button variant={sms.status == 0 ? 'outline-success' : 'outline-danger'} size='sm'>{SmsService.ERRORS[sms.status ? parseInt(sms.status) : 'isNull'] ? SmsService.ERRORS[sms.status ? parseInt(sms.status) : 'isNull'].definition : 'Unknown'}</Button></OverlayTrigger></td>
                                            <td>{moment(sms.sentAt).format('DD-MM-2020 HH:mm:ss')}</td>
                                            <td>{sms.lastUpdateTime}</td>
                                            {/* <th>
                                                <Button onClick={() => this.editDiscount(discount)} variant='outline-primary' size='sm' style={{marginRight: '5px'}}>Edit</Button>
                                                <Button variant='outline-danger' size='sm' onClick={() => {this.handleShow(discount)}}>Delete</Button>
                                            </th> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {sentSMSs && sentSMSs.length === 0 && <div style={{textAlign: 'center'}}>You haven't sent any SMSs yet</div>}
                        {sentSMSs == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                    </Card.Body>
                </Card>
        </div>
    )
}