import firebase from 'firebase'
import { Observable } from 'rxjs';

export class AuthService {
    userData
    static login(email, password) {
        return firebase.auth().signInWithEmailAndPassword(email, password)
    }

    static logout() {
        return firebase.auth().signOut()
    }

    static sendResetPasswordEmail(email) {
        return firebase.auth().sendPasswordResetEmail(email)
    }

    static verifyPasswordResetCode(oob) {
        return firebase.auth().verifyPasswordResetCode(oob)
    }

    static confirmPasswordReset(password, code) {
        return firebase.auth().confirmPasswordReset(code, password)
    }

    static loggedIn() {
        return Observable.create(observer => {
            return firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    return firebase.database().ref(`employees/${user.uid}`).once('value').then(snapshot => {
                        const employee = snapshot.val()
                        employee.uid = user.uid
                        this.userData = employee
                        observer.next(employee)
                    })
                } else {
                    this.userData = user
                    observer.next(user)
                }
                
            })
        })
    }

    static user() {
        if (!firebase.auth().currentUser) {
            return firebase.auth().currentUser
        }

        return this.userData;
    }

    static async employee(uid) {
        let user
        if (uid) {
            user = {uid: uid}
        } else {
            user = AuthService.user()
        }
        
        const employee = (await firebase.database().ref(`employees/${user.uid}`).once('value')).val()

        return {
            id: user.uid,
            ...employee
        }
    }
} 