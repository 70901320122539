import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import {red, blue, green, orange} from '@material-ui/core/colors'
import { Formik } from 'formik'
import moment from 'moment'
import InboxIcon from '@material-ui/icons/ArrowDownward'
import SentIcon from '@material-ui/icons/ArrowUpward'
import FailedIcon from '@material-ui/icons/Error'
import CampaignIcon from '@material-ui/icons/Timeline'
import { SmsService } from '../../services/sms-service'
import { DeliveryChart } from './charts/delivery-pie'
import { DeliveryErrorsChart } from './charts/delivery-errors-pie'
import { DeliveryLineChart } from './charts/delivery-line'

const StatCard = ({icon, color, title, value}) => {
    return (
        <Card>
            <Card.Body style={{display: 'flex'}}>
                <div style={{background: color, 
                    borderRadius: '50%', 
                    width: '40px', 
                    height: '40px', 
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {icon}
                </div>
                <div style={{textAlign: 'right', flex: 1}}>
                    <Card.Subtitle>{title}</Card.Subtitle> 
                    <Card.Title style={{margin: 0}}>{value == null ? '-' : value}</Card.Title> 
                    {/* {prevValue != '-' ? <div style={{
                        color: positive ? 'green' : 'red',
                        fontSize: '0.8em'
                    }}>{upArrow ? <ArrowUpward /> : <ArrowDownward />} <span>{prevValue} ({percentage})</span></div> : <div>-</div>} */}
                </div>
            </Card.Body>
        </Card>
    )
}

export const SMSDashboard = () => {
    const initialStats = {
        delivered: 0,
        notdelivered: 0,
        received: 0,
        campaigns: 0,
        errorCodes: {}
    }
    const [stats, setStats] = useState(initialStats)
    const [days, setDays] = useState([])

    const loadStats = (beginningOfMonth, today) => {
        setStats(initialStats)
        SmsService.smsStats(beginningOfMonth, today).then(days => {
            console.log(days)
            const counts = {
                delivered: 0,
                notdelivered: 0,
                received: 0,
                campaigns: 0,
                errorCodes: {}
            }
            days.forEach(day => {
                counts.delivered += day.delivered
                counts.notdelivered += day.notdelivered
                counts.unknown += day.unknown
                
                if (day.errorCodes) {
                    Object.keys(day.errorCodes).forEach(code => {
                        counts.errorCodes[code] = counts.errorCodes[code] ? counts.errorCodes[code] + 1 : 1
                    })
                }
            })

            setStats(counts)
            setDays(days.map(day => {
                return {
                    ...day,
                    'Not Delivered': day.notdelivered,
                    'Delivered': day.delivered
                }
            }))
        })
    }

    const filter = (start, end) => {
        loadStats(start, end)
    }

    useEffect(() => {
        const beginningOfMonth = moment(Date.now()).startOf('month').format('YYYY-MM-DD')
        const today = moment(Date.now()).format('YYYY-MM-DD')
        loadStats(beginningOfMonth, today)
    }, [])

    return (
        <div>
            <Card>
                <Card.Body>
                    <div style={{ display: 'flex', flexDirection: 'row' }}><h3 style={{flexGrow: 1}}>SMS Dashboard</h3>
                    
                    <Formik
                        initialValues={{
                            start: moment(Date.now()).startOf('month').format('YYYY-MM-DD'),
                            end: moment(new Date()).format('YYYY-MM-DD')
                        }}
                        onSubmit={(values) => {
                            filter(values.start, values.end)
                        }}
                        >
                        {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            values
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="start">
                                                <Form.Label column sm="3" style={{textAlign: 'right'}}>From</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="5">
                                            <Form.Group as={Row} controlId="end">
                                                <Form.Label column sm="3" style={{textAlign: 'right'}}>To</Form.Label>
                                                <Col sm="9">
                                                    <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col sm="2">
                                            <Button type='submit'>Filter</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                        </Formik>
                    </div>

                    <hr />
                    <Row>
                        <Col sm={3}>
                            <StatCard
                                color={green['300']}
                                icon={<SentIcon style={{color: 'white'}} />}
                                title='Delivered'
                                value={stats.delivered} />
                        </Col>
                        <Col sm={3}>
                            <StatCard
                                color={blue['300']}
                                icon={<InboxIcon style={{color: 'white'}} />}
                                title='Inbox'
                                value={stats.received} />
                        </Col>
                        <Col sm={3}>
                            <StatCard
                                color={red['300']}
                                icon={<FailedIcon style={{color: 'white'}} />}
                                title='Not Delivered'
                                value={stats.notdelivered} />
                        </Col>
                        <Col sm={3}>
                            <StatCard
                                color={orange['300']}
                                icon={<CampaignIcon style={{color: 'white'}} />}
                                title='Campaigns'
                                value={stats.campaigns} />
                        </Col>
                    </Row>

                    <Row style={{marginTop: 20}}>
                        <Col sm={12}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>SMS Delivery</Card.Title>
                                    <DeliveryLineChart data={days} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} style={{marginTop: 20}}>
                            <Card>
                                <Card.Body>
                                    <div style={{display: 'flex'}}>
                                        <Card.Title style={{flex: 1}}>Messages</Card.Title>
                                    </div>
                                    <DeliveryChart data={[{
                                        name: 'Delivered',
                                        value: stats.delivered
                                    }, {
                                        name: 'Not Delivered',
                                        value: stats.notdelivered
                                    }]} />
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={6} style={{marginTop: 20}}>
                            <Card>
                                <Card.Body>
                                    <div style={{display: 'flex'}}>
                                        <Card.Title style={{flex: 1}}>Delivery Errors</Card.Title>
                                    </div>
                                    <DeliveryErrorsChart data={Object.keys(stats.errorCodes).map(code => {
                                        return {
                                            name: SmsService.ERRORS[code].definition,
                                            value: stats.errorCodes[code]
                                        }
                                    })} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}