import { AuthService } from "./auth.service";
import firebase from 'firebase'
import { Observable } from "rxjs";

export class EmployeesService {
    static employees() {
        return Observable.create(observer => {
            var subscribe
            AuthService.employee().then(employee => {
                subscribe = firebase.firestore().collection('employees')
                .where('branch', '==', employee.branch)
                .where('active', '==', true)
                .orderBy('firstname').onSnapshot(snapshots => {
                    const employees = snapshots.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })

                    observer.next(employees)
                })
            })
            

            return () => {
                if (subscribe != null) {
                    subscribe()
                }
            }
        })
        
    }

    static async add(employee) {
        const user = await AuthService.employee()

        employee = {
            ...employee,
            branch: user.branch,
            organization: user.organization,
            global: true,
            active: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        }

        const ref = await firebase.firestore().collection('employees').add(employee)

        return {
            ...employee,
            id: ref.id
        }
    }

    static async edit(id, employee) {
        await firebase.firestore().collection('employees').doc(id).update(employee)
        
        return {
            ...employee,
            id: id
        }
    }

    static async remove(id) {
        return await firebase.firestore().collection('employees').doc(id).delete()
    }

    static async employeesByIds(ids) {
        const user = AuthService.user()

        return firebase.database().ref(`employees/${user.uid}`).once('value').then(snapshot => {
            const employee = snapshot.val()

            return Promise.all(ids.map(id => {
                return firebase.firestore().collection(`employees`).doc(id).get().then(snapshot => {
                    if (snapshot.data() == null) {
                        console.log('Non existant employee: ' + id)
                    }
                    return {
                        ...snapshot.data(),
                        id: snapshot.id
                    }
                })
            }))
        })
    }
}