import React, { useEffect, useState } from 'react'
import BranchService from '../../services/branch-service'
import { Card, Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'

export const Online = () => {
    const [branch, setBranch] = useState()

    useEffect(() => {
        BranchService.branch().then(branch => {
            console.log(branch)
            setBranch(branch)
        })
    }, [])

    return (
        <div>
            {branch && (
                <Card>
                    <Card.Body>
                        <Formik>
                            {({
                                values,
                                handleBlur,
                                handleChange
                            }) => {
                                return (
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Form.Group controlId='onlineStore'>
                                                <Form.Check type="checkbox" label="Enable Online Store" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} xs={12}>
                                            <Form.Group controlId='delivery'>
                                                <Form.Check type="checkbox" label="Enable Delivery" />
                                            </Form.Group>
                                        </Col>
                                        
                                        <Col md={12} xs={12}>
                                            <Card style={{padding: 20}}>
                                                <Card.Title>Delivery Options</Card.Title>
                                                <Form.Group controlId='deliverToAddress'>
                                                    <Form.Check type="checkbox" label="Deliver to Address" />
                                                </Form.Group>
                                                <Form.Group controlId='name'>
                                                    <Form.Check type="checkbox" label="Delivery Options" />
                                                </Form.Group>
                                            </Card>
                                        </Col>
                                        
                                        
                                    </Row>
                                )
                            }}
                        </Formik>
                    </Card.Body>
                </Card>
            )}
        </div>
    )
}