import React, { Component } from 'react'
import { Button, Table, Modal, Card } from 'react-bootstrap';
import {ExpensesService} from '../../../services/expenses-service';
import { AddTypeModal } from './add-type-modal'
import { ClipLoader } from 'react-spinners';
import moment from 'moment'

export class Types extends Component {
    constructor() {
        super()
        this.state = {
            types: [],
            loading: true,
            showDelete: false,
            showAddType: false,
            selectedType: null
        }

        this.addType = this.addType.bind(this)
        this.onHide = this.onHide.bind(this)
        this.typeAdded = this.typeAdded.bind(this)
        this.typeEdited = this.typeEdited.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        ExpensesService.types().then(types => {
            this.setState({
                types: types,
                loading: false
            })
        })
    }

    addType() {
        this.setState({
            showAddType: true
        })
    }

    onHide() {
        this.setState({
            showAddType: false,
            selectedType: null
        })
    }

    typeAdded(type) {
        type.createdAt = { seconds: new Date().getTime() / 1000 }
        type.updatedAt = { seconds: new Date().getTime() / 1000 }
        this.state.types.push(type)
        const types = this.state.types.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        })
        this.setState({
            types: types
        })
    }

    editType(type) {
        this.setState({
            showAddType: true,
            selectedType: type
        })
    }

    deleteType() {
        for (let i = 0; i < this.state.types.length; i++) {
            if (this.state.selectedType.id === this.state.types[i].id) {
                this.state.types.splice(i, 1)
                this.setState({
                    types: this.state.types
                })
                break
            }
        }

        ExpensesService.removeType(this.state.selectedType.id)
        this.handleClose()
    }

    typeEdited(type) {
        type.updatedAt = { seconds: new Date().getTime() / 1000 }
        for (let i = 0; i < this.state.types.length; i++) {
            if (type.id === this.state.types[i].id) {
                const newTypes = this.state.types
                newTypes[i] = type
                this.setState({
                    types: newTypes
                })
                break
            }
        }
    }

    handleClose() {
        this.setState({ showDelete: false, selectedType: null });
    }

    handleDelete(type) {
        this.setState({ showDelete: true, selectedType: type });
    }

    render() {
        return (
            <Card>
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Expense Types</h3><Button variant="outline-dark" size="sm" style={{marginLeft: '10px'}} onClick={this.addType}>Add Type</Button></div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Updated At</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.types.map((type, index) => {
                                const createdAt = moment(new Date(type.createdAt.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss')
                                const updatedAt = moment(new Date(type.updatedAt.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss')
                                return (
                                    <tr key={type.id}>
                                        <td>{index + 1}</td>
                                        <td>{type.name}</td>
                                        <td>{updatedAt}</td>
                                        <td>{createdAt}</td>
                                        {/* <td><Link to={`/merchant/${type.organization.key}/branch/${type.branch.key}/edit`}>{type.branch.name}</Link></td> */}
                                        <td>
                                            <Button onClick={() => this.editType(type)} style={{marginRight: '5px'}} variant="outline-primary" size="sm">Edit</Button>
                                            {/* <Button style={{marginRight: '5px'}} variant="outline-secondary" size="sm">Reset Password</Button> */}
                                            <Button variant="outline-danger" size="sm" onClick={() => {this.handleDelete(type)}}>Remove</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>
                {!this.state.loading && this.state.types.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any expense types yet</div>}

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the type '{this.state.selectedType && this.state.selectedType.name}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteType()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddTypeModal type={this.state.selectedType} onTypeEdited={this.typeEdited} onTypeAdded={this.typeAdded} show={this.state.showAddType} onHide={this.onHide} />
                </Card.Body>
            </Card>
        )
    }
}
