import React, { Component } from 'react'
import { Table, Badge, Button, ProgressBar } from 'react-bootstrap';
import MerchantService from '../../services/merchant-service';
import { EmployeeService } from '../../services/employee-service'
import { Link } from 'react-router-dom'
import { AuthService } from '../../services/auth.service';

export class BranchList extends Component {
    merchantService = new MerchantService()

    constructor() {
        super()

        this.state = {
            branches: [],
            merchant: null,
            loading: true
        }
    }

    componentDidMount() {
        AuthService.employee().then(employee => {
            this.merchantService.merchant(employee.organization).then(merchant => {
                console.log(merchant)
                return this.merchantService.branches(employee.organization).then(branches => {
                    this.setState({
                        branches: branches,
                        merchant: merchant,
                        loading: false
                    })
                })
            })
        })        
    }

    render() {
        return (
            <div>
                <h3>Branches</h3>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Average OPD</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.branches.map((branch, index) => {
                            return (
                                <tr key={branch.key}>
                                    <td>{index + 1}</td>
                                    <td>{branch.name}</td>
                                    <td>{branch.address}</td>
                                    <td>0</td>
                                    <td>
                                        <Link to={`/merchant/${this.state.merchantId}/branch/${branch.key}/edit`}><Button style={{marginRight: '5px'}} size="sm" variant="outline-primary">Edit</Button></Link>
                                        <Link to={`/merchant/${this.state.merchantId}/branch/${branch.key}/edit`}><Button style={{marginRight: '5px'}} size="sm" variant="outline-secondary">Users</Button></Link>
                                        <Button size="sm" variant="outline-danger">Deactivate</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {this.state.loading ? <ProgressBar now={100} animated></ProgressBar> : null}
            </div>
        )
    }
}