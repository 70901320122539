import React, { Component } from 'react'
import { OrdersService } from '../../services/orders-service';
import { Table, Form, Card, Row, Col, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';

export class Payments extends Component {
    total = 0
    constructor(props) {
        super(props)

        this.state = {
            orders: null,
            showDelete: false,
            selectedOrder: null
        }

        const today = moment(new Date()).format('YYYY-MM-DD')
        OrdersService.payments(today, today).then(data => {
            console.log(data)
            this.setState({
                orders: data
            })
        })

        this.onHide = this.onHide.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            showDelete: false,
            showViewOrder: false,
            selectedOrder: null
        })
    }

    viewEmployees(order) {
        this.setState({
            showEmployees: true,
            currentOrder: order
        })
    }

    onHide() {
        this.setState({
            showEmployees: false
        })
    }

    filter(data, options) {
        console.log(options)
        // console.log(date.target.value)
        this.setState({
            orders: null
        }, () => {
            OrdersService.payments(data.start, data.end, options).then(data => {
                console.log(data)
                this.setState({
                    orders: data
                })
            })
        })
        
    }

    cancelOrder(order) {
        this.setState({
            selectedOrder: order,
            showDelete: true
        })
    }

    viewOrder(order) {
        this.setState({
            selectedOrder: order,
            showViewOrder: true
        })
    }

    confirmCancel() {
        const date = moment(this.state.selectedOrder.createdAt).format('YYYY-MM-DD')
        const id = this.state.selectedOrder.id.split('-')[3]
        OrdersService.cancel(id, date).then(() => {
            this.state.selectedOrder.cancelled = true
            this.setState({
                selectedOrder: null,
                showDelete: false
            })
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        this.total = 0;
        return (
            <Card>
                
                <Card.Body>
                    <Modal style={{zIndex: 100000}} show={this.state.showViewOrder} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Order {this.state.selectedOrder && this.state.selectedOrder.id.split('-')[3] }</Modal.Title>
                        </Modal.Header>
                        {this.state.selectedOrder && <Modal.Body>
                            <h5>Customer</h5>
                            <p>Name: {this.state.selectedOrder.name}<br />
                            Phone: {this.state.selectedOrder.phone}
                            </p>

                            <h5>Order Info</h5>
                            <p>
                                Order Number: {this.state.selectedOrder.id.split('-')[3]}<br />
                                Date: {moment(this.state.selectedOrder.createdAt).format('YYYY-MM-DD HH:mm:ss')}<br />
                                Description: {this.state.selectedOrder.description}<br />
                                Special Instructions: {this.state.selectedOrder.instructions}<br />
                                Completed: {this.state.selectedOrder.completed ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}<br />
                                Paid: {this.state.selectedOrder.paid ? <span style={{color: 'green'}}>Yes</span> : <span style={{color: 'red'}}>No</span>}<br />
                                Payment Method: {this.state.selectedOrder.paymentType}<br />
                            </p>

                            <h5>Products</h5>
                            <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Discount</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.selectedOrder.products && this.state.selectedOrder.products.map((product, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td style={{maxWidth: 200}}>{product.name + ' ' + product.customDescription}</td>
                                    <td>{product.qty}</td>
                                    <td>R{product.discount.toFixed(2)}</td>
                                    <td>R{(((product.price * product.qty) / 100) - product.discount).toFixed(2)}</td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>R{(this.state.selectedOrder.price / 100).toFixed(2)}</td>
                        </tr>
                    </tbody>
                                
                            </Table>
                        </Modal.Body>}
                    </Modal>

                    <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to cancel the order '{this.state.selectedOrder && this.state.selectedOrder.order}'?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Nevermind
                            </Button>
                            <Button variant="primary" onClick={() => this.confirmCancel()}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <h3 style={{flexGrow: 1}}>Payments</h3>
                        <Formik 
                            initialValues={{
                                start: moment(new Date()).format('YYYY-MM-DD'),
                                end: moment(new Date()).format('YYYY-MM-DD'),
                                notPaid: null,
                                notCollected: null
                            }}
                            onSubmit={(values) => this.filter(values, {notPaid: values.notPaid, notCollected: values.notCollected})}
                            >
                            {({
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                values
                            }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="start">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>From</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.start} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="5">
                                                <Form.Group as={Row} controlId="end">
                                                    <Form.Label column sm="3" style={{textAlign: 'right'}}>To</Form.Label>
                                                    <Col sm="9">
                                                        <Form.Control onBlur={handleBlur} value={values.end} onChange={handleChange} type="date" />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="2">
                                                <Button type='submit'>Filter</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                        
                    </div>

                    <hr />

                    <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Order</th>
                            <th>Name</th>
                            <th>Transaction</th>
                            <th>Order Date</th>
                            <th>Payment Date</th>
                            <th>Amount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orders && this.state.orders.map((order, index) => {
                            this.total += order.price;
                            order.price = order.price ? order.price : 0
                            const startDate = moment(new Date(order.createdAt))
                            const endDate = moment(new Date(order.finishedAt))
                            const diff = endDate.diff(startDate)
                            const duration = order.duration === -1 ? '-' : moment.utc(diff).format('HH:mm:ss')

                            
                            return (
                                <tr key={order.id}>
                                    <td>{index + 1}</td>
                                    <td>{order.order}</td>
                                    <td>{order.description}</td>
                                    <td style={{
                                        color: !!order.cancelled == true ? 'red' : (order.paymentType === 'card' ? 'blue' : 'green'),
                                        textTransform: 'capitalize'
                                    }}>{!!order.cancelled == true ? 'CANCELLED' : order.paymentType}</td>
                                    <td>{moment(new Date(order.createdAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>{moment(new Date(order.paidAt)).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>R{(order.price / 100).toFixed(2)}</td>
                                    <td>
                                        <Button style={{marginRight: 5}} onClick={() => this.viewOrder(order)} variant='outline-primary' size='sm'>View</Button>
                                        {/* <Button onClick={() => this.cancelOrder(order)} variant='outline-danger' size='sm'>Cancel</Button> */}
                                    </td>
                                </tr>
                            )
                        })}
                        {this.state.orders && this.state.orders.length > 0 && <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{fontWeight: 'bold'}}>R{(this.total / 100).toFixed(2)}</td>
                        </tr>}
                    </tbody>
                </Table>
                {this.state.orders && this.state.orders.length === 0 && <div style={{textAlign: 'center'}}>No payments to display</div>}
                {this.state.orders == null && <div style={{justifyContent: 'center', display: 'flex'}}><ClipLoader /></div>}
                </Card.Body>
                
                
            </Card>
        )
    }
}