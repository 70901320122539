import { AuthService } from "./auth.service";
import firebase from 'firebase'
import Axios from "axios";

export class SubscriptionService {
    static FREE = 'free'
    static STANDARD = 'standard'
    static ADVANCED = 'advanced'

    static currentSubscription() {
        return AuthService.employee().then(employee => {
            return firebase.firestore().collection(`subscriptions`)
                .where('organization', '==', employee.organization)
                .orderBy('createdAt')
                .limit(1)
                .get().then(snapshot => {
                    return snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                }).then(subscriptions => {
                    return subscriptions[0]
                })
        })
    }

    static generateSubscription() {
        return AuthService.employee().then(employee => {
            const obj = {
                merchant_id: '11612308',
                merchant_key: '1q8b0zoyh0b38',
                return_url: 'https://www.waitr.co.za/settings',
                cancel_url: 'ttps://www.waitr.co.za/settings',
                notify_url: 'ttps://www.waitr.co.za/settings',

                name_first: employee.name,
                email_address: employee.username,

                amount: 0,
                item_name: 'Waitr Subscription',
                item_description: 'Advanced',

                email_confirmation: 1,
                email_address: employee.username,

                subscription_type: 1,
                recurring_amount: 899,
                frequency: 3
            }

            return Axios.post('https://sandbox.payfast.co.za/eng/process', obj, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(result => {
                return result.data
            })
        })
    }

    static subscriptions() {

    }
}