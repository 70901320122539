import React from 'react'
import { Form, Modal, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import {CategoriesService} from '../../services/category-service';
import { ClipLoader } from 'react-spinners'
import TickIcon from '@material-ui/icons/Done'

export class AddCategoryModal extends React.Component {
    state = {
        saving: false,
        selectedColor: null
    }
    colors = [
        '#F44336',
        '#E91E63',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#2196F3',
        '#03A9F4',
        '#00BCD4',
        '#009688',
        '#4CAF50',
        '#FF5722',
        '#795548',
        '#9E9E9E',
        '#607D8B',
        '#1E1E1E'
    ]

    constructor(props) {
        super(props)

        this.handleClose = this.handleClose.bind(this)
        this.onHide = this.onHide.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide()
        }
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    onSubmit(values) {
        values = Object.assign({}, values)

        switch (values['visibility']) {
            case 'instore':
                values['showOnline'] = false
                values['showInstore'] = true
                break
            case 'online':
                values['showOnline'] = true
                values['showInstore'] = false
                break
            case 'instore-online':
                values['showOnline'] = true
                values['showInstore'] = true
                break
            case 'hidden':
                values['showOnline'] = false
                values['showInstore'] = false
                break
            default:
                values['showOnline'] = false
                values['showInstore'] = true
        }

        if (values.name && values.name.length > 0 && this.state.selectedColor) {
            console.log('Saving')
        
        this.setState({
            saving: true
        })
        values['color'] = this.state.selectedColor

        if (this.props.category) {
            CategoriesService.edit(this.props.category.id, values).then(category => {
                if (this.props.onCategoryEdited) {
                    this.props.onCategoryEdited(category)
                    this.onHide()
                }
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        } else {
            CategoriesService.add(values).then(category => {
                if (this.props.onCategoryAdded) {
                    this.props.onCategoryAdded(category)
                }
                this.onHide()
            }).finally(() => {
                this.setState({
                    saving: false
                })
            })
        }
    }
        
    }

    render() {
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.category ? {
                    name: this.props.category.name,
                    createdAt: this.props.category.createdAt,
                    id: this.props.category.id,
                    visibility: this.props.category.visibility
                } : {
                    name: ''
                }}
                onSubmit={this.onSubmit}
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal show={this.props.show} onHide={this.onHide} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId='name'>
                                        <Form.Label>Category Name</Form.Label>
                                        <Form.Control value={values.name} onBlur={handleBlur} onChange={handleChange} type='text' />
                                    </Form.Group>
                                    <Form.Group controlId="visibility">
                                        <Form.Label>Category Visibility</Form.Label>
                                        <Form.Control value={values.visibility} onBlur={handleBlur} onChange={handleChange} as="select">
                                            <option value='instore'>In Store Only</option>
                                            <option value='online'>Online Only</option>
                                            <option value='instore-online'>Instore &amp; Online</option>
                                            <option value='hidden'>Hidden</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <div>Category Color</div>
                                    <div style={{marginTop: 10, display: 'flex', flexWrap: 'wrap'}}>
                                        {this.colors.map((color, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    this.setState({
                                                        selectedColor: color
                                                    })
                                                }} style={{width: 60, 
                                                    height: 60, 
                                                    backgroundColor: color, 
                                                    borderRadius: 10, 
                                                    marginRight: 5, 
                                                    marginBottom: 5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    {this.state.selectedColor === null && this.props.category && this.props.category.color === color && <TickIcon style={{color: 'white'}} />}
                                                    {this.state.selectedColor === color && <TickIcon style={{color: 'white'}} />}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {/* {!this.props.category && <Form.Group controlId='password'>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control value={values.password} onBlur={handleBlur} onChange={handleChange} type='password' />
                                    </Form.Group>} */}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.onHide} disabled={this.state.saving}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type='submit' onClick={submitForm}>
                                    <span style={{marginRight: this.state.saving ? '10px' : '0px'}}>Save</span>{this.state.saving && <ClipLoader size={20} />}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                        
                    )
                }}
            </Formik>
        )
    }
}