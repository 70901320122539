import firebase from 'firebase';
import { EmployeesService } from './employee-service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import moment from 'moment'

export class CheckinService{

    static checkins(date) {
        return Observable.create(observer => {
            var ref
            AuthService.employee().then(employee => {
                ref = firebase.database().ref( `checkins/${employee.organization}/${date}`)
                ref.on('value', snapshot => {
                    observer.next(snapshot.val())
                })
            })
            
            return () => {
                ref.off()
            }
        })

        
    }    

    static async checkin(employeeId, date) {
        const today = moment().format('YYYY-MM-DD')
        const loggedInEmployee = await AuthService.employee()

        console.log(`checkins/${loggedInEmployee.branch}/${today}/${employeeId}`)
        console.log(date)
        if(!date){            
            const data = await firebase.database().ref( `checkins/${loggedInEmployee.branch}/${today}/${employeeId}`).set({
                checkin: firebase.database.ServerValue.TIMESTAMP,
            })
            console.log(data)
        }else{
            return await firebase.database().ref( `checkins/${loggedInEmployee.branch}/${today}/${employeeId}`).set({
                checkin: date,
            });
        }

    }

    static checkout(org, employeeId, date) {
        const today =  moment().format('YYYY-MM-DD')
        if(!date){            
            firebase.database().ref( `checkins/${org}/${today}/${employeeId}`).update({
                checkout: firebase.database.ServerValue.TIMESTAMP,
            });
        }else{
            firebase.database().ref( `checkins/${org}/${today}/${employeeId}`).update({
                checkout: date,
            });
        }

    }

    static async report(startDate, endDate) {
        const employee = await AuthService.employee()
        const org = employee.branch

        return await firebase.database().ref(`checkins/${org}`)
            .orderByKey()
            .startAt(startDate)
            .endAt(endDate)
            .once('value')
            .then(snapshot => {
                const obj = snapshot.val()
                // const keys = Object.keys(obj ? obj : {})

                return obj;
                // return keys.map(key => {
                //     return {
                //         ...obj[key],
                //         id: key
                //     }
                // })
            })
    }
}
