import { AuthService } from "./auth.service";
import firebase from 'firebase'

export class ExpensesService {
    static async expenses(start, end) {
        const employee = await AuthService.employee()

        return await firebase.firestore().collection(`expenses`)
            .where('branch', '==', employee.branch)
            .where('date', '>=', start)
            .where('date', '<=', end)
            .orderBy('date', 'desc').get().then(snapshots => {
            return snapshots.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                }
            })
        })
    }

    static async types() {
        const employee = await AuthService.employee()

        return await firebase.firestore().collection(`expenseTypes`).where('organization', '==', employee.organization)
            .orderBy('name', 'asc').get().then(snapshots => {
            return snapshots.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                }
            })
        })
    }

    static async expenseTypes() {
        const employee = await AuthService.employee()

        return await firebase.firestore().collection(`expenseTypes`).where('organization', '==', employee.organization).get().then(snapshots => {
            return snapshots.docs.map(doc => {
                return {
                    ...doc.data(),
                    id: doc.id
                }
            })
        })
    }

    static async editType(id, type) {
        const user = await AuthService.user()

        await firebase.firestore().collection(`expenseTypes`).doc(id).update({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...type
        })
        await firebase.firestore().collection(`expenseTypes`).doc(id).collection('activity').add({
            uid: user.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            note: 'Edited expense type'
        });

        return {
            ...type,
            id: id
        }
    }

    static async addType(type) {
        const user = await AuthService.user()
        const employee = await AuthService.employee()

        const ref = await firebase.firestore().collection(`expenseTypes`).add({
            organization: employee.organization,
            branch: employee.branch,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...type
        })
        await ref.collection('activity').add({
            uid: user.uid,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            note: 'Added type'
        });

        return {
            ...type,
            id: ref.id
        }
    }

    static async edit(id, expense) {
        const user = await AuthService.user()

        await firebase.firestore().collection(`expenses`).doc(id).update({
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...expense
        })
        await firebase.firestore().collection(`expenses`).doc(id).collection('activity').add({
            uid: user.uid,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            note: 'Edited expense'
        });

        return {
            ...expense,
            id: id
        }
    }

    static async add(expense) {
        const user = await AuthService.user()
        const employee = await AuthService.employee()

        const ref = await firebase.firestore().collection(`expenses`).add({
            organization: employee.organization,
            branch: employee.branch,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...expense
        })
        await ref.collection('activity').add({
            uid: user.uid,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            note: 'Added expense'
        });

        return {
            ...expense,
            id: ref.id
        }
    }

    static async removeType(id) {
        return await firebase.firestore().collection('expenseTypes').doc(id).delete()
    }

    static async remove(id) {
        return await firebase.firestore().collection('expenses').doc(id).delete()
    }
}