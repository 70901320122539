import React from 'react'
import { EmployeesService } from '../../services/employee-service';
import { ClipLoader } from 'react-spinners';
import { Dropdown, Table, Card, Button, Form, Modal } from 'react-bootstrap';
import { CheckinService} from '../../services/checkin.services';
import { Formik } from "formik";
import { flatMap, map } from 'rxjs/operators';
import moment from 'moment'

export class CheckOuts extends React.Component {
    subscription
   
    state = {
        employees: [],
        loading: true,
    }

    constructor(props) {
        super(props)

        this.toggleEmployees = this.toggleEmployees.bind(this)
    }

    selectedEmployees() {
        for (let i = 0; i < this.state.employees.length; i++) {
            if (this.state.employees[i].selected) {
                return true
            }
        }

        return false
    }

    toggleEmployee(employee, data) {
        const select = data.target.checked
        const employees = this.state.employees
        const index = employees.indexOf(employee)
        
        employee['selected'] = select
        employees[index] = employee

        this.setState({
            employees: employees
        })
    }

    toggleEmployees(data) {
        const select = data.target.checked
     

        const employees = this.state.employees.map(employee => { 
            return {
                ...employee,
                selected: employee.checkOut == null && employee.checkIn ? select : false
            }
        });

        console.log(employees);
        

        this.setState({
            employees: employees
        });
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    
    componentWillUnmount() {
        this.subscription.unsubscribe()
    }

    componentDidMount() {
        this.subscription = EmployeesService.employees().pipe(flatMap(employees => {
            const today = moment().format('YYYY-MM-DD')
            return CheckinService.checkins(today).pipe(map(checkins => {
                if (checkins == null) {
                    checkins = {}
                }
                
                return employees.map(employee => {
                    return {
                        ...employee,
                        checkIn: checkins[employee.id] ? checkins[employee.id].checkin : null,
                        checkOut: checkins[employee.id] ? checkins[employee.id].checkout : null,
                    }
                })    
            }))
        })).subscribe(employees => {
            this.setState({
                employees: employees,
                loading: false
            })
        });

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setState({
            show: false
        })
    }

    checkout(employee, index){
        CheckinService.checkout(employee.organization, employee.id);
    }

    timePicker(){
        return (
            <Modal show={this.state.show} onHide={this.handleClose} centered  size="sm">
            <Modal.Header closeButton>
              <Modal.Title>Provide check out time</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik

                    initialValues={{
                            time: "",
                    }}
                    validate={values => {
                    let errors = {};

                    if (!values.time) {
                        errors.time = "Field Required *";
                    }
                    
                    return errors;
                    }}
                    
                    onSubmit={(values, { setSubmitting }) => {
                            
                            var time =  new Date().toJSON().slice(0,10) + " " + values.time + ":00";

                            if(this.state.rowIndex === -1){
                                var employees = this.state.activeRow;

                                employees.map((employee) => {
                                    CheckinService.checkout(emp.organization, emp.id, new Date(time).getTime());
                                });    

                            }else{
                                var emp = this.state.activeRow;
                                CheckinService.checkout(emp.organization, emp.id, new Date(time).getTime());
                            }
                           
                            
                            this.handleClose();

                        }
                    }
                >

            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
                }) => (
                <div>
                    <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                        type="time"
                        name="time"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.time}
                        />
                        <Form.Text className="text-muted">
                        {errors.time &&
                            touched.time &&
                            errors.time}
                        </Form.Text>
                    </Form.Group>

                    

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>

                        <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="primary"
                        >
                            Check out
                        </Button>
                    </Modal.Footer>
                    </Form>
                </div>
                )}
                </Formik>
                
            </Modal.Body>
          </Modal>
        );
    }

    render() {
        return (
            
            <Card>
                {this.timePicker()}
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Check Outs</h3>
                <Dropdown>
                    <Dropdown.Toggle disabled={!this.selectedEmployees()} variant='outline-dark' size='sm' id="dropdown-basic">
                    Check Out
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onSelect = {() =>{
                        this.state.employees.map((employee, index) => {
                            if(employee.selected)
                                this.checkout(employee, index);
                        });
                    }}>Now</Dropdown.Item>
                    <Dropdown.Item onSelect = {() =>{
                        var employees = [];
                        this.handleShow();
                        this.state.employees.map((employee, index) => {
                            if(employee.selected)
                                employees.push(employee);
                        });
                        this.setState({
                            activeRow : employees,
                            rowIndex : -1
                        });
            
                    }}>Select Time</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </div>
                
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th><Form.Check onChange={this.toggleEmployees} type="checkbox" /></th>
                            <th>Full names</th>
                            <th>Check out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.employees.map((employee, index) => {
                                var checkOutTime
                                var hours, min
                                if (employee.checkIn) {
                                    var checkInTime = moment(new Date(employee.checkIn))
                                    var checkOutTimeMoment = moment(new Date(employee.checkOut))
                                    checkOutTime = checkOutTimeMoment.format('HH:mm')

                                    // hours = checkOutTimeMoment.diff(checkInTime, 'hours')
                                    min = checkOutTimeMoment.diff(checkInTime, 'minutes')
                                    hours = Math.floor(min / 60)
                                    min = min - (hours * 60)
                                }
                                
                                return (
                                    <tr key={employee.id}>
                                        <td>
                                            <Form.Check defaultChecked={employee.selected} value={employee.selected} onChange={(data) => { this.toggleEmployee(employee, data)} } disabled={!!employee.checkedIn} type="checkbox" />
                                        </td>
                                        <td>{employee.firstname + " " + employee.lastname}</td>
                                        {/* <td><Link to={`/merchant/${employee.organization.key}/branch/${employee.branch.key}/edit`}>{employee.branch.name}</Link></td> */}
                                        <td>
                                        {   employee.checkIn ?
                                             (employee.checkOut == null ? <Dropdown>
                                                <Dropdown.Toggle variant='outline-dark' size='sm' id="dropdown-basic">
                                                    Check Out
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onSelect = {() =>{
                    
                                                        this.checkout(employee, index);
                                                    }}>Now</Dropdown.Item>
                                                    <Dropdown.Item onSelect = {() =>{
                                                    //this.checkin(employee);
                                                    this.handleShow();
                                                    this.setState({
                                                        activeRow : employee,
                                                        rowIndex : index
                                                    });

                        

                                            
                                                    }}>Select Time</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown> : <span style={{color: 'green'}}>{checkOutTime} ({hours} hr {min} min)</span>)
                                            : <span style={{color: 'red'}}>Didn't check in</span>
                                        
                                        }
                                       
                                        </td>
                                    </tr>
                                    
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>
                {!this.state.loading && this.state.employees.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any employees yet</div>}

                </Card.Body>
            </Card>
            
        );
    }
}