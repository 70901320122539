import React from 'react'
import { Badge, ProgressBar, Form, Row, Col, Button, Alert, InputGroup, FormControl } from 'react-bootstrap';
import MerchantService from '../../services/merchant-service';
import { Formik } from 'formik';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
// import Map from '../components/Map';
import GoogleMapReact from 'google-map-react';
import * as config from '../../config'
import GeocodingService from '../../services/geocoding-service';
import BranchService from '../../services/branch-service';
import { AuthService } from '../../services/auth.service';
import * as Yup from 'yup';

const AnyReactComponent = ({ text }) => <div style={{
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: 'red',
    borderWidth: '2px',
    borderColor: 'white',
    borderStyle: 'solid'
}}></div>;

export class BranchInfo extends React.Component {
    merchantService = new MerchantService()
    branchService = new BranchService()
    geocodingService = new GeocodingService()

    constructor() {
        super()
        this.state = {
            merchantId: null,
            branchId: null,
            merchant: null,
            saved: false,
            savingError: false,
            branchExists: false
        }
    }

    componentDidMount() {
        const subscription = AuthService.loggedIn().subscribe(user => {
            AuthService.employee(user.uid).then(employee => {
                // console.log(employee)
                this.setState({
                    merchant: employee.organization
                })
            })
            subscription.unsubscribe()
        })
    }

    render() {
        return (
            <div style={{padding: 20}}>
                {/* <h3>{this.state.branchId ? 'Edit' : 'Add'} Branch {this.state.merchant ? <Badge variant="secondary">{this.state.merchant.name}</Badge>: null}</h3> */}
                {/* {this.state.saved ? <Alert variant="success" dismissible>Branch successfully saved</Alert> : null} */}
                {/* {this.state.savingError ? <Alert variant="danger" dismissible>An error has occurred while saving branch</Alert> : null} */}
                {this.renderForm()}
            </div>
        )
    }

    next() {

    }

    submit(values) {
        BranchService.branchExists(values.key).then(exists => {
            this.setState({
                branchExists: exists
            })

            if (exists) {
                
            } else {
                if (this.props.submitted) {
                    this.props.submitted(values)
                }
            }
        })

        // if (this.state.branch) {
        //     promise = this.branchService.update(key, values)
        // } else {
        //     promise = this.branchService.registerBranch(key, values)
        // }

        // promise.then(() => {
        //     this.setState({
        //         saving: false,
        //         saved: true
        //     })
        // }).catch(() => {
        //     this.setState({
        //         saving: false,
        //         savingError: true
        //     })
        // })
    }

    renderForm() {
        const schema = Yup.object().shape({
            name: Yup.string()
              .min(3, 'Merchant name is too short')
              .required('Merchant name is eequired'),
            key: Yup.string()
              .min(3, 'Link name is too short. Cannot be less than 3 characters')
              .max(20, 'Link name is too long. Cannot be more than 20 characters')
              .required('Merchant link is required'),
          });

        const initialValues = {
            ...this.state.branch,
            key: this.state.branchId
        }
        const errorStyle = {color: 'red', fontSize: '0.8em', marginBottom: 5}
        return (
            <Formik
                initialValues={this.props.values ? this.props.values : {
                    name: '',
                    key: ''
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    this.submit(values)
                }}
                >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm,
                    touched,
                    errors
                }) => {
                    if (this.props.bindForm) {
                        this.props.bindForm(submitForm)
                    }
                    
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12}>
                                <Form.Group controlId="key">
                                    <Form.Label>Branch Link</Form.Label>
                                    {errors.key && touched.key ? (
                                        <div style={errorStyle}>{errors.key}</div>
                                    ) : null}
                                    {this.state.branchExists && <div style={errorStyle}>A branch with that link already exists. Please try another link name</div>}
                                    <InputGroup className="mb-3">
                                        <FormControl
                                        placeholder="Branch Link"
                                        aria-describedby="basic-addon2"
                                        value={values.key} onBlur={handleBlur} onChange={handleChange}
                                        />
                                        <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2">.waitr.co.za</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                </Form.Group>
                                
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Branch Name</Form.Label>
                                        {errors.name && touched.name ? (
                                            <div style={errorStyle}>{errors.name}</div>
                                        ) : null}
                                        <Form.Control placeholder="Branch Name" value={values.name} onBlur={handleBlur} onChange={handleChange} type="text"></Form.Control>
                                    </Form.Group>
                                    <input style={{display: 'none'}} value={values.address} onBlur={handleBlur} onChange={handleChange} />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col>
                                <GooglePlacesAutocomplete
                                    initialValue={values.address}
                                    onSelect={(data) => {
                                        this.geocodingService.geocode(data.description).then(coords => {
                                            if (coords) {
                                                values.location = coords
                                                values.location = coords
                                                this.setState(this.state)
                                            }
                                        })
                                        values.address = data.description

                                    }}
                                />
                                </Col>
                            </Row> */}
                            <Row>
                            {/* <div style={{width: '100%', height: '350px', margin: '20px'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: config.default.apiKey }}
                                defaultCenter={values.location ? values.location : {lat: 59.95,
                                    lng: 30.33}}
                                defaultZoom={18}
                                center={values.location ? values.location : null}
                                >
                                {values.location &&
                                    <AnyReactComponent
                                    {...values.location }
                                />}
                                </GoogleMapReact>
                            </div> */}
                            </Row>
                            {/* <Row>
                                <Button style={{margin: '10px'}} type="submit">Save Branch</Button>
                            </Row> */}
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}