export default {
    apiKey: 'AIzaSyA9uopHue7CsDj4Bk_1xPvwLf25gDzhpK0',
    spreadsheetId: '1sCgmzBIq2K9jUckLuYSWbDq4CuNUfdtuE6a5xI3I5Hw',
    firebase: {
        apiKey: "AIzaSyA9uopHue7CsDj4Bk_1xPvwLf25gDzhpK0",
        authDomain: "waitr-d321d.firebaseapp.com",
        databaseURL: "https://waitr-d321d.firebaseio.com",
        projectId: "waitr-d321d",
        storageBucket: "waitr-d321d.appspot.com",
        messagingSenderId: "593224149652"
    },
    // apiUrl: 'http://localhost:5000/waitr-d321d/us-central1/',
    apiUrl: 'https://us-central1-waitr-d321d.cloudfunctions.net/'
}

// export default {
//     apiKey: 'AIzaSyA9uopHue7CsDj4Bk_1xPvwLf25gDzhpK0',
//     spreadsheetId: '1sCgmzBIq2K9jUckLuYSWbDq4CuNUfdtuE6a5xI3I5Hw',
//     firebase: {
//         apiKey: "AIzaSyA9uopHue7CsDj4Bk_1xPvwLf25gDzhpK0",
//         authDomain: "waitr-d321d.firebaseapp.com",
//         databaseURL: "https://waitr-development.firebaseio.com",
//         projectId: "waitr-d321d",
//         storageBucket: "waitr-development",
//         messagingSenderId: "593224149652"
//     },
//     // apiUrl: 'http://localhost:5000/waitr-d321d/us-central1/',
//     apiUrl: 'https://us-central1-waitr-d321d.cloudfunctions.net/'
// }