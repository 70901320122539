import React from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import BranchService from '../../services/branch-service'
import { ClipLoader } from 'react-spinners'

export class General extends React.Component {
    state = {
        branch: null
    }

    componentDidMount() {
        BranchService.branch().then(branch => {
            this.setState({
                branch: branch
            })
        })
    }

    render() {
        return (
            <div>
                {this.state.branch && <Card>
                    <Card.Body>
                        <Formik onSubmit={(data, {
                            setSubmitting
                        }) => {
                            console.log(data)
                            BranchService.updateBranch(data).then(() => {
                                setSubmitting(false)
                            })
                        }} enableReinitialize={true} initialValues={{
                            name: this.state.branch.name,
                            hours: this.state.branch.hours ? this.state.branch.hours : '',
                            terms: this.state.branch.terms ? this.state.branch.terms : '',
                            contacts: this.state.branch.contacts ? this.state.branch.contacts : ''
                        }}>
                            {({
                                handleBlur,
                                handleChange,
                                values,
                                submitForm,
                                isSubmitting
                            }) => {
                                return (
                                    <Row>
                                        <Col md={6} xs={12}>
                                            <Form.Group controlId='name'>
                                                <Form.Label>Branch Name (Appears on receipt)</Form.Label>
                                                <Form.Control disabled={isSubmitting} value={values.name} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter Branch Name' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Form.Group controlId='contacts'>
                                                <Form.Label>Contact Number (Appears on receipt)</Form.Label>
                                                <Form.Control disabled={isSubmitting} value={values.contacts} onBlur={handleBlur} onChange={handleChange} type='text' placeholder='Enter Contact Number' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Form.Group controlId='terms'>
                                                <Form.Label>Terms and Conditions</Form.Label>
                                                <Form.Control disabled={isSubmitting} rows="6" value={values.terms} onBlur={handleBlur} onChange={handleChange} type='text' as={'textarea'} placeholder='Enter Terms &amp; Conditions' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Form.Group controlId='hours'>
                                                <Form.Label>Business Hours</Form.Label>
                                                <Form.Control disabled={isSubmitting} rows="6" value={values.hours} onBlur={handleBlur} onChange={handleChange} type='text' as={'textarea'} placeholder='Enter Business Hours' />
                                            </Form.Group>
                                        </Col>

                                        {/* <Col md={6} xs={12}>
                                            <Form.Group controlId='hours'>
                                                <Form.Label>Search for Waitr Controller</Form.Label>
                                                <Form.Control disabled={isSubmitting} rows="6" value={values.hours} onBlur={handleBlur} onChange={handleChange} type='text' as={'textarea'} placeholder='Enter Business Hours' />
                                            </Form.Group>
                                        </Col> */}

                                        <Button variant="primary" type='submit' onClick={submitForm} disabled={this.state.saving}>
                                            <span style={{marginRight: isSubmitting ? '10px' : '0px'}}>Save</span>{isSubmitting && <ClipLoader size={20} />}
                                        </Button>
                                    </Row>
                                )
                            }}
                        </Formik>
                    </Card.Body>
                </Card>}
            </div>
        )
    }
}