import { AuthService } from "./auth.service";
import firebase from 'firebase'

export class CustomersService {
    static async customers({orderBy, direction, phoneOnly, limit = 20, startAfter, endBefore}) {
        const employee = await AuthService.employee()
        console.log(employee)

        var ref = firebase.firestore().collection(`customers`).where('branches', 'array-contains', employee.branch)

        if (phoneOnly) {
            ref = ref.where('hasPhone', '==', true)
        }

        if (orderBy) {
            console.log(`order by ${orderBy}`)
            ref = ref.orderBy(orderBy, direction) 
        }

        console.log('Start after: ' + startAfter)
        if (startAfter) {
            ref = ref.startAfter(startAfter)
        }

        if (endBefore) {
            ref = ref.endBefore(endBefore)
        }

        if (limit) {
            ref = ref.limit(limit)
        }

        return ref.get().then(snapshots => {
            const customers = snapshots.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            })

            return {
                customers: customers,
                lastVisible: snapshots.docs[snapshots.docs.length - 1],
                firstVisible: snapshots.docs[0]
            }
        }).then(data => {
            const customerIds = data.customers.map(customer => customer.id)
            const points = data.customers.map(customer => {
                return firebase.firestore().doc(`customers/${customer.id}/points/${employee.organization}`).get().then(snapshot => {
                    if (snapshot.data()) {
                        return {
                            ...snapshot.data(),
                            id: customer.id
                        }
                    }

                    return null
                })
            })

            return Promise.all(points).then(points => {
                const result = {}

                for (let i = 0; i < points.length; i++) {
                    if (points[i] != null) {
                        result[points[i].id] = points[i]
                    }
                }

                return result   
            }).then(points => {
                return {
                    ...data,
                    ids: customerIds,
                    points: points
                }
            })
        })
    }

    static deleteCustomer(customer) {
        console.log(customer)
    }

    static fixCustomer(customer) {
        console.log('Fix customer called')
        const digits = '0123456789'
        const phone = customer.phone
        let newPhone = ''

        if (customer.orders == null) {
            customer.orders = []
        }

        if (phone) {
            for (let i = 0; i < phone.length; i++) {
                if (digits.indexOf(phone[i]) != -1) {
                    newPhone += phone[i]
                }
            }

            if (newPhone[0] == '0') {
                newPhone = '+27' + newPhone.substr(1, newPhone.length)
            } else {
                newPhone = '+' + newPhone
            }

            customer.phone = newPhone
        }

        firebase.firestore().collection(`customers`).where('phone', '==', newPhone).get().then(snapshots => {
            console.log(snapshots.docs.length)
            if (!snapshots.empty) {
                snapshots.docs.forEach(doc => {
                    const cust = doc.data()

                    if (cust.phone == customer.phone && cust.id != customer.id) {
                        if (cust.orders) {
                            customer.orders = customer.orders.concat(cust.orders)
                            customer.ordersCount += cust.ordersCount
                        }

                        if (cust.lastOrder > customer.lastOrder) {
                            customer.lastOrder = cust.lastOrder
                        }
                        
                        console.log(customer)
                    }

                    firebase.firestore().collection(`customers`).doc(customer.id).update({
                        ordersCount: customer.ordersCount,
                        phone: customer.phone,
                        orders: customer.orders
                    }).then(() => {
                        return firebase.firestore().collection(`customers`).doc(doc.id).delete()
                    })
                    
                })
            } else {
                firebase.firestore().collection(`customers`).doc(customer.id).update({
                    ordersCount: customer.ordersCount,
                    phone: customer.phone,
                    orders: customer.orders
                })
            }
        })
        

        return customer
    }
}