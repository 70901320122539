import React, { Component } from 'react'
import { Button, Table, Modal, Card } from 'react-bootstrap';
import {CategoriesService} from '../../services/category-service';
import { AddCategoryModal } from './add-category-modal'
import { ClipLoader } from 'react-spinners';
import moment from 'moment'

export class Categories extends Component {
    // categoriesService = new CategoriesService()

    constructor() {
        super()
        this.state = {
            categories: [],
            loading: true,
            showDelete: false,
            showAddCategory: false,
            selectedCategory: null
        }

        this.addCategory = this.addCategory.bind(this)
        this.onHide = this.onHide.bind(this)
        this.categoryAdded = this.categoryAdded.bind(this)
        this.categoryEdited = this.categoryEdited.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
        CategoriesService.categories().then(categories => {
            this.setState({
                categories: categories,
                loading: false
            })
        })
    }

    addCategory() {
        this.setState({
            showAddCategory: true
        })
    }

    onHide() {
        this.setState({
            showAddCategory: false,
            selectedCategory: null
        })
    }

    categoryAdded(category) {
        category.createdAt = { seconds: new Date().getTime() / 1000 }
        category.updatedAt = { seconds: new Date().getTime() / 1000 }
        this.state.categories.push(category)
        const categories = this.state.categories.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else {
                return 1
            }
        })
        this.setState({
            categories: categories
        })
    }

    editCategory(category) {
        this.setState({
            showAddCategory: true,
            selectedCategory: category
        })
    }

    deleteCategory() {
        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.selectedCategory.id === this.state.categories[i].id) {
                this.state.categories.splice(i, 1)
                this.setState({
                    categories: this.state.categories
                })
                break
            }
        }

        CategoriesService.remove(this.state.selectedCategory.id)
        this.handleClose()
    }

    categoryEdited(category) {
        category.updatedAt = { seconds: new Date().getTime() / 1000 }
        for (let i = 0; i < this.state.categories.length; i++) {
            if (category.id === this.state.categories[i].id) {
                const newCategories = this.state.categories
                newCategories[i] = category
                this.setState({
                    categories: newCategories
                })
                break
            }
        }
    }

    handleClose() {
        this.setState({ showDelete: false, selectedCategory: null });
    }

    handleDelete(category) {
        this.setState({ showDelete: true, selectedCategory: category });
    }

    render() {
        return (
            <Card>
                <Card.Body>
                <div style={{display: 'flex', alignItems: 'center'}}><h3 style={{flex: 1}}>Categories</h3><Button variant="outline-dark" size="sm" style={{marginLeft: '10px'}} onClick={this.addCategory}>Add Category</Button></div>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Updated At</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.categories.map((category, index) => {
                                const createdAt = moment(new Date(category.createdAt.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss')
                                const updatedAt = moment(new Date(category.updatedAt.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss')
                                return (
                                    <tr key={category.id}>
                                        <td>{index + 1}</td>
                                        <td><span style={{
                                            backgroundColor: category.color,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            borderRadius: 5,
                                            color: 'white'
                                        }}>{category.name}</span></td>
                                        <td>{updatedAt}</td>
                                        <td>{createdAt}</td>
                                        {/* <td><Link to={`/merchant/${category.organization.key}/branch/${category.branch.key}/edit`}>{category.branch.name}</Link></td> */}
                                        <td>
                                            <Button onClick={() => this.editCategory(category)} style={{marginRight: '5px'}} variant="outline-primary" size="sm">Edit</Button>
                                            {/* <Button style={{marginRight: '5px'}} variant="outline-secondary" size="sm">Reset Password</Button> */}
                                            <Button variant="outline-danger" size="sm" onClick={() => {this.handleDelete(category)}}>Remove</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        
                            
                        
                        
                    </tbody>
                    
                </Table>
                <div style={{display: 'flex', justifyContent: 'center'}}>{this.state.loading && <ClipLoader />}</div>
                {!this.state.loading && this.state.categories.length === 0 && <div style={{textAlign: 'center'}}>You haven't added any categories yet</div>}

                <Modal show={this.state.showDelete} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the category '{this.state.selectedCategory && this.state.selectedCategory.name}'?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Nevermind
                        </Button>
                        <Button variant="primary" onClick={() => this.deleteCategory()}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <AddCategoryModal category={this.state.selectedCategory} onCategoryEdited={this.categoryEdited} onCategoryAdded={this.categoryAdded} show={this.state.showAddCategory} onHide={this.onHide} />
                </Card.Body>
            </Card>
        )
    }
}
