import BranchService from "./branch-service"
import Axios from "axios"
import { AuthService } from "./auth.service"
import moment from "moment"

export class HardwareService {
    static openDrawer() {
        return Axios.get(`http://localhost:1024/open-drawer`).then(data => {
            return data.data.success
        }).catch(() => {
            return false
        })
    }

    static ping() {
    

        return Axios.get(`http://localhost:1024/ping`).then(data => {
            const success = data.data.success
            
            return success
        }).catch(err => {
            console.log(err)

            return false
        })
    }

    static makeLine(width) {
        let line = ""

        for (let i = 0; i < width; i++) {
            line += "="
        }

        return line
    }

    static print(order, openCashDrawer) {
        return AuthService.employee().then(employee => {
            return BranchService.branch().then(branch => {
                return BranchService.businessInfo().then(organization => {
                    HardwareService.printOrder(order, TextTrackCue, openCashDrawer, employee, branch, organization).then(() => {
                        setTimeout(() => {
                            HardwareService.printOrder(order, false, openCashDrawer, employee, branch, organization)
                        }, 5000)
                    })
                })
            })
        })
    }

    static printOrder(order, customerCopy, openCashDrawer, employee, branch, organization) {
        order.discount = 0

        const subtotal = `R${((order.price + (order.discount * 100)) / 100).toFixed(2)}`
        const discount = `R${((order.discount)).toFixed(2)}`
        const total = `R${(order.price / 100).toFixed(2)}`

        function makeSpace(width) {
            let line = ""
    
            for (let i = 0; i < width; i++) {
                line += " "
            }
    
            return line
        }

        function zeros(width) {
            let line = ""
    
            for (let i = 0; i < width; i++) {
                line += "0"
            }
    
            return line
        }

        function formatPrice(price) {
            return `R${(price / 100).toFixed(2)}`
        }
        
        function qty(number) {
            const length = `${number}`.length
            let space = ''
            for (let i = 0; i < 5 - length; i++) {
                space += ' '
            }

            return `${number}${space}`
        }

        function productName(name) {
            if (name.length > 18) {
                return name.substr(0, 18)
            }

            return name
        }

        function printProduct(product) {
            const words = (product.name + ' ' + (product.customDescription ? product.customDescription : '')).trim().split(' ')
            let lines = []
            const maxLineWidth = 29
            let currentLine = ''

            words.forEach(word => {
                if (`${currentLine} ${word}`.length < maxLineWidth) {
                    currentLine = `${currentLine} ${word}`
                } else {
                    lines.push(`${currentLine}`)
                    currentLine = word
                }
            })

            lines.push(currentLine)
            lines = lines.map((line, index) => {
                const price = `${formatPrice((product.price * product.qty) - (product.discount * 100))}`
                const space = makeSpace(paperWidth - (`${qty(product.qty)} ${line}`.length + price.length))
                if (index == 0) {
                    const productText = `${qty(product.qty)} ${line}`
                    return {method: "text", params: [`${productText}${space}${price}`]}
                } else {
                    const productText = `  ${line}`
                    return {method: "text", params: [`${productText}${space}`]}
                }
            })

            console.log(lines)

            return lines
        }

        const orderId = `${order.order}`.toUpperCase()
        // console.log(NativeModules)
        // console.log(NativeModules.PrintModule)
        // const paperWidth = 32
        const paperWidth = 48
        // console.log(NativeModules.PrintModule.print)
        
        // NativeModules.PrintModule.printText(JSON.stringify(
        //     [
        //         {method: "font", params: ["a"]},
        //         {method: "align", params: ["ct"]},
        //         {method: "size", params: [1, 1]},
        //         {method: "text", params: ["Konette"]},
        //         {method: "text", params: ["Pretoria North"]},
        //         {method: "text", params: [moment(new Date(order.createdAt)).format('YYYY-MM-DD HH:mm:ss')]},
        //         {method: "text", params: [""]},
        //         {method: "text", params: ['License Plate: ' + orderId]}, 
        //         {method: "text", params: [order.description]},
        //         // {method: "barcode", params: [`${zeros(7 - orderId.length)}${orderId}`, 'EAN8']},
        //         {method: "text", params: [""]},
        //         {method: "text", params: [WaitrHardwareService.makeLine(32)]},
        //         {method: "text", params: ["QTY ITEM                   TOTAL"]},
        //         ...order.products.map(product => {
        //             const productText = `${qty(product.qty)} ${productName(product.name)}`
        //             const price = `${formatPrice((product.price * product.qty) - (product.discount * 100))}`
        //             const space = makeSpace(32 - (productText.length + price.length))

        //             return {
        //                 method: "text",
        //                 params: [`${productText}${space}${price}`]
        //             }
        //         }),
        //         {method: "text", params: [""]},
        //         {method: "text", params: [`SUBTOTAL${makeSpace(32 - (subtotal.length + 8))}${subtotal}`]},
        //         {method: "text", params: [`DISCOUNT${makeSpace(32 - (discount.length + 8))}${discount}`]},
        //         {method: "text", params: [`TOTAL${makeSpace(32 - (total.length + 5))}${total}`]},
        //         // {method: "text", params: [""]},
        //         // {method: "text", params: ["Special Instructions"]},
        //         // {method: "text", params: [order.instructions]},
        //         {method: "text", params: [WaitrHardwareService.makeLine(32)]},
        //         {method: "text", params: [""]},
        //         {method: "text", params: ["View your order status on"]}, 
        //         {method: "text", params: ["konette.waitr.co.za"]},
        //         {method: "text", params: [""]},
        //         {method: "text", params: ["Or scan the QR code below"]},
        //         {method: "text", params: [""]},
        //         {method: "qrcode", params: [`https://konette.waitr.co.za/order/${orderId}`]},
        //         {method: "text", params: [""]},
        //         {method: "text", params: ["Powered by Waitr"]},
        //         {method: "text", params: ["https://www.waitr.co.za"]},
        //     ]
        // ))
        console.log('About to print')

        let customerText = []
        let discountText = []
        let customerPaid = []
        let infoForCustomer = []
        let extraSpace = []
        let eibBarcode = []

        if (organization.entity == 'item') {
            eibBarcode = [
                {method: "barcode", params: [`${zeros(7 - orderId.length)}${orderId}`, 'EAN8']}
            ]
        } 

        if (order.paid) {
            customerPaid = [
                { method: "text", params: [""] },
                { method: 'style', params: ['bu'] },
                { method: 'text', params: ['*** Paid ***'] },
                { method: 'style', params: ['normal'] }
            ]
        }

        if (customerCopy) {
            customerText = [
                {method: "text", params: [""]},
                {method: "size", params: [2, 2]},
                {method: "text", params: ["Customer Copy"]},
                {method: "size", params: [1, 1]},
                {method: "text", params: [""]}
            ]

            infoForCustomer = [
                {method: "text", params: ["View your order status, operating times and"]}, 
                {method: "text", params: ["disclaimer on"]},
                {method: "text", params: [`${employee.branch}.waitr.co.za`]},
                {method: "text", params: [""]},
                {method: "text", params: ["Or scan the QR code below"]},
                {method: "qrimage", params: [`https://${employee.branch}.waitr.co.za/order/${orderId}`]},
                // {method: "qrimage", params: [`https://${branch.id}.waitr.co.za`]},
                {method: "text", params: [""]},
                {method: "text", params: ["Powered by Waitr"]},
                {method: "text", params: ["https://www.waitr.co.za"]},
                {method: "text", params: [""]},
                {method: "text", params: [""]},
                {method: "text", params: [""]},
                {method: "text", params: [""]},
            ]
        } else {
            customerText = [{method: "text", params: [""]}]
            extraSpace = [
                {method: "text", params: [""]},
                {method: "text", params: [""]},
                {method: "text", params: [""]},
                {method: "text", params: [""]}
            ]
        }

        const arrayProducts = order.products.map(product => {
            return printProduct(product)
        })
        const productsToPrint = []

        arrayProducts.forEach(array => {
            array.forEach(item => {
                productsToPrint.push(item)
            })
        })

        console.log(productsToPrint)
        

        if (Math.abs(order.discount) > 0) {
            discountText = [{method: "text", params: [`DISCOUNT${makeSpace(paperWidth - (discount.length + 9))}-${discount}`]}]
        }

        if (order.phone == null || order.phone.trim().length == 0) {
            order.phone = order.cellphone
        }

        return Axios.get(`http://localhost:1024/init?uid=YNpxlIqsvgSZvfeMxMw9ZyUf4ng2`).then(data => {
            const token = data.data.token
            console.log('Token: ' + token)
            return Axios.post(`http://localhost:1024/print`, {
                openCashDrawer: !!openCashDrawer,
                instructions: [
                    {method: "font", params: ["a"]},
                    {method: "align", params: ["ct"]},
                    {method: "size", params: [1, 1]},
                    {method: "text", params: [organization.orgInfo.name]},
                    {method: "text", params: [branch.address]},
                    {method: "text", params: [branch.contacts]},
                    {method: "text", params: [""]},
                    {method: "align", params: ["ct"]},
                    {method: "text", params: [order.newOrder ? moment(new Date()).format('YYYY-MM-DD HH:mm:ss') : moment(new Date(order.createdAt)).format('YYYY-MM-DD HH:mm:ss')]},
                    {method: "text", params: [""]},
                    {method: "align", params: ["ct"]},
                    {method: "size", params: [2, 2]},
                    {method: "text", params: ['Order ' + orderId]},
                    ...customerPaid,
                    {method: "size", params: [1, 1]},
                    {method: "text", params: [""]},
                    // {method: "text", params: [order.description]},
                    ...eibBarcode,
        
                    ...customerText,
                    {method: "align", params: ["lt"]},
                    {method: "text", params: [`${order.name ? order.name : ''} ${order.phone ? order.phone : ''}`.trim()]},
                    {method: "text", params: [""]},
                    {method: "text", params: ["Special Instructions"]},
                    order.instructions ? {method: "text", params: [order.instructions]} : {method: "text", params: ['-']},
                    {method: "align", params: ["ct"]},
                    {method: "text", params: [HardwareService.makeLine(paperWidth)]},
                    // {method: "tableCustom", params: [[
                    //     {text: "QTY", align: 'LEFT', width: paperWidth / 48 * 0.11},
                    //     {text: "ITEM", align: 'LEFT', width: paperWidth / 48 * 0.54},
                    //     {text: "TOTAL", align: 'RIGHT', width: paperWidth / 48 * 0.25}
                    // ]]},
                    {method: "text", params: [`QTY    ITEM${makeSpace(paperWidth - 16)}TOTAL`]},
                    // ...order.products.map(product => {
                    //     const productText = product.name + (product.customDescription ? `\n${product.customDescription}` : '')
                    //     const price = `${formatPrice((product.price * product.qty) - (product.discount * 100))}`
                    //     // const space = makeSpace(paperWidth - (productText.length + price.length))

                    //     return {
                    //         method: "tableCustom",
                    //         params: [[
                    //             {text: product.qty, align: 'LEFT', width: paperWidth / 48 * 0.11},
                    //             {text: productText, align: 'LEFT', width: paperWidth / 48 * 0.54},
                    //             {text: price, align: 'RIGHT', width: paperWidth / 48 * 0.25}
                    //         ]]
                    //         // params: [`${productText}${space}${price}`]
                    //     }
                    // }),
                    ...productsToPrint,

                    {method: "text", params: [""]},
                    {method: "text", params: [`SUBTOTAL${makeSpace(paperWidth - (subtotal.length + 8))}${subtotal}`]},
                    ...discountText,
                    {method: "text", params: [`TOTAL${makeSpace(paperWidth - (total.length + 5))}${total}`]},
                    {method: "text", params: [HardwareService.makeLine(paperWidth)]},
                    {method: "align", params: ["lt"]},
                    {method: "text", params: ["Assisted by " + employee.name]},
                    {method: "text", params: [""]},
                    {method: "align", params: ["ct"]},
                    ...infoForCustomer,
                    ...extraSpace
                ]
            }, { headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }}).then(result => {
                console.log('Data sent')
                console.log(result.data) 
            }).catch(err => {
                console.log(err)
            })
        })
    }
}


