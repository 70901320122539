import React from 'react'
import { Subscription } from './subscription';
import { Tabs, Tab } from 'react-bootstrap';
import { Subscribe } from './subscribe';
import { BranchList } from './branches';
import { General } from './general';
import { Online } from './online';
import { Notifications } from './notifications';

export class Settings extends React.Component {
    render() {
        return (
            <Tabs defaultActiveKey="general" id="settings">
                {/* <Tab eventKey="subscriptions" title="Subscriptions">
                    <Subscription />
                </Tab> */}
                <Tab eventKey="general" title="General">
                    <General />
                </Tab>
                <Tab eventKey="online" title="Online Store">
                    <Online />
                </Tab>
                <Tab eventKey="notifications" title="Notifications">
                    <Notifications />
                </Tab>
            </Tabs>
        )
    }
}